import { library } from '@fortawesome/fontawesome-svg-core'

import { faBackward } from '@fortawesome/pro-light-svg-icons/faBackward';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons/faEllipsisH';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faForward } from '@fortawesome/pro-light-svg-icons/faForward';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faStepBackward } from '@fortawesome/pro-light-svg-icons/faStepBackward';
import { faStepForward } from '@fortawesome/pro-light-svg-icons/faStepForward';
import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faInfo } from '@fortawesome/pro-light-svg-icons/faInfo';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faTrashUndo } from '@fortawesome/pro-light-svg-icons/faTrashUndo';
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faBoxes } from '@fortawesome/pro-light-svg-icons/faBoxes';
import { faBox } from '@fortawesome/pro-light-svg-icons/faBox';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faCloudDownloadAlt } from '@fortawesome/pro-light-svg-icons/faCloudDownloadAlt';
import { faBroom } from '@fortawesome/pro-light-svg-icons/faBroom';
import { faCloudUploadAlt } from '@fortawesome/pro-light-svg-icons/faCloudUploadAlt';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowAltRight } from '@fortawesome/pro-light-svg-icons/faArrowAltRight';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faPercent } from '@fortawesome/pro-light-svg-icons/faPercent';
import { faBolt } from '@fortawesome/pro-light-svg-icons/faBolt';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faCheckDouble } from '@fortawesome/pro-light-svg-icons/faCheckDouble';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faHandHoldingUsd } from '@fortawesome/pro-light-svg-icons/faHandHoldingUsd';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';
import { faRedoAlt } from '@fortawesome/pro-light-svg-icons/faRedoAlt';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';
import { faListOl } from '@fortawesome/pro-light-svg-icons/faListOl';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie';
import { faFastForward } from '@fortawesome/pro-light-svg-icons/faFastForward';
import { faFastBackward } from '@fortawesome/pro-light-svg-icons/faFastBackward';
import { faCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import { faMoneyCheckEditAlt } from '@fortawesome/pro-light-svg-icons/faMoneyCheckEditAlt';
import { faArrowAltCircleLeft } from '@fortawesome/pro-light-svg-icons/faArrowAltCircleLeft';
import { faStream } from '@fortawesome/pro-light-svg-icons/faStream';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faHandsUsd } from '@fortawesome/pro-light-svg-icons/faHandsUsd';
import { faArrowFromTop } from '@fortawesome/pro-light-svg-icons/faArrowFromTop';
import { faArrowFromBottom } from '@fortawesome/pro-light-svg-icons/faArrowFromBottom';
import { faRssSquare } from '@fortawesome/pro-light-svg-icons/faRssSquare';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons/faPlusSquare';
import { faMinusSquare } from '@fortawesome/pro-light-svg-icons/faMinusSquare';
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer';
import { faBoxUsd } from '@fortawesome/pro-light-svg-icons/faBoxUsd';
import { faWallet } from '@fortawesome/pro-light-svg-icons/faWallet';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
import { faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons/faChevronDoubleRight';
import { faChevronDoubleLeft } from '@fortawesome/pro-light-svg-icons/faChevronDoubleLeft';
import { faBagsShopping } from '@fortawesome/pro-light-svg-icons/faBagsShopping';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faMoneyCheck } from '@fortawesome/pro-light-svg-icons/faMoneyCheck';
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faCashRegister } from '@fortawesome/pro-light-svg-icons/faCashRegister';
import { faSackDollar } from '@fortawesome/pro-light-svg-icons/faSackDollar';
import { faMoneyFromBracket } from '@fortawesome/pro-light-svg-icons/faMoneyFromBracket';
import { faFolderMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faFolderMagnifyingGlass';
import { faFolders } from '@fortawesome/pro-light-svg-icons/faFolders';
import { faFolderBookmark } from '@fortawesome/pro-light-svg-icons/faFolderBookmark';
import { faUsersLine } from '@fortawesome/pro-light-svg-icons/faUsersLine';
import { faDisplay } from '@fortawesome/pro-light-svg-icons/faDisplay';
import { faBarcode } from '@fortawesome/pro-light-svg-icons/faBarcode';
import { faBoxOpenFull } from '@fortawesome/pro-light-svg-icons/faBoxOpenFull';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faFileSpreadsheet } from '@fortawesome/pro-light-svg-icons/faFileSpreadsheet';
import { faPuzzlePiece } from '@fortawesome/pro-light-svg-icons/faPuzzlePiece';
import { faCirclesOverlap } from '@fortawesome/pro-light-svg-icons/faCirclesOverlap';
import { faSquarePen } from '@fortawesome/pro-light-svg-icons/faSquarePen';
import { faRotateRight } from '@fortawesome/pro-light-svg-icons/faRotateRight';
import { faMoneyCheckDollar } from '@fortawesome/pro-light-svg-icons/faMoneyCheckDollar';
import { faMoneyCheckDollarPen } from '@fortawesome/pro-light-svg-icons/faMoneyCheckDollarPen';
import { faSquareDollar } from '@fortawesome/pro-light-svg-icons/faSquareDollar';
import { faBuildingColumns } from '@fortawesome/pro-light-svg-icons/faBuildingColumns';
import { faFileZipper } from '@fortawesome/pro-light-svg-icons/faFileZipper';

library.add(
  faRotateRight,
  faSquarePen,
  faCirclesOverlap,
  faPuzzlePiece,
  faFileSpreadsheet,
  faGlobe,
  faBoxOpenFull,
  faBarcode,
  faBackward,
  faCloudUpload,
  faDollarSign,
  faEllipsisH,
  faFile,
  faForward,
  faKey,
  faSignOut,
  faStepBackward,
  faStepForward,
  faSave,
  faEdit,
  faSearch,
  faPrint,
  faUndo,
  faTimes,
  faPlus,
  faInfo,
  faMinus,
  faTrash,
  faTrashUndo,
  faFolder,
  faFolderOpen,
  faBoxes,
  faBox,
  faCloudDownload,
  faSync,
  faCloudDownloadAlt,
  faBroom,
  faCloudUploadAlt,
  faArrowLeft,
  faArrowAltRight,
  faUpload,
  faDownload,
  faPercent,
  faBolt,
  faEnvelope,
  faCheckDouble,
  faChevronRight,
  faChevronLeft,
  faHandHoldingUsd,
  faCheck,
  faArrowUp,
  faList,
  faRedoAlt,
  faClipboardListCheck,
  faCopy,
  faSitemap,
  faListOl,
  faUser,
  faUserTie,
  faFastForward,
  faFastBackward,
  faCheckSquare,
  faMoneyCheckEditAlt,
  faArrowAltCircleLeft,
  faStream,
  faLock,
  faHandsUsd,
  faArrowFromTop,
  faArrowFromBottom,
  faRssSquare,
  faPlusSquare,
  faMinusSquare,
  faServer,
  faBoxUsd,
  faWallet,
  faFileInvoiceDollar,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faBagsShopping,
  faCreditCard,
  faBook,
  faMoneyCheck,
  faCode,
  faBan,
  faEye,
  faXmark,
  faCashRegister,
  faSackDollar,
  faMoneyFromBracket,
  faFolderMagnifyingGlass,
  faFolders,
  faFolderBookmark,
  faUsersLine,
  faClipboardList,
  faDisplay,
  faMoneyCheckDollar,
  faMoneyCheckDollarPen,
  faSquareDollar,
  faBuildingColumns,
  faFileZipper
)