import { LocalEmpresaFullInfo } from '../containers/component/formLocal/types/types'
import { DateUtils, formatoFechasApi } from '../helpers/dateUtils'
import { RequestHelper } from '../helpers/requestHelper'
import { RequestHelperDotNet } from '../helpers/requestHelperDotNet'
import { utilidades } from '../helpers/utilidades'
import { ECountry } from '../store/enum/countries'
import {
  Respuesta,
  Plan,
  Empresa,
  Puntoventa,
  Impresora,
  TipoNegocio,
  UsuarioLocal,
  CapitalizeMode,
  TipoComprobantes,
  TImpuestos,
} from '../store/types'
import { SesionService } from './sesion.service'

export const sidebarService = {
  datosLocalActualizar,
  autorizacionIngresar,
  certificadoIngresar,
  certificadoVerificar,
  puntoVentaIngresar,
  puntoVentaAutorizacionAsignar,
  tipoagenteListar,
  tiponegocioListar,
  planObtener,
  datosEmpresaObtener,
  datosEmpresaActualizar,
  docEmpresaUplod,
  autorizacionExtraer,
  autorizacionAsignar,
  getDatosEmpresa,
  puntosVentaListar,
  impresorasListar,
  actualizarModoFacturacion,
  tiposComprobantesListar,
  impresionesListar,
  obtenerTerminosCondiciones,
  cancelSuscripcion,
  updateApisExternar,
  terminosAceptados,
  renombrarArchivo,
  configuracionDesactivar,
  uploadCertificadoEmpresa,
  localExtraer,
  localAtributosExtraer,
  clasificacionRimpeListar,
  relationUserLocal,
  impuestosListar,
  tokenHaciendaActualizar,
}

async function autorizacionIngresar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'sidebar',
      'authorization/register',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function autorizacionExtraer(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.getAll<any>(
      'sidebar',
      'autorizacion/extraer',
      '',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function autorizacionAsignar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'sidebar',
      'pventaautorizacion/asignar',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function certificadoIngresar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<Respuesta>(
      'sidebar',
      'certificados/ingresar',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function certificadoVerificar(): Promise<any> {
  try {
    const data = await RequestHelper.getAll<Respuesta>(
      'certificates',
      'getActive',
      '',
      '',
    )
    return data
  } catch (error) {
    return error
  }
}

async function puntoVentaIngresar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'sidebar',
      'puntoventa/ingresar',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function puntoVentaAutorizacionAsignar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.post<Respuesta>(
      'sidebar',
      'puntoventa/ingresar',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function tipoagenteListar(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const queryParams: any = {
      paisCodigo: sesion?.local?.ciudad?.paisCodigo ?? 1,
    }
    const data = await RequestHelper.get<any>(
      'tipoagente',
      'listar',
      '',
      queryParams,
    )
    return data
  } catch (error) {
    return error
  }
}

async function tiponegocioListar(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      paisCodigo: sesion?.empresa?.codigoPais ?? 1,
    }
    data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'economicOptions' + JSON.stringify(data)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return JSON.parse(cached)
    } else {
      const res = await RequestHelper.get<Array<TipoNegocio>>(
        'tiponegocio',
        'listar',
        '',
        data,
      )
      let opt: Array<TipoNegocio> = []
      if (res && res.length > 0) {
        opt = res.map((x) => {
          const item: TipoNegocio = {
            codigo: parseInt(x['codigo']) ?? 0,
            descripcion: utilidades.capText(x['descripcion']) ?? '',
            codigoTributario: x?.codigoTributario ?? null,
          }
          return item
        })
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))
      return opt
    }
  } catch (error) {
    return error
  }
}

async function planObtener(planId: number): Promise<any> {
  try {
    const dataSearch = {
      plan: planId,
    }
    const data = await RequestHelper.get<Plan>(
      'sidebar',
      'empresa/plan',
      '',
      dataSearch,
    )
    return data
  } catch (error) {
    return error
  }
}

async function datosEmpresaObtener(objeto: any): Promise<any> {
  try {
    if (utilidades.canUseNetApi()) {
      const _empresa = await RequestHelperDotNet.get<any>(
        'Companies',
        '',
        '',
        null,
      )
      const empresa: Empresa = {
        codigo: _empresa.codigo,
        logo: _empresa.pathLogoEmpresa,
        ruc: _empresa.ruc,
        nombre: _empresa.nombre,
        comercial: _empresa.nombreComercial,
        direccion: _empresa.direccion,
        telefono: _empresa.telefono,
        email: _empresa.email,
        token: _empresa.token,
        descuento: _empresa.descuento,
        iproforma: _empresa.ipProforma,
        transparencia: _empresa.transparencia,
        modulos: _empresa.modulos,
        precision: parseInt(_empresa.precisionDecimal),
        uso_electronica: parseInt(_empresa.modoFacturacion),
        uso_liquidacion: parseInt(_empresa.uso_liquidacion),
        uso_ncredito: parseInt(_empresa.uso_ncredito),
        creditos: parseInt(_empresa.creditos),
        uso_ndedito: parseInt(_empresa.uso_ndedito),
        uso_guia: parseInt(_empresa.uso_guia),
        uso_retencion: parseInt(_empresa.uso_retencion),
        certificado: _empresa.certificado,
        caduca: _empresa.certificadoCaduca,
        imprimeAutomaticamenteAlfacturar:
          _empresa.PreguntaAlImprimir === 1 ? false : true,
        pathArchivosPaginaWeb: _empresa.pathWeb,
        locales: [],
        cuotasVenta: parseInt(_empresa.cuotasv),
        cuotasVentaDias: parseInt(_empresa.diasCuota),
        formaPagoDefectoCompra: _empresa.formaPagoDefectoCompra,
        formaPagoDefectoVenta: _empresa.formaPagoDefectoVenta,
        configurado: _empresa.configurado ?? false,
        leyendaComprobantesElctronicos: _empresa.leyendaCe ?? '',
        codigoTipoNegocio: Number(_empresa?.tipo_negocio_id) ?? -1,
        editarFechaEmision: parseInt(_empresa?.editarFechaEmision) ?? 0,
        contrato: parseInt(_empresa.contrato ?? 0),
        servicioS3: parseInt(_empresa.CFG_SE3SERVICE) === 1 ? true : false,
        uuid: _empresa.uuid ?? '',
        configuracion: {
          cantidadComprobantesCaducaContrato:
            parseInt(
              _empresa?.configuracion?.cantidadComprobantesCaducaContrato,
            ) ?? 0,
          cantidadDiasCaducaContrato:
            parseInt(_empresa?.configuracion?.cantidadDiasCaducaContrato) ?? 0,
          comisionPayphone:
            parseInt(_empresa?.configuracion?.comisionPayphone) ?? 0,
          urlFiles: _empresa?.configuracion?.urlFiles ?? '',
          cantidadDiasMoraPago:
            parseInt(_empresa?.configuracion?.cantidadDiasMoraPago) ?? 0,
        },
        uaf: _empresa.uaf ?? '',
        ipWeb: _empresa.ipWeb ?? '',
        wooUrl: _empresa.wooUrl ?? '',
        preciosIva: parseInt(_empresa.preciosIva) ?? 0,
        consumidorFinalValor: _empresa.consumidorFinalValor ?? 0,
        contribuyente: _empresa.contribuyente ?? '',
        logs: parseInt(_empresa.logs) ?? 0,
        plantillaBarras: parseInt(_empresa.iplantilla) ?? -1,
        inventarios: parseInt(_empresa.inventarios) ?? 0,
        distinguir: parseInt(_empresa.distinguir) ?? 0,
        utilizarImpuestos: parseInt(_empresa.utilizarImpuestos) ?? 0,
        recintoFiscal: _empresa.recintoFiscal ?? '',
        regimen: _empresa.regimen ?? '',
        tipoItemExporta: parseInt(_empresa.tipoItemExporta) ?? 0,
        nrc: _empresa.nrc ?? '',
        codigoTributarioActividad: _empresa.codigoTributarioActividad ?? '',
        descripcionActividad: _empresa.descripcionActividad ?? '',
        tipoAmbiente: parseInt(_empresa.tipoAmbiente) ?? 0,
        authHacienda: _empresa.authHacienda ?? '',
        expiraCertificado: _empresa.expiraCertificado ?? '',
        passCertificado: _empresa.passCertificado ?? '',
        codigoPais: parseInt(_empresa.paisCodigo) ?? 0,
        tipoLicencia: parseInt(_empresa.tipoLicencia) ?? 0,
        numUsuarios: parseInt(_empresa.numUsuarios) ?? 0,
        haciendaapikey: _empresa?.haciendaapikey ?? '',
        tipoAgente: parseInt(_empresa.tipoAgente) ?? -1,
        numeroRoles: parseInt(_empresa.numeroRoles) ?? 0,
      }
      return empresa
    } else {
      const data = await RequestHelper.get<Empresa>(
        'sidebar',
        'empresa/datos',
        '',
        objeto,
      )
      const parseApi = parseApiEmpresaData(data)
      return parseApi
    }
  } catch (error) {
    return error
  }
}

async function actualizarModoFacturacion(objeto: any): Promise<any> {
  try {
    const dataUpdate = {
      ...objeto,
    }
    const data = await RequestHelper.postAll<any>(
      'sidebar',
      'empresa/updateBillingMode',
      dataUpdate,
    )
    return data
  } catch (error) {
    return error
  }
}

async function datosEmpresaActualizar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'sidebar',
      'empresa/actualizar',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function docEmpresaUplod(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postData<any>(
      'upload',
      'index.php',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function uploadCertificadoEmpresa(objeto: any): Promise<any> {
  try {
    let api: string = 'validateCertificate'
    if (objeto.pais === ECountry.ElSalvador) {
      objeto.id = objeto.ruc ?? ''
      api = 'validateCertificateMultiCountry'
    } else {
      api = 'validateCertificate'
    }
    const data = await RequestHelper.postData<any>('public', api, objeto)
    return data
  } catch (error) {
    return error
  }
}

async function getDatosEmpresa(): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  const data = {
    empresa: sesion.empresa.codigo,
    plan: '',
  }
  return datosEmpresaObtener(data)
}

async function puntosVentaListar(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const obj = {
      codigoLocal: sesion.local.codigo,
    }
    const data = await RequestHelper.get<Array<Puntoventa>>(
      'sidebar',
      'pointSales/getPointsSales',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function impresorasListar(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const obj = {
      codigoLocal: sesion.local.codigo,
    }
    const data = await RequestHelper.get<Array<Impresora>>(
      'sidebar',
      'getPrinters',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function tiposComprobantesListar(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {
      codigoLocal: sesion.local.codigo,
      mensaje: '',
      paisCodigo: sesion?.empresa?.codigoPais ?? 1,
    }
    data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'typoDocsOptions' + JSON.stringify(data)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return JSON.parse(cached)
    } else {
      const res = await RequestHelper.get<any>(
        'generales',
        'voucherType/cboxload',
        '',
        data,
      )

      let opt: Array<TipoComprobantes> = []

      if (res && res.length > 0) {
        opt = res.map((x) => {
          const item: any = {
            codigo: x['codigo'] ?? '',
            archivo: x['archivo'] ?? '',
            comprobante:
              utilidades.capText(x?.['comprobante'], CapitalizeMode.upper) ??
              '',
            descripcionEstado: x?.['descripcionEstado'] ?? '',
            estado: parseInt(x['estado']) ?? 0,
            limite: x?.['limite'] ?? '',
            nombre: x?.['nombre'] ?? '',
            nombreImpresora: x?.['nombreImpresora'] ?? '',
            puertoImpresora: x?.['puertoImpresora'] ?? '',
          }
          return item
        })
      }
      sessionStorage.setItem(cacheKey, JSON.stringify(opt))

      return opt
    }
  } catch (error) {
    return error
  }
}

async function impresionesListar(objecFind): Promise<any> {
  try {
    const obj = {
      mensaje: '',
      ...objecFind,
    }
    const data = await RequestHelper.get<Array<Impresora>>(
      'generales',
      'impressions/cboxLoad',
      '',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function obtenerTerminosCondiciones(): Promise<any> {
  try {
    const obj = {}
    const getTerminos = await RequestHelper.getAll<any>(
      'terminos',
      'getConditions',
      '',
      obj,
    )
    return getTerminos
  } catch (error) {
    return error
  }
}

async function cancelSuscripcion(): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    const objRequest = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
    }
    const data = await RequestHelper.deleteRequestAll<any>(
      'sidebar',
      'companies/inactive',
      '',
      objRequest,
    )
    return data
  } catch (error) {
    return error
  }
}

async function updateApisExternar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'sidebar/company',
      'apis/update',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function terminosAceptados(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postUrlEncodedAll<any>(
      'terminos',
      'saveDateConditionsAcept',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function renombrarArchivo(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<any>(
      'generales',
      'fileRename',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}

async function configuracionDesactivar(): Promise<any> {
  try {
    const data = await RequestHelper.getAll<any>(
      'admin',
      'empresas/disableConfiguration',
      '',
      '',
    )
    return data
  } catch (error) {
    return error
  }
}

async function localExtraer(codigoLocal: number): Promise<any> {
  try {
    const objFind = {
      codigo: codigoLocal,
    }
    const data = await RequestHelper.get<any>(
      'inventario/multialmacen',
      'local/get',
      '',
      objFind,
    )
    const parseApi = parseApiLocalEmpresaData(data)
    return parseApi
  } catch (error) {
    return error
  }
}

function parseApiLocalEmpresaData(apiresult: any) {
  const infoLocalEmpresa: LocalEmpresaFullInfo = {
    claseCodigo: parseInt(apiresult?.claseCodigo) ?? 0,
    claseDescripcion: String(apiresult?.claseDescripcion) ?? '',
    contrato: parseInt(apiresult?.contrato) ?? 0,
    estadoCodigo: parseInt(apiresult?.estadoCodigo) ?? 0,
    estadoDescripcion: String(apiresult?.estadoDescripcion) ?? '',
    gerente: String(apiresult?.gerente) ?? '',
    listaPrecios: Number(apiresult?.listaPrecios) === 1 ? true : false,
    observaciones: String(apiresult?.observaciones) ?? '',
    passwordDokan: String(apiresult?.passwordDokan) ?? '',
    perteneceCodigo: parseInt(apiresult?.perteneceCodigo) ?? 0,
    perteneceDescripcion: String(apiresult?.perteneceDescripcion) ?? '',
    siglas: String(apiresult?.siglas) ?? '',
    siglasBod: String(apiresult?.siglasBod) ?? '',
    tipo: parseInt(apiresult?.tipo) ?? 0,
    tipoDescripcion: String(apiresult?.tipoDescripcion) ?? '',
    uaf: String(apiresult?.uaf) ?? '',
    usuarioDokan: String(apiresult?.usuarioDokan) ?? '',
    web: apiresult?.web ?? '',
    paisCodigo: parseInt(apiresult?.paisCodigo) ?? 0,
    provinciaCodigo: parseInt(apiresult?.provinciaCodigo) ?? 0,
    codigo: parseInt(apiresult?.codigo) ?? 0,
    nombre: String(apiresult?.nombre) ?? '',
    ciudadCodigo: parseInt(apiresult?.ciudadCodigo) ?? 0,
    ciudadNombre: String(apiresult?.ciudadNombre) ?? '',
    descuento: parseFloat(apiresult?.descuento) ?? 0,
    direccion: String(apiresult?.direccion) ?? '',
    telefono: String(apiresult?.telefono) ?? '',
    tipoRol: String(apiresult?.tipoRol) ?? '',
    controlaKardex: Number(apiresult?.controlaKardex) === 1 ? true : false,
    logo: String(apiresult?.logo) ?? '',
  }
  return infoLocalEmpresa
}

function parseApiEmpresaData(apiresult: any) {
  const infoLocalEmpresa: any = {
    ...apiresult,
    tipoItemExportar: apiresult?.tipoItemExportar ?? null,
    recintoFiscal: apiresult?.recintoFiscal ?? '',
    regimen: apiresult?.regimen ?? '',
    utilizarImpuestos: apiresult?.utilizarImpuestos ?? '',
    haciendaapikey: apiresult?.authHacienda ?? '',
  }
  return infoLocalEmpresa
}

async function localAtributosExtraer(codigoLocal: number): Promise<any> {
  try {
    const objFind = {
      codigoLocal: codigoLocal,
    }
    const data = await RequestHelper.getAll<any>(
      'sidebar/local',
      'features',
      '',
      objFind,
    )
    return data
  } catch (error) {
    return error
  }
}

async function datosLocalActualizar(objeto: any): Promise<any> {
  try {
    const obj = {
      infoRegistro: {
        ...objeto,
      },
    }
    const data = await RequestHelper.postAll<any>(
      'inventario/multialmacen',
      'local/save',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function relationUserLocal(objeto: UsuarioLocal): Promise<any> {
  try {
    const obj = {
      ...objeto,
    }
    const data = await RequestHelper.postUrlEncodedAll<any>(
      'admin/usuarios',
      'saveUserLocal',
      obj,
    )
    return data
  } catch (error) {
    return error
  }
}

async function clasificacionRimpeListar(): Promise<any> {
  try {
    const data = await RequestHelper.get<any>(
      'public',
      'getClasificationRimpe',
      '',
      '',
    )
    return data
  } catch (error) {
    return error
  }
}

async function impuestosListar(): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion()
    const data: any = {}
    data.paisCodigo = sesion?.local?.ciudad?.paisCodigo ?? 1
    data.dateKey = DateUtils.getCurrentDateAsString(formatoFechasApi)
    const cacheKey = 'impuestosOptions' + JSON.stringify(data)
    const cached = sessionStorage.getItem(cacheKey)
    if (cached) {
      return { error: false, auto: JSON.parse(cached) }
    } else {
      const resApi: any = await RequestHelper.getAll<Array<TImpuestos>>(
        'iva',
        'listar',
        '',
        data,
      )
      if (resApi?.auto && resApi?.auto.length > 0) {
        const parseApi = parseApiImpuestos(resApi?.auto)
        sessionStorage.setItem(cacheKey, JSON.stringify(parseApi))
        resApi.auto = parseApi
      }
      return resApi
    }
  } catch (error) {
    return error
  }
}

function parseApiImpuestos(apiresult: any) {
  const dataParsed: Array<TImpuestos> = []
  for (const item of apiresult) {
    dataParsed.push({
      ivaCodigo: parseInt(item?.ivaCodigo) ?? 0,
      ivaTipo: item?.ivaTipo ?? '',
      ivaDescripcion: String(item?.ivaDescripcion) ?? '',
      ivaValor: parseInt(item?.ivaValor) ?? 0,
      paisCodigo: parseInt(item?.paisCodigo) ?? 0,
      ivaSri: String(item?.ivaSri) ?? '',
    })
  }
  return dataParsed
}

async function tokenHaciendaActualizar(objeto: any): Promise<any> {
  try {
    const data = await RequestHelper.postAll<Respuesta>(
      'integraciones',
      'sv/haciendaCredential',
      objeto,
    )
    return data
  } catch (error) {
    return error
  }
}
