import { OptionCiudad } from "../../../../../containers/component/formLocal/types/types"
import { TabsState } from "../../../../../store/genericTabTypes"
import { CustomDictionary, FETCH_STATUS, LoaderInfo, TEstado } from "../../../../../store/types"
import { TOptionGenerico } from "../../../../clientes/pages/clientes/types/types"
import { EstadosCivilOption } from "../../../../componentes/estadosCivilLookUp/store/type"
import { LocalOption } from "../../../../componentes/localesLookUp/store/type"
import { NivelesEstudioOption } from "../../../../componentes/nivelesEstudioLookUp/store/type"
import { PaisOption } from "../../../../componentes/paisesLookUp/store/type"
import { TipoIdentificacion } from "../../../../componentes/tipoIdentificacion/store/types"
import { OptionTiposPersonas } from "../../../../componentes/tiposPersonasLookUp/types/types"
import { EstadoOption } from "../../../../shared/types/types"
import { MenuState } from "../../../../ventas/types/types"




export enum PersonalNuevoTabs {
  personal = 'Personal',
  estudios = 'Estudios',
  extras = 'Extras',
  login = 'Login',
  rentas = 'Rentas',
  normas = 'Nómina',
}


export enum GenerosCompletos {
  m = 'Masculino',
  f = 'Femenino',
  n = 'N/A'
}

export enum AsigmentType {
  empleado = "NOMINA EMPLEADO",
  organico = "NOMINA ORGANICO"
}

export const AsigmentTransaction = [
  "sueldo",
  "ajustes",
  "nocturno",
  "suple",
  "extra",
  "comision",
  "irenta",
  "apersonal",
  "bonificacion",
  "bonificacionna",
  "freserva",
  "irentaxp",
  "apersonalxp",
  "freservaxp",
  "quirografariosxp",
  "hipotecariosxp",
  "anticipos",
  "descuentos",
  "multas",
  "prestamos",
  "nominaxp",
  "dtsueldo",
  "pdtsueldoxp",
  "dcsueldo",
  "pcsueldoxp",
  "apatronal",
  "apatronalxp",
  "vacacion",
  "vacacionxp",
  "descuentoNomina",
]

export type PersonalList = {
  codigo: number
  nombres: string
  apellidos: string
  nombre: string
  idsesion: string
  cedula: string
  fcreacion: string
  fnacimiento: string
  direccion: string
  numero: string
  telefono: string
  celular1: string
  celular2: string
  email: string
  tipo: string
  ciuCodigo: number
  ciuNombre: string
  ciuDepCodigo: number
  proCodigo: string
  proNombre: string
  proRDepCodigo: number
  ideCodigo: string
  pase: string
  password: string
  genero: number
  ecivil: number
  foto: string
  firma: string
  titulos: string
  estado: number
  tipoSangre: string
  codigoIess: number
  ciuOrigen: number
  paiCodigo: number
  paiNombre: string
  cursos: string
  host: string
  nivelCodigo: number
}

export type UsuarioLocalList = {
  codigoUsuario: number
  nombresUsuario: string
}

export type RolXMLList = {
  codigo: number
  descripcion: string
  padre: number
  padreNom: string
  siglas: string
  estado: number
}

export type RolesList = {
  organicoRolCodigo: number
  organicoCodigo: number
  organicoDescripcion: string
  rolCodigo: number
  rolDescripcion: string
}


export type UsuariosRolList = {
  organicoRolUsuario: number
  organicoRol: number
  organico: number
  descripcion: string
  rolDescripcion: string
  codigoUsuario: number
  nombresUsuario: string
}

export type PermisosList = {
  codigo: number
  menuBotCodigo: number
  menuCodigo: number
  menuNombre: string
  indexNombre: string
  botonCodigo: number
  botonDescripcion: string
  asignado: number
  tipo: number
  tipoDescripcion: string
}


export type OpcionesList = {
  codigo: number
  name: string
  url: string
  icon: string
  padre: number
  hijos?: Array<any> | null
  acciones?: Array<any> | null
}

export type MenuButtonList = {
  codigo: number
  icon: string
  name: string
  mebcodigo: number
  modal: string
  main: string
  order: number
}

export type BusquedaState<PersonalList> = {
  filtro: FiltroBusquedaPersonal
  filtroUsuarioLocal: FiltroUsuariosLocales
  status: FETCH_STATUS
  mensaje: string
  resultados: Array<PersonalList>
  resultadosUsuarioLocal: Array<UsuarioLocalList>
  resultadosUsuarioSinLocal: Array<UsuarioLocalList>
  colapsado: boolean
  import: boolean
  searchPersonal: boolean
  seleccionado: PersonalList
  seleccionadoUsuario: UsuarioLocalList
  seleccionadoLocal: UsuarioLocalList
  loader: LoaderInfo
  loaderUserLocal: LoaderInfo
  tabs: Array<string>
  currentTab: string
  nroRegistrosEncontrados: number
}


export type RolState<RolXMLList> = {
  status: FETCH_STATUS
  mensaje: string
  loader: LoaderInfo
  initial: boolean
  resultadosOrganigrama: Array<RolXMLList>
  resultadosRoles: Array<RolesList>
  resultadosUsuarios: Array<UsuarioLocalList>
  resultadosUsuariosRol: Array<UsuariosRolList>
  seleccionadoUsuarios: UsuarioLocalList
  seleccionadoRoles: RolesList
  seleccionadoUsuariosRol: UsuariosRolList
}


export type PermisosState<PermisosList> = {
  status: FETCH_STATUS
  mensaje: string
  loader: LoaderInfo
  initial: boolean
  resultadosPermisos: Array<PermisosList>
  resultadosOpciones: Array<OpcionesList>
  resultadosComandos: Array<MenuButtonList>
  seleccionadoComando: MenuButtonList
  seleccionadoPermiso: PermisosList
}

export type SaveRollUsuario = {
  organicoRolCodigo: number
  usuarioCodigo: number
}

export type FiltroBusquedaPersonal = {
  usuario: string
  cedula: string
  nombres: string
  apellidos: string
  pais: PaisOption | null
  locales: LocalOption | null
  activos: boolean
  initital: boolean
}

export type FiltroUsuariosLocales = {
  tipoUsuario: OptionTiposPersonas | null
  locales: LocalOption | null
  initial: boolean
}

export type PersonalesDatosEdicion = {
  tipoId: TipoIdentificacion | null,
  cedula: string
  codigoIESS: string,
  nombres: string
  apellidos: string
  fechaNacimiento: string
  genero: { label: string, value: number }
  estadoCivil: EstadosCivilOption | null
  cargasFamiliares: number
  tipoSangre: TEstado | null
  estado: EstadoOption | null
  ciudadReferencia: OptionCiudad | null
  ciudadOrigen: OptionCiudad | null
  direccion: string
  numero: string
  email: string
  telefonos: Array<any>,
}

export type EstudioDatosEdicion = {
  estudios: NivelesEstudioOption | null
  titulos: string
  cursos: string
}

export type ExtrasDatosEdicion = {
  fotos: string,
  fotosFile: any,
  fotosName: string,
  firma: string,
  firmaFile: any,
  firmaName: string,
}

export type LoginDatosEdicion = {
  creado: string
  usuario: string
  password: string
  confirmarPassword: string
  codigoPase: string,
  codigoPaseImagen: string,
  lugarCC: LocalOption | null
  tipoUsuario: OptionTiposPersonas | null
}

export type RentasList = {
  codigo: number
  vivienda: number
  salud: number
  eduacacion: number
  alimentacion: number
  vestimenta: number
  discapacitados: number
  terceraedad: number
  anio: number
  usuario: number
  renta: number
  ingresos: number
  deducciones: number
  rebajas: number
  retencion: number
  rotros: number
  retenciones: number
}

export type RentasDatosEdicion = {
  anio: number
  codigoREM: number
  vivienda: number
  salud: number
  educacion: number
  alimentacion: number
  vestimenta: number
  discapacitados: number
  terceraEdad: number
  iRporEpleado: number
  iGEmpleadores: number
  dgpEmpleadores: number
  orcEmpleadores: number
  virEmpleadores: number
  numeroRetenciones: number
  virEmpleadoresAnteriores: number
  rentas: Array<RentasList>
  seleccionadoRenta: RentasList | null
}

export type SeleccionadoDescuento = {
  item: number
  descripcion: string
  tipo: number
  transaccion: string
  codigo?: number
  planNumero?: string
  planDescripcion?: string
  enUso?: number
}

export type DescuentosDatosEdicion = {
  numeroDescuento: string
  codigoDescuento: number
  descripcionDescuento: string
  descuentoNomina: Array<SeleccionadoDescuento>
  nomina: Array<any>
  radioOption: TOptionGenerico | null
  seleccionadoDescuento: SeleccionadoDescuento | null
}

export type RolesPagoDatosEdicion = {
  sueldo: string
  ajustes: string
  nocturno: string
  suple: string
  extra: string
  comision: string
  irenta: string
  apersonal: string
  bonificacion: string
  bonificacionna: string
  freserva: string
  irentaxp: string
  apersonalxp: string
  freservaxp: string
  quirografariosxp: string
  hipotecariosxp: string
  anticipos: string
  descuentos: string
  multas: string
  prestamos: string
  nominaxp: string
  dtsueldo: string
  pdtsueldoxp: string
  dcsueldo: string
  pcsueldoxp: string
  apatronal: string
  apatronalxp: string
  vacacion: string
  vacacionxp: string
  descuentoNomina: string
  rolesPago: Array<string>
}

export type NominaDatosEdicion = {
  rolesPago: RolesPagoDatosEdicion
  descuentos: DescuentosDatosEdicion
}

export type PersonalDatosEdicion = {
  personales: PersonalesDatosEdicion
  estudios: EstudioDatosEdicion
  extras: ExtrasDatosEdicion
  login: LoginDatosEdicion
  rentas: RentasDatosEdicion
  nomina: NominaDatosEdicion
  codigo: number
  idSesion: string,
  ciudadCodigo: number,
  cargas: number,
  loader: LoaderInfo | null,
  loading: boolean
}

export type Asignacion = {
  codigo: number
  vinculacion: number
  tipo: string
  planCuentasCodigo: number
  transaccion: string
}

export type PersonalState = {
  config: configPersonal
  menu: MenuState
  tabs: TabsState<PersonalDatosEdicion>
  editData: CustomDictionary<PersonalDatosEdicion>
  search: BusquedaState<PersonalList>
  organigrama: RolState<RolXMLList>
  permisos: PermisosState<PermisosList>
}

export type Contactos = {
  numero: string
  descripcion: string
  tipo: number
}


export type SavePersonal = {
  infoRegistro: {
    codigo: number
    nombres: string
    apellidos: string
    idsesion: string
    pase: string
    password: string
    cedula: string
    codiess: string
    direccion: string
    numero: string
    fnacimiento: string
    email: string
    firma: string
    ciudadCodigo: number
    ideCodigo: string
    sexo: number
    ecivil: number
    cargas: number
    foto: string
    nivelCodigo: number
    estado: number
    tiposangre: string
    titulos: string
    cursos: string
    ciudadOrigen: number
    contactos: Contactos[]
  },
  infoLocal: {
    codigo: number
  }
}


export type RentasSave = {
  infoRegistro: {
    codigoREM: number
    alimentacion: number
    anio: number
    deducciones: number
    discapacitados: number
    educacion: number
    ingresos: number
    rebajas: number
    renta: number
    retencion: number
    retenciones: number
    rotros: number
    salud: number
    terceraEdad: number
    vestimenta: number
    vivienda: number
    usuario: number
  }
}

export type configPersonal = {
  aplicacion: number
  editData?: CustomDictionary<PersonalDatosEdicion>

}

export type SavePermises = {
  infoRegistro: {
    codigo: number
    menuBotCodigo: number
    asignado: number
    tipo: number
  }
}

export type PersonalListDto = {
  Identification: string
  User: string
  Firstnames: string
  Lastnames: string
  Direction: string
  Email: string
  Cellphone: string
}


export type PermisesUserDto = {
  Identification: string
  User: string
  Name: string
  CreatioDate: string
  Email: string
  Place: string
  Cellphone: string
  Permises: Permises[]
}


export type Permises = {
  MenuName: string
  ButtonDescription: string
  TypeDescription: string
}

export type PersonalDto = {
  Code: number
  TipoId: string
  Identification: string
  IESSCode: string
  Firstnames: string
  Lastnames: string
  Gender: string
  CivilStatus: string
  FamilyResponsabilities: number
  BloodType: string
  Status: string
  PlaceResidence: string
  PlaceOrigin: string
  Address: string
  Email: string
  EducationLevel: string
  DateCreation: string
  User: string
  Cellphones: Cellphons[]
}


export type Cellphons = {
  Number: string
  Description: string
}