import React from 'react'
import { useSelector } from 'react-redux'
import DataGrid, {
  Column,
  Paging,
  Pager,
  ColumnChooser,
  HeaderFilter,
  Item,
  Export,
} from 'devextreme-react/data-grid'
import { DatosVenta } from '../../../../types/types'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { RootState } from '../../../../../../../../../../../store/store'
interface ITablaVentasProps extends React.PropsWithChildren {
  data: Array<DatosVenta> | []
  // eslint-disable-next-line no-unused-vars
  onExport: (data) => void
}
const pageSizes = [10, 25, 50, 100, 200]

const TablaVentas: React.FunctionComponent<ITablaVentasProps> = (props) => {
  const { data, onExport } = props

  const dataGrid = React.useRef<any>()
  const filtros = useSelector((state: RootState) => {
    return state.impuestosSV.anexoTransaccional.search.filter
  })

  const onExportExcell = React.useCallback(
    (e) => {
      const rango = `${filtros?.fechaInicio} - ${filtros?.fechaFinal}`
      const fileName = `anexo_consumidor_final-${rango}`
      if (e.format === 'xlsx') {
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet('Consumidores Finales')
        e.component.exportDataFieldHeaders = false
        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: 'application/octet-stream' }),
              fileName + '.xlsx',
            )
          })
        })
      } else if (e.format === 'pdf') {
        // const doc = new jsPDF();
        // exportDataGridToPdf({
        //   jsPDFDocument: doc,
        //   component: e.component
        // }).then(() => {
        //   doc.save('anexo_consumidor_final.pdf');
        // })
      } else if (e.format === 'csv') {
        onExport({
          title: 'Consumidores Finales',
          format: e.format,
          component: e.component,
          filename: fileName,
        })
      }
    },
    [filtros, onExport],
  )

  return (
    <DataGrid
      ref={dataGrid}
      selection={{ mode: 'single' }}
      allowColumnResizing={true}
      columnResizingMode={'nextColumn'}
      focusedRowEnabled={true}
      keyExpr="codigo"
      focusedRowKey="codigo"
      dataSource={data}
      showBorders={true}
      onExporting={onExportExcell}
      width={'100%'}
    >
      {/* <Toolbar> */}
      {/* <Item>
          <Button
            onClick={() => {
              onExport(data)
            }}
            icon="exportxlsx"
            disabled={false}
            text="Exportar"
          />
        </Item> */}
      {/* <Item> */}
      <Export
        enabled={true}
        allowExportSelectedData={false}
        formats={['csv', 'xlsx']}
      />
      {/* </Item> */}
      <Item name="columnChooserButton" />
      {/* </Toolbar> */}

      <ColumnChooser enabled={true} mode="select" />
      <HeaderFilter visible={true} />

      <Column
        dataField="fechaNegociacion"
        caption="FECHA DE EMISIÓN" // A
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="claseDocumento"
        caption="CLASE DE DOCUMENTO" // B
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="tipoDocumentoCodigo"
        caption="TIPO DE DOCUMENTO" // C
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="numeroResolucion"
        caption="NÚMERO DE RESOLUCIÓN" // D
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="serieDocumento"
        caption="SERIE DE DOCUMENTO" // E
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />

      <Column
        dataField="numControlInternoDel"
        caption="NÚMERO DE CONTROL INTERNO DEL" // F
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="numControlInternoAL"
        caption="NÚMERO DE CONTROL INTERNO AL" // G
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />

      <Column
        dataField="numDocumentoDel"
        caption="NÚMERO DE DOCUMENTO DEL" // H
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="numDocumentoAl"
        caption="NÚMERO DE DOCUMENTO AL" // I
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />

      <Column
        dataField="numeroMaquinaRegistradora"
        caption="NÚMERO DE MAQUINA REGISTRADORA" // J
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="subtotal0" //hay que calcularlo
        caption="VENTAS EXENTAS" // K
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="ventasProporcionalidad" //hay que calcularlo
        caption="VENTAS INTERNAS EXENTAS NO SUJETAS A PROPORCIONALIDAD " // L
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="ventasNoSujetas"
        caption="VENTAS NO SUJETAS" // M
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="subtotalIva"
        caption="VENTAS GRAVADAS LOCALES" // N
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />

      <Column
        dataField="expoDentroCentroAmerica"
        caption="EXPORTACIONES DENTRO DEL ÁREA DE CENTROAMÉRICA" // O
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />

      <Column
        dataField="expoAfueraCentroAmerica"
        caption="EXPORTACIONES FUERA DEL ÁREA DE CENTROAMÉRICA" // P
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="exportacionesServicios"
        defaultVisible={false}
        caption="EXPORTACIONES DE SERVICIO" // Q
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="ventasZonasFrancasDPA"
        defaultVisible={false}
        caption="VENTAS A ZONAS FRANCAS  Y DPA (TASA CERO)" // R
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="ventasZonasFrancas"
        caption="VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS" // S
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="total"
        caption="TOTAL DE VENTAS" // T
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={false}
      />
      <Column
        dataField="numeroAnexo"
        caption="NÚMERO DEL ANEXO" // U
        allowEditing={false}
        visible={true}
        allowSearch={false}
        allowFiltering={true}
      >
        <HeaderFilter allowSearch={true} />
      </Column>

      <Pager
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
        showInfo
      />
      <Paging defaultPageSize={10} />
    </DataGrid>
  )
}
export default TablaVentas
