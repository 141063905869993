import { CCol, CContainer, CRow } from '@coreui/react-pro'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../../../../store/toasterReducer'
import { TiposComprobantesSLV, TiposComprobantesSri, ToastTypes } from '../../../../store/types'
import CustomCol from '../../../../views/componentes/colContainer'
import Labeled from '../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import { VentasService } from '../../services/ventas.service'
import { Cliente, InfoDocumentoVentaAfectar, TipoIngresoDocumentos } from '../../types/types'
import CustomModalDevx from '../../../../views/componentes/modal/Modal'
import BuscarClienteLookUp from '../busquedaCliente/busquedaCliente'
import LoadPanel from 'devextreme-react/load-panel'
import DataGrid, { Column, SearchPanel, HeaderFilter, Scrolling, LoadPanel as GridLoadPanel, Button as DatagridButton, Paging, Pager } from 'devextreme-react/data-grid';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { ValidationGroup } from 'devextreme-react/validation-group';
import {
  Validator,
  PatternRule, RequiredRule
} from 'devextreme-react/validator';
import { useModalConvertSize } from '../../../../hooks/useModalSize'
import { isMobileOnly } from 'react-device-detect'
import RadioGroup from 'devextreme-react/radio-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import { utilidades } from '../../../../helpers/utilidades'
import { CompraService } from '../../../compras/services/compra.service'
import { consoleService } from '../../../../services/console.service'
import { onParseComprToVentaType } from './helpers/helpers'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux';
import { ECountry } from '../../../../store/enum/countries'
import { getAllowedPageSizes } from '../../../../helpers/Helper'

const options = [{ id: 0, text: 'NO' }, { id: 1, text: 'SI' }];

interface Props extends React.PropsWithChildren {
  tipo: TiposComprobantesSri | TiposComprobantesSLV,
  onOk: (documento: InfoDocumentoVentaAfectar) => void,
  onCancel: () => void
  modulo?: number
  tipoComprobante?: string
}

const DocumentoVentaAfectar = (props: Props) => {
  const { tipo, onOk, onCancel, tipoComprobante = 'venta' } = props;
  const dispatch = useDispatch();
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });

  const [cliente, setCliente] = React.useState<Cliente | null>(null);
  const [comprobante, setComprobante] = useState('');
  const [facturas, setFacturas] = useState<Array<InfoDocumentoVentaAfectar>>([])
  const [buscando, setBuscando] = useState(false);
  const [modalSize] = useModalConvertSize("md");
  const [loadPanelEnabled, setloadPanelEnabled] = useState(true);
  const validationGroupRef = React.useRef<any>();
  const validationGroupAddDocRef = React.useRef<any>();
  const [checkedDocumento, setCheckedDocumento] = React.useState<{ id: number, text: string }>(options[0]);
  const [documento, setDocumento] = useState('');

  const onAddDocument = React.useCallback(() => {

    const validationResult = validationGroupAddDocRef.current.instance.validate();
    if (!validationResult.isValid && validationResult.status !== 'pending') {
      return false;
    }
    const parseDocumento = documento.split('-');
    let puntoEmision = "";
    let establecimiento = "";
    let numeroFactura = "";
    if (parseDocumento.length === 3) {
      puntoEmision = parseDocumento[0];
      establecimiento = parseDocumento[1];
      numeroFactura = utilidades.zeroFill(parseDocumento[2], 9);
    }

    if (documento === '') {
      dispatch(addToast({
        title: 'Número del documento.',
        content: "Ingresar el número del documento por favor.",
        type: ToastTypes.Info,
      }));
      return false;
    }
    const addDocument: InfoDocumentoVentaAfectar = {
      codigo: 0,
      tipoComprobante: TiposComprobantesSri.Factura,
      tipoComprobanteNombre: "",
      numero: `${puntoEmision}-${establecimiento}-${numeroFactura}`,
      clienteCodigo: 0,
      clienteIdentificacion: "",
      clienteNombre: "",
      clienteDireccion: "",
      clienteTelefono: "",
      asociado: "",
      clienteEmail: "",
      subtotal0: 0,
      subtotal12: 0,
      subtotalNoIva: 0,
      descuento: 0,
      descuentoGlobal: 0,
      iva: 0,
      total: 0,
      fecha: new Date(),
      formaPagoCodigo: 0,
      formaPagoDescripcion: "",
      puntoVenta: "",
      tipoIngreso: TipoIngresoDocumentos.manual
    }
    onOk(addDocument)

  }, [onOk, documento, dispatch]);


  const onEnterAddDocumento = React.useCallback(() => {
    if (documento) {
      onAddDocument()
    }
  }, [documento, onAddDocument]);

  const onSelectDocument = React.useCallback(async (data: InfoDocumentoVentaAfectar) => {
    const authDocument = await VentasService.getAutorizacionDocumento(data.codigo)
    consoleService.log("authDocument:", authDocument)
    data.codigoGeneracion = authDocument?.codigoGeneracion ?? ''
    consoleService.log("data:", data)
    if (empresa.codigoPais === ECountry.ElSalvador) {
      if (tipo === TiposComprobantesSLV.NotaDebito || tipo === TiposComprobantesSLV.NotaCredito) {
        if (['07', '03'].includes(data.tipoComprobante.toString())) {
          data.tipoComprobante = data.tipoComprobante as TiposComprobantesSLV;
          onOk(data);
          return true
        } else {
          dispatch(addToast({
            title: 'Documento no permitido.',
            content: "Este tipo de documento no puede ser utilizado para emitir el comprobante.",
            type: ToastTypes.Info,
          }));
          return false;
        }
      }
    }

    onOk(data);

  }, [onOk, empresa, tipo, dispatch]);

  const onRowDoubleClick = React.useCallback((e) => {
    const { data } = e;
    const documentoAfectar = data as InfoDocumentoVentaAfectar;
    console.log("documentoAfectar", documentoAfectar)

    onSelectDocument(documentoAfectar);
    //props.onDocumentSelected(data);
    //console.log(data);
  }, [onSelectDocument]);

  const filtrarPorTipoComprobante = useCallback(async (objetos: any[]) => {
    let filtradosDte = []

    // return only auth
    await objetos.forEach(objeto => {
      if (objeto.autorizacionExtras?.selloRecibido && objeto.autorizacionExtras?.selloRecibido.length > 0) {
        filtradosDte.push(objeto)
      }
    });

    if (tipo === TiposComprobantesSLV.NotaCredito.toString()) {
      filtradosDte = filtradosDte.filter(objeto => objeto.tipoComprobante === '03' || objeto.tipoComprobante === '07');
    }
    if (tipo === TiposComprobantesSLV.NotaDebito.toString()) {
      filtradosDte = filtradosDte.filter(objeto => objeto.tipoComprobante === '03' || objeto.tipoComprobante === '07');
    }
    if (tipo === TiposComprobantesSLV.NotaRemision.toString()) {
      filtradosDte = filtradosDte.filter(objeto => objeto.tipoComprobante === '01' || objeto.tipoComprobante === '03');
    }

    return filtradosDte;
  }, [tipo]);

  const buscarFacturas = useCallback(async () => {
    try {
      const validationResult = await validationGroupRef.current?.instance.validate();
      //console.log('validationResult',validationResult)
      let _facturas = null
      if (validationResult === undefined || validationResult?.isValid) {
        setBuscando(true);
        if (tipoComprobante === 'venta') {
          _facturas = await VentasService.buscarDocumentosAfectar(cliente ? cliente.identificacion : '', '', comprobante ?? '', tipo);
        } else if (tipoComprobante === 'compra') {
          _facturas = await CompraService.buscarComprobantesModificar(cliente ? cliente.identificacion : '');
          _facturas = onParseComprToVentaType(_facturas)
        }
        let objetosFiltrados = _facturas;
        if (empresa.codigoPais === ECountry.ElSalvador) {
          objetosFiltrados = await filtrarPorTipoComprobante(_facturas);
        }
        setFacturas(objetosFiltrados);
      }
    } catch (error) {
      consoleService.log('error:', error)

      dispatch(addToast({
        title: 'Buscar documentos',
        content: (typeof error == 'string' ? error : JSON.stringify(error)),
        type: ToastTypes.Warning,
      }

      ))
    }
    setBuscando(false);
  }, [dispatch, tipo, cliente, comprobante, tipoComprobante, filtrarPorTipoComprobante])


  useEffect(() => {
    buscarFacturas();
  }, [buscarFacturas])

  return (
    <CustomModalDevx
      id="listadoComprobantes"
      title='Listado de Comprobantes'
      onClose={onCancel}
      show
      size={modalSize}
      height={window.innerHeight * 0.8}
    >
      <ValidationGroup id={`valGroupDocumentoVentaAfectar`} ref={validationGroupRef}>
        <CContainer
          fluid
        >
          <RowContainer>
            <CustomCol xs='12' md='8'>
              <Labeled
                label='Cliente'
              >
                <BuscarClienteLookUp allowClear selected={cliente} onChanged={(value) => setCliente(value)} />
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='12'
              md='4'
            >
              <Labeled
                label='Comprobante'
              >
                <TextBox
                  value={comprobante}
                  onValueChanged={({ value }: any) => setComprobante(value)}
                >
                  <Validator>
                    <PatternRule pattern='^[0-9]{1,10}$' message='El campo comprobante es numerico.' />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <ValidationGroup id={'validationGroupAddDocumento'} ref={validationGroupAddDocRef}>

            <RowContainer>
              <CustomCol xs='12' md='8'>
                <Labeled
                  label='Ingresar Documento'
                >
                  <RadioGroup
                    name="ingresarDoc"
                    layout='horizontal'
                    items={options}
                    value={checkedDocumento}
                    onValueChanged={(e) => {
                      setCheckedDocumento(e.value)
                    }}
                  />
                  {checkedDocumento.id === 1 &&
                    <RowContainer>
                      <CustomCol xs='12' md='8'>
                        <TextBox
                          value={documento}
                          placeholder='000-000-000000000'
                          useMaskedValue={true}
                          onValueChanged={({ value }: any) => setDocumento(value)}
                          onEnterKey={() => {
                            onEnterAddDocumento()
                          }}
                        >
                          <TextBoxButton
                            name="btnEye"
                            location="after"
                            options={{
                              type: "normal",
                              stylingMode: "contained",
                              icon: 'plus',
                              onClick: () => {
                                onAddDocument()
                              }
                            }}
                          />
                          <Validator>
                            <PatternRule message={"El número del documento no tiene el formato correcto."} pattern='^[0-9]{3}-[0-9]{3}-[0-9]{1,10}$' />
                            <RequiredRule message={"El número del documento es requerido."} />
                          </Validator>
                        </TextBox>
                      </CustomCol>
                    </RowContainer>
                  }
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          </ValidationGroup>

          <CRow className='mt-4'>
            <CCol xs="12" sm="12" md="12" lg="12">

              <DataGrid
                focusedRowEnabled={true}
                keyExpr='codigo'
                hoverStateEnabled={true}
                dataSource={facturas}
                showBorders={true}
                onRowDblClick={onRowDoubleClick}
                height={isMobileOnly ? `${window.innerHeight * 0.7}px` : `${(window.innerHeight * 0.7).toFixed(0)}px`}
                width='100%'
                onContentReady={() => setloadPanelEnabled(false)}
              >
                <SearchPanel
                  visible={true}
                  highlightCaseSensitive={true}
                  width={window.innerWidth < 920 ? 720 : 160} />
                <HeaderFilter visible={true} />
                < Column type='buttons' width='40px' >
                  <DatagridButton text='+' icon='plus' hint='Selecionar' onClick={async (e) => {
                    const data: InfoDocumentoVentaAfectar = e.row.data;
                    onSelectDocument(data);
                  }} />
                </Column>
                <Column dataField='fecha' dataType='date' width='80px' allowSearch={false} />
                <Column dataField='tipoComprobanteNombre' caption='Tipo Comprobante' width='100px' allowSearch={false} />
                <Column dataField='numero' caption='Número' width='120px' />
                <Column dataField='clienteNombre' caption='Cliente' width={window.innerWidth < 1360 ? 'auto' : '*'} />
                <Column dataField='total' caption='Total' width='100px' alignment='right' allowSearch={false} />
                <Column dataField='formaPagoDescripcion' caption='Forma Pago' width='120px' allowSearch={false} />
                <Column dataField='puntoVenta' caption='P. Venta' width='80px' allowSearch={false} />
                <Scrolling mode="virtual" />
                <GridLoadPanel enabled={loadPanelEnabled} />
                <Paging defaultPageSize={20} />
                <Pager
                  visible={true}
                  allowedPageSizes={getAllowedPageSizes(facturas)}
                  displayMode={'full'}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </CCol>
          </CRow>

        </CContainer>
      </ValidationGroup>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={buscando}
        showIndicator={true}
        shading={true}
        showPane={true}
        message='Buscando...'
        closeOnOutsideClick={false}
      />
    </CustomModalDevx>
  )
}

export default DocumentoVentaAfectar