
/* eslint-disable no-undef */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ComprobantesService } from '../comprobantes.service';
import { setLoadSummaryDocs } from '../../../../store/reducers';
import { setContratoEmpresa } from '../../registraPagos/store/pagosSuscripcionReducer';
import { useNavigate } from 'react-router-dom';
import { addNotification } from '../../../../store/notificationsReducer';
import { ToastTypes } from '../../../../store/types';
import { RecurrenteData } from './type/type';
import { restaFechas } from '../../../../helpers/Helper';
import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils';
import { setCuotasVencidas } from '../../../../modulos/cuenta/upgrate/store/upgrateReducer';
import { setVencidas } from '../../../../modulos/cuenta/deudas/store/pastduePaymentsReducer';
import { DepositosService } from '../../../../services/depositos.services';
import { consoleService } from '../../../../services/console.service';
import { ECountry } from '../../../../store/enum/countries';
import { EAmbiente } from '../../../../services/itemEnum/enumSV';
import { getAmbiente } from '../../../../helpers/sv/utilitiesSv';
import { licenceHelper } from '../../../../helpers/licenceHelper';

interface IContadorDocumentosProps extends React.PropsWithChildren {
  show: boolean,
}

export enum TipoCorte {
  // eslint-disable-next-line no-unused-vars
  mora = 'mora',
  // eslint-disable-next-line no-unused-vars
  caducidad = 'caducidad',
  // eslint-disable-next-line no-unused-vars
  default = 'default'
}

export enum MotivoCaducidad {
  // eslint-disable-next-line no-unused-vars
  none = 'none',
  // eslint-disable-next-line no-unused-vars
  tiempo = 'tiempo',
  // eslint-disable-next-line no-unused-vars
  primerPago = 'primerPago',
  // eslint-disable-next-line no-unused-vars
  comprobantes = 'comprobantes'
}

const ContadorDocumentos: React.FC<IContadorDocumentosProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const loadSummaryDocs = useSelector((state: RootState) => state.global.loadSummaryDocs);
  const sesionState = useSelector((state: RootState) => state.global.session);
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });
  const procesandoPago = useSelector((state: RootState) => state.global.procesandoPago);
  const configEmpresaState = useSelector((state: RootState) => state.global?.session?.empresa?.configuracion);
  const contratoEmpresa = useSelector((state: RootState) => state.pagosSuscripcion?.contratoEmpresa);




  const onVerificatePorCaducar = React.useCallback(async (contratoData: RecurrenteData) => {
    consoleService.log("onVerificatePorCaducar")
    consoleService.log("fechaFin : ", contratoData['fechaFin'])

    let fecha: Date = null;
    let fechaString: string = '';
    let cantidadDias: number = 0;

    if (contratoData['fechaFin'] !== null) {
      fecha = DateUtils.strDateToDate(contratoData['fechaFin'], formatoFechasApi);
      fechaString = DateUtils.dateToString(fecha, formatoFechasApi);
    }

    const fechaHoy = DateUtils.dateToString(new Date(), formatoFechasApi);
    consoleService.log('fechaHoy', fechaHoy)

    if (fechaString !== '') {
      const cantidad = restaFechas(fechaHoy, fechaString);
      consoleService.log('cantidad', cantidad)
      cantidadDias = Number(cantidad);
    }
    consoleService.log('cantidadDias', cantidadDias)

    const caducado: any = {
      dias: false,
      cantidadDias: 0,
      comprobantes: false,
      cantidadComprobantes: 0,
    }
    if (contratoData?.maxComprobantes && (Number(contratoData['maxComprobantes']) > 0)) {
      const cantidadComprobantes = Number(contratoData['maxComprobantes']) - Number(contratoData['comprobantesEmitidos']);
      if (cantidadComprobantes < configEmpresaState.cantidadComprobantesCaducaContrato) {
        caducado.comprobantes = true
        caducado.cantidadComprobantes = cantidadComprobantes
      }
    }
    // Se notifica la caducidad del plan solamente por x cantidad aproximado de dias
    if (Number(cantidadDias) < configEmpresaState.cantidadDiasCaducaContrato) {
      caducado.dias = true
      caducado.cantidadDias = cantidadDias
    }
    // se valida mayor a cero porque ya cuando es igual a cero se muestra otra notificacion que retorna del back
    if (caducado.comprobantes === true && (Number(caducado?.cantidadComprobantes) >= 0)) {
      consoleService.log('if')
      dispatch(addNotification({
        content: `${"Tienes "} ${caducado?.cantidadComprobantes ?? 0} ${" comprobantes disponibles, actualiza tu plan "}`,
        type: ToastTypes.Warning,
        title: "",
        autoClose: false
      }));
    }
    if (caducado.dias === true && (Number(caducado?.cantidadDias) >= 0)) {
      consoleService.log('else')
      let sms: string = `${"Tu plan caduca en "} ${caducado?.cantidadDias ?? 0} ${" días, actualiza tu plan "}`;
      if (Number(caducado?.cantidadDias) === 0) {
        sms = `${"Tu plan caduca hoy, actualiza tu plan "}`;
      }
      dispatch(addNotification({
        content: sms,
        type: ToastTypes.Warning,
        title: "",
        autoClose: false
      }));
    }
    return caducado;
  }, [configEmpresaState, dispatch]);

  const showNotification = React.useCallback(async () => {
    if (procesandoPago === true) {
      return false
    }
    if (sesionState === null && sesionState !== undefined) {
      return false
    }
    if (sesionState.empresa !== null && sesionState.empresa?.codigo && sesionState.empresa?.codigo !== 0) {

      dispatch(setLoadSummaryDocs(false));

      try {
        const resultado = await ComprobantesService.getRecurrente(sesionState.empresa.uuid);
        consoleService.log('resultado contrato', resultado)

        // ocultar bloqueo cuando la empresa esta esta en ambiente pruebas
        if ((sesionState?.empresa?.codigoPais === ECountry.ElSalvador) && (getAmbiente(sesionState?.empresa) === EAmbiente.Pruebas)) {
          dispatch(setContratoEmpresa(resultado))
          return false;
        }

        if (resultado.fechaFin !== null) {
          await onVerificatePorCaducar(resultado);
        }

        let planActivo = false;

        if (resultado.codigo !== undefined && Number(resultado.codigo > 0)) {
          dispatch(setContratoEmpresa(resultado))

          const verificar = await DepositosService.verificarEstadoPagos();
          if (verificar.length > 0) {
            let capturar: any = null;
            verificar.forEach((element: any, i) => {
              if (i === 0) {
                capturar = element
              }
            });
            const sms = `${"Tu pago por "} ${capturar?.valor ?? 0} ${"registrado "} ${capturar?.fecha ?? ""} ${"se encuentra en revisión, por favor espera unos minutos mientras se procesa la misma."} `
            dispatch(addNotification({
              content: sms,
              type: ToastTypes.Info,
              title: "",
              autoClose: false
            }));
            return false;
          }

          planActivo = resultado.planActivo ?? false

          // redireccionar si es que la fecha de registro de la empresa fue la fecha actual, para poder hacer el cobro por la suscripcion de contrato
          if (resultado.verificado === 0) {
            navigate('/renewal');
          }

          // permite navegar si es que es un plan ilimitado
          if (resultado.fechaFin === null && resultado.maxComprobantes === 0) {
            return false;
          }

          if (planActivo === false) {
            navigate('/renewal');
            return false;
          } else {

            // se verifica si existe mora para poder redireccionar
            try {
              consoleService.log('init resultadoCheckArrears')
              const resultadoCheckArrears = await ComprobantesService.checkArrears(sesionState.empresa.codigo);
              consoleService.log("resultadoCheckArrears", resultadoCheckArrears)

              if (resultadoCheckArrears.length > 0) {
                dispatch(setCuotasVencidas(resultadoCheckArrears ?? []))
                dispatch(setVencidas(resultadoCheckArrears ?? []))
                navigate('/past_due_payments');
              }

            } catch (error) {
              consoleService.error(error)
            }

          }

        } else {
          let sms = "Se produjo un error al verificar el contrato";
          if (resultado.mensajeMotivo) {
            sms = resultado?.mensajeMotivo.replaceAll('"', '');
          }
          dispatch(addNotification({
            content: sms,
            type: ToastTypes.Danger,
            title: "",
            autoClose: false
          }))
          navigate('/');
        }

      } catch (e) {
        consoleService.warn(e)
        dispatch(addNotification({
          content: JSON.stringify(e).replaceAll('"', '') ?? "Se produjo un error al verificar el contrato",
          type: ToastTypes.Danger,
          title: "",
          autoClose: false
        }))
        navigate('/');

      }

    }
  }, [sesionState, onVerificatePorCaducar, navigate, dispatch, procesandoPago]);

  React.useEffect(() => {
    if (licenceHelper.isLicence() === false) {
      if (sesionState !== undefined && loadSummaryDocs === true && (puntoVenta?.length !== null || puntoVenta?.length !== undefined)) {
        showNotification()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sesionState, loadSummaryDocs, puntoVenta]);

  return (<> </>)
}

export default React.memo(ContadorDocumentos);
