import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCol, CContainer, CRow, CTooltip } from '@coreui/react-pro';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DataGrid, {
  Column,
  Editing
} from 'devextreme-react/data-grid';
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box';
import DateBox from 'devextreme-react/date-box';
import Switch from 'devextreme-react/switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { TextBox, Validator } from 'devextreme-react';
import { AsyncRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';

import CustomModalDevx from '../../../views/componentes/modal/Modal';
import { CuotaCreditoVenta } from '../../ventas/types/types';
import { useModalConvertSize } from '../../../hooks/useModalSize';
import { utilidades } from '../../../helpers/utilidades';
import { DateUtils, formatoFechasApi, formatoFechasDatePickers } from '../../../helpers/dateUtils';
import { addToast } from '../../../store/toasterReducer';
import { ToastTypes } from '../../../store/types';
import { RootState } from '../../../store/store';
import applyChanges from 'devextreme/data/apply_changes';
import { BotonMenu } from '../../contabilidad/pages/planCuentas/store/types';
import { menuService } from '../../../services/menu.service';
import { ButtonTypes } from '../../../views/componentes/globalMenu/types';
import RowContainer from '../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../views/componentes/colContainer';
import Labeled from '../../../views/componentes/labeledInput/labeledInput';
import { EModulosAcatha } from '../../../store/enumsAplication';


enum ICredito {
  Dias = 'Días',
  Credito = 'crédito'
}


export interface ICreditosProps extends React.PropsWithChildren {
  totalCobrar: number,
  fechaFactura: string,
  cuotas: Array<CuotaCreditoVenta>,
  onOk: (cuotas: Array<CuotaCreditoVenta>) => void,
  onCancel: () => void
  moduloCodigo: number
  facturaCompra?: string
}


const menuDefectoSearch = [
  { icon: 'fal fa-save', id: "Guardar", text: "Guardar", type: ButtonTypes.save },
  // { icon: 'fal fa-print', id: "Imprimir", text: "Imprimir", type: ButtonTypes.print },
  { icon: 'fal fa-sync', id: "Refrescar", text: "Refrescar", type: ButtonTypes.regenerate },
  { icon: 'fal fa-trash', id: "Anular", text: "Anular", type: ButtonTypes.disable },
  { icon: 'fal fa-calculator', id: "Recalcular", text: "Recalcular", type: ButtonTypes.undo },
];




export const ModalCreaditos: React.FC<ICreditosProps> = (props) => {
  const { onCancel, onOk, fechaFactura, totalCobrar, cuotas, moduloCodigo, facturaCompra } = props;
  const dispatch = useDispatch();
  //const [periodos, setPeriodos] = React.useState(["ICredito, Meses"]);
  const [periodo, setPeriodo] = React.useState("Meses");
  const [modalSize] = useModalConvertSize("md");
  const [options, setOptions] = React.useState<Array<any>>([]);
  const [numeroCuotas, setNumeroCuotas] = React.useState(1);
  const [primeraCuotaCOnFechaCmp, setprimeraCuotaCOnFechaCmp] = React.useState(false);
  const [cuotasIngresadas, setCuotas] = React.useState<Array<CuotaCreditoVenta>>([]);
  //const [total, setTotal] = React.useState(0);
  const [descuadre, setDescuandre] = React.useState(0);
  const [factura, setFactura] = React.useState("");
  const [interesAnual, setInteresAnual] = React.useState(0);
  const [entrada, setEntrada] = React.useState(0);
  const [total, setTotal] = React.useState(totalCobrar);
  const [fechaCalculo, setFechaCalculo] = React.useState<string>(fechaFactura);
  const [numeroPeriodosCredito, setNumeroPeriodosCredito] = React.useState(1);
  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const validationGroupRef = React.useRef<any>();

  const validateRules = React.useCallback(async () => {
    let resolved = false;
    let status = false;
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(10);
      }
      resolve(resolved);
    });
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid && validationResult.status !== 'pending') {
      //setTieneErroresValidacion(true);
      return false;
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async resolve => {
        await resolve;
        status = resolve.isValid;
        resolved = true;
      });
      await p;
      return status;
    }
    return true;
  }, [])


  const ordenarCuotas = React.useCallback((cuotas: Array<CuotaCreditoVenta>) => {
    const cuotasOrdenadas = cuotas.sort((a, b) => {
      const fechaA = DateUtils.strDateToDate(a.vencimiento, formatoFechasApi);
      const fechaB = DateUtils.strDateToDate(b.vencimiento, formatoFechasApi);
      return fechaA.getTime() - fechaB.getTime()
    })
    const numeroCuotas = cuotasOrdenadas.length;
    for (let index = 0; index < numeroCuotas; index++) {
      cuotasOrdenadas[index].numero = index + 1;
      cuotasOrdenadas[index].letra = `LETRA ${index + 1}\\${numeroCuotas}`
    }
    return cuotasOrdenadas;
  }, [])

  const validateCuotas = React.useCallback((cuotas: Array<CuotaCreditoVenta>): boolean => {
    const cuotasOrdenadas = ordenarCuotas(cuotas);
    if (cuotasOrdenadas.length === 1) return true;
    let cuotaA = cuotasOrdenadas[0];
    let cuotaB: CuotaCreditoVenta;
    let index = 1;
    while (index < cuotasOrdenadas.length) {
      cuotaB = cuotasOrdenadas[index]
      const fechaA = DateUtils.strDateToDate(cuotaA.vencimiento, formatoFechasApi);
      const fechaB = DateUtils.strDateToDate(cuotaB.vencimiento, formatoFechasApi);
      if (fechaA.getTime() === fechaB.getTime()) {
        dispatch(addToast({
          content: `La cuota ${index} tiene igual fecha de vencimiento que la cuota ${index + 1}`,
          type: ToastTypes.Danger,
          autoHide: 5000
        }))
        return false;
      }
      cuotaA = cuotasOrdenadas[index]
      index++;
    }
    return true;
  }, [ordenarCuotas, dispatch])

  const calcularCuotas = React.useCallback((numeroCuotas: number, fechaCalculo: string, primeraCuotaConFecha: boolean, periodo: string, valorPeriodo: number) => {
    const cuotas: Array<CuotaCreditoVenta> = [];
    const startIndex = 0;
    let cuotaNumero = 1;
    let fechaPrimeraCuota = DateUtils.strDateToDate(primeraCuotaConFecha ? fechaFactura : fechaCalculo, formatoFechasDatePickers);
    if (!primeraCuotaConFecha) {
      if (periodo === ICredito.Dias) {
        fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, { days: valorPeriodo });
      } else {
        fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, { months: valorPeriodo });
      }
      //fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, { months: 1 });
    }

    // if (entrada > 0) {
    //   const cuota: CuotaCreditoVenta = {
    //     cuota: entrada,
    //     estado: 'NO COBRADO',
    //     letra: 'ENTRADA',
    //     numero: cuotaNumero,
    //     saldo: entrada,
    //     vencimiento: DateUtils.format(fechaPrimeraCuota, formatoFechasApi)
    //   }
    //   fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, { months: 1 }); // fechaPrimeraCuota.add(30, 'days');
    //   cuotas.push(cuota);
    //   //cuotaNumero++;
    // }
    const valorCuotas = total;
    const valorCuota = parseFloat((valorCuotas / numeroCuotas).toFixed(2))
    let sumanCuotas = 0;
    for (let index = startIndex; index < numeroCuotas; index++) {
      const cuota: CuotaCreditoVenta = {
        cuota: valorCuota,
        estado: 'NO COBRADO',
        letra: `LETRA ${cuotaNumero}\\${numeroCuotas}`,
        numero: cuotaNumero,
        saldo: valorCuota,
        vencimiento: DateUtils.format(fechaPrimeraCuota, formatoFechasApi)
      }
      if (periodo === ICredito.Dias) {
        fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, { days: valorPeriodo });
      } else {
        fechaPrimeraCuota = DateUtils.add(fechaPrimeraCuota, { months: valorPeriodo });
      }

      cuotas.push(cuota);
      sumanCuotas += cuota.cuota;
      cuotaNumero++;
    }
    setNumeroCuotas(numeroCuotas);
    if (sumanCuotas < valorCuotas) {
      const desfase = valorCuotas - sumanCuotas;
      cuotas[0].cuota = parseFloat((cuotas[cuotas.length - 1].cuota + desfase).toFixed(2));
      cuotas[0].saldo = parseFloat((cuotas[cuotas.length - 1].cuota + desfase).toFixed(2));
    } else if (sumanCuotas > valorCuotas) {
      const desfase = valorCuotas - sumanCuotas;
      cuotas[0].cuota = parseFloat((cuotas[cuotas.length - 1].cuota + desfase).toFixed(2));
      cuotas[0].saldo = parseFloat((cuotas[cuotas.length - 1].cuota + desfase).toFixed(2));
    }

    setCuotas(cuotas);
    return cuotas;
  }, [fechaFactura, total]);



  const handleOnOk = React.useCallback(async () => {
    calcularCuotas(numeroCuotas, fechaCalculo, primeraCuotaCOnFechaCmp, periodo, numeroPeriodosCredito)
    if (await validateRules() && validateCuotas(cuotasIngresadas)) {
      onOk(ordenarCuotas(cuotasIngresadas))
      return;
    }
    dispatch(addToast({
      content: 'Verifique las cuotas!!!',
      type: ToastTypes.Danger
    }))
  }, [cuotasIngresadas, validateRules, validateCuotas, onOk, ordenarCuotas, dispatch, numeroCuotas, fechaCalculo, primeraCuotaCOnFechaCmp, periodo, numeroPeriodosCredito, calcularCuotas]);

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    const newData = applyChanges(cuotasIngresadas, e.changes, { keyExpr: 'numero' });
    setChanges([]);
    setEditRowKey(null);
    setCuotas(newData);
    //e.promise = saveChange(dispatch, e.changes[0]);
  }, [cuotasIngresadas]);

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, []);

  const validateRetencion = React.useCallback((e) => {
    return e.value === 0 ? Promise.resolve() : Promise.reject("La suma de cuotas no es igual al valor de la factura.");
  }, []);


  const convertirFechaVencimiento = React.useCallback((data: CuotaCreditoVenta) => {
    if (data) {
      return DateUtils.strDateToDate(data.vencimiento, formatoFechasApi);
    }
  }, []);

  const setFechaVencimientoValue = React.useCallback((rowData, value) => {
    if (value) {
      rowData.vencimiento = DateUtils.dateToString(value, formatoFechasApi);
    }
  }, []);

  const setCuotaValue = React.useCallback((rowData, value) => {
    if (value) {
      rowData.cuota = value;
      rowData.saldo = value;
    }
  }, []);

  const validateVencimiento = React.useCallback((params) => {
    const { data, } = params;
    const vencimiento = DateUtils.strDateToDate(data.vencimiento, formatoFechasApi)
    const fechaFacturaDate = DateUtils.strDateToDate(fechaFactura, formatoFechasDatePickers)
    if (vencimiento.getTime() < fechaFacturaDate.getTime()) {
      return Promise.reject("La fecha de vencimiento no puede ser anterior a la factura!!!");
    }
    return Promise.resolve();
  }, [fechaFactura]);

  const isDisabledDate = React.useCallback(({ date }) => {
    const fechaFacturaDate = DateUtils.strDateToDate(fechaFactura, formatoFechasDatePickers)
    if (parseInt(EModulosAcatha.Proveedores) === moduloCodigo) {
      return true
    }
    return fechaFacturaDate.getTime() >= date.getTime();
  }, [fechaFactura, moduloCodigo]);

  const regenerate = React.useCallback(() => {
    setFechaCalculo(fechaFactura)
    setEntrada(0)
    setNumeroPeriodosCredito(1)
    setTotal(totalCobrar)
    setNumeroCuotas(1)
    setPeriodo("Meses")
    setprimeraCuotaCOnFechaCmp(false)
    setCuotas(cuotas)
    calcularCuotas(numeroCuotas, fechaCalculo, primeraCuotaCOnFechaCmp, "Meses", 1)
  }, [cuotas, fechaFactura, totalCobrar, numeroCuotas, primeraCuotaCOnFechaCmp, fechaCalculo, calcularCuotas])

  React.useEffect(() => {
    if (empresa && empresa.cuotasVenta > 0 && cuotasIngresadas.length === 0) {
      setCuotas(cuotas)
      if (facturaCompra !== null && facturaCompra !== undefined && facturaCompra !== "") {
        setFactura(facturaCompra)
      }
      setNumeroCuotas(empresa.cuotasVenta);
      calcularCuotas(empresa.cuotasVenta, fechaFactura, false, "Meses", 1)
    }
  }, [empresa, fechaFactura, cuotasIngresadas, calcularCuotas, cuotas, facturaCompra]);

  React.useEffect(() => {
    const sumanCuotas = parseFloat(cuotasIngresadas.reduce((prev, next) => prev + next.cuota, 0).toFixed(2));
    const diferencia = parseFloat((total - sumanCuotas).toFixed(2));
    if (diferencia > 0.01 || diferencia < 0) {
      setDescuandre(diferencia);
    } else {
      setDescuandre(0)
    }
  }, [cuotasIngresadas, total])

  React.useEffect(() => {
    const botones = menuDefectoSearch.map(acc => {
      const boton: BotonMenu = {
        id: acc.text as any,
        icon: menuService.getIcon(acc.icon),
        text: acc.text
      };
      return boton;
    });
    setOptions(botones)
  }, []);

  const handleDelete = React.useCallback(() => {
    let pagos: Array<string> = []
    pagos = cuotas.map(cuota => cuota.codigo)
    console.log(pagos)
  }, [cuotas])

  const onDisebled = React.useCallback(() => {
    if (cuotas.length > 0) {
      cuotas.map(cuota => {
        if (parseInt(cuota.codigo) !== 0) {
          handleDelete()
        }
      })
    } else {
      dispatch(addToast({
        title: 'Cuotas',
        content: 'No hay cuotas que se puedan anular',
        type: ToastTypes.Warning
      }))
    }
  }, [dispatch, cuotas, handleDelete])

  const returnAction = React.useCallback((metodo) => {
    console.log(metodo)
    switch (metodo) {
      case ButtonTypes.save:
        handleOnOk()
        break;
      case ButtonTypes.print:
        // AÃ±adir metodo para imprimir
        break;
      case ButtonTypes.regenerate:
        regenerate()
        break;
      case ButtonTypes.disable:
        onDisebled()
        break;
      case ButtonTypes.undo:
        calcularCuotas(numeroCuotas, fechaCalculo, primeraCuotaCOnFechaCmp, periodo, numeroPeriodosCredito)
        break;

      default:
        console.log(metodo)
        break;
    }
  }, [calcularCuotas, fechaCalculo, handleOnOk, numeroPeriodosCredito, onDisebled, primeraCuotaCOnFechaCmp, regenerate, numeroCuotas, periodo])

  const optionsForm = () => {
    if (options.length > 0) {
      return (
        <>

          <CRow>
            <CCol className="ms-0 d-flex align-items-start justify-content-start" >
              <div key='menuItems' className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginBottom: '5px', paddingTop: '-2rem' }}>
                <div key='menuItemsBotones' className="btn-group-lg btn-group btn-sm me-2" role="group" aria-label="First group" >
                  {options.map(function (x, i) {
                    const prefixButton = "buttonsCuentas_";
                    const nameButon = x.text;
                    // const disabled = getButtonDisabled(x);
                    return (
                      <CTooltip
                        key={"tool-" + prefixButton + nameButon}
                        placement="top"
                        content={nameButon}
                      >
                        <CButton
                          key={prefixButton + nameButon}
                          name={prefixButton + nameButon}
                          title={x.text}
                          id={prefixButton + nameButon}
                          value={x.text}
                          className="btn btn-secondary sm"
                          variant="ghost"
                          onClick={(ev) => returnAction(ev = x.text)}
                          size='sm'
                          disabled={false}
                        >
                          <FontAwesomeIcon size='sm' icon={x.icon ?? faQuestionCircle} />
                        </CButton>
                      </CTooltip>
                    );
                  })}
                </div>
              </div>
            </CCol>
          </CRow>
        </>
      )
    } else {
      return (<> </>)
    }
  }


  return (
    <CustomModalDevx
      size={modalSize}
      id="sectionTutModalCuotas"
      title={'Cuotas de ' + ICredito.Credito}
      onClose={onCancel}
      show
      toolbarItems={[
        {
          toolbar: 'bottom',
          widget: 'dxButton',
          location: 'after',
          options: {
            text: 'Guardar',
            type: 'default',
            onClick: handleOnOk
          }
        },
        {
          toolbar: 'bottom',
          widget: 'dxButton',
          location: 'after',
          options: {
            text: 'Cancelar',
            type: 'danger',
            onClick: onCancel
          }
        }
      ]}
    >
      <ValidationGroup id={`valGroupCuotasCredito`} ref={validationGroupRef}>
        <CCard
          className={`mb-3 border-top-secondary border-top-3`}>
          <CCardHeader>
            <CRow>
              <CCol className="ms-0 d-flex align-items-start justify-content-start" >
                {optionsForm()}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <RowContainer>
              {!primeraCuotaCOnFechaCmp &&
                <CustomCol xs='12' md='6' lg='3' xxl='2' >

                  <Labeled
                    label={parseInt(EModulosAcatha.Proveedores) === moduloCodigo ? 'Fecha Emision' : 'Fecha Calculo'}
                  >
                    <DateBox
                      disabledDates={isDisabledDate}
                      value={fechaCalculo} onValueChanged={({ value }) => {
                        if (typeof value === 'string') {
                          if (!isDisabledDate({ date: DateUtils.strDateToDate(value, formatoFechasDatePickers) })) {
                            setFechaCalculo(value);
                            calcularCuotas(numeroCuotas, value, primeraCuotaCOnFechaCmp, periodo, numeroPeriodosCredito);
                          }
                          /*
                          else {
                            dispatch(addToast({
                              content: 'Fecha no vÃ¡lida',
                              type: ToastTypes.Warning
                            }))
                          }
                          */
                        } else {
                          setFechaCalculo(DateUtils.dateToString(value ?? new Date(), formatoFechasDatePickers))
                        }
                      }
                      } />
                  </Labeled>

                </CustomCol>
              }
              {parseInt(EModulosAcatha.Proveedores) === moduloCodigo && <CustomCol xs='12' md='6' lg='2' xxl='1'>
                <Labeled
                  label='Factura'
                >
                  <TextBox
                    readOnly={true}
                    value={factura}
                  />
                </Labeled>
              </CustomCol>}
              {parseInt(EModulosAcatha.Proveedores) !== moduloCodigo && (
                <>
                  <CustomCol xs='12' md='6' lg='2' xxl='1'>
                    <Labeled
                      label='Periodo'
                    >
                      <Switch width={60} switchedOnText={ICredito.Dias} switchedOffText='Meses' value={periodo === ICredito.Dias} onValueChanged={(e) => {
                        console.log('switch periodos', e)
                        if (e.event !== undefined) {
                          setPeriodo(e.value ? ICredito.Dias : "Meses")
                          setNumeroPeriodosCredito(e.value ? 30 : 1);
                          calcularCuotas(numeroCuotas, fechaCalculo, primeraCuotaCOnFechaCmp, e.value, e.value === ICredito.Dias ? 30 : 1)
                        }
                      }} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs='12' md='6' lg='2' xxl='1'>
                    <Labeled
                      label='Entrada'
                    >
                      <NumberBox
                        readOnly={true}
                        format={{ precision: 2 }}
                        value={entrada}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs='12' md='6' lg='3' xxl='2'>
                    <Labeled
                      label={periodo === ICredito.Dias ? '# ICredito Cuota' : '# Meses cuota'}
                    >
                      <NumberBox
                        value={numeroPeriodosCredito}
                        onValueChanged={({ value }) => {
                          setNumeroPeriodosCredito(value)
                          calcularCuotas(numeroCuotas, fechaCalculo, primeraCuotaCOnFechaCmp, periodo, value)
                        }}
                        min={1}
                        step={periodo === ICredito.Dias ? 15 : 1}
                        showSpinButtons
                        max={periodo === ICredito.Dias ? 1000 : 600}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs='12' lg='6' xxl='3' >
                    <Labeled
                      label='Primera cuota con fecha comprobante'
                    >
                      <Switch value={primeraCuotaCOnFechaCmp} onValueChanged={(e) => {
                        console.log('premera fac', e)
                        if (e.event !== undefined) {
                          setprimeraCuotaCOnFechaCmp(e.value);
                          calcularCuotas(numeroCuotas, fechaCalculo, e.value, periodo, numeroPeriodosCredito)
                        }
                      }} />
                    </Labeled>
                  </CustomCol>
                </>
              )}
            </RowContainer>

            <RowContainer>
              <CustomCol
                xs='6'
                lg='4'
                xxl='2'
              >
                <Labeled
                  label='Total'
                >
                  <NumberBox
                    readOnly={true}
                    format={{ precision: 2 }}
                    value={total}
                  />
                </Labeled>
              </CustomCol>
              <CustomCol
                xs='6'
                xl='2'
              >
                <Labeled
                  label='Descuadre'
                >
                  <NumberBox
                    readOnly={true}
                    format={{ precision: 2 }}
                    value={descuadre}
                  >
                    <Validator>
                      <AsyncRule reevaluate validationCallback={validateRetencion}   > </AsyncRule>
                    </Validator>
                  </NumberBox>
                </Labeled>
              </CustomCol>
              {parseInt(EModulosAcatha.Proveedores) !== moduloCodigo && <CustomCol
                xs='6'
                xl='2'
              >
                <Labeled
                  label='Interes Anual(%)'
                >
                  <NumberBox
                    readOnly={true}
                    format={{ precision: 2 }}
                    value={interesAnual}
                  >
                    <Validator>
                      <AsyncRule reevaluate validationCallback={validateRetencion}   > </AsyncRule>
                    </Validator>
                  </NumberBox>
                </Labeled>
              </CustomCol>}
            </RowContainer>
            <RowContainer>
              <CustomCol xs='3' sm='8' md='6' lg='4' xxl='2'>
                <Labeled
                  label='Numero Cuotas'
                >
                  <NumberBox
                    style={{ with: "100%" }}
                    min={empresa?.cuotasVenta ?? 0}
                    showSpinButtons
                    value={numeroCuotas}
                    onValueChanged={(e) => {
                      setNumeroCuotas(e.value);
                      calcularCuotas(e.value, fechaCalculo, primeraCuotaCOnFechaCmp, periodo, numeroPeriodosCredito);
                    }}
                  >
                    <NumberBoxButton name='spins' />
                    <NumberBoxButton
                      name="currency"
                      location="after"
                      options={{
                        text: 'Calcular',
                        onClick: () => calcularCuotas(numeroCuotas, fechaCalculo, primeraCuotaCOnFechaCmp, periodo, numeroPeriodosCredito),
                        stylingMode: 'contained',
                        type: 'default',
                        width: 80,
                      }}
                    />


                  </NumberBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs='12' className='mt-2'>
                <DataGrid
                  keyExpr='numero'
                  focusedRowEnabled={true}
                  dataSource={cuotasIngresadas}
                  showBorders
                  repaintChangesOnly
                  onSaving={onSaving}
                >
                  <Editing
                    mode="cell"
                    allowUpdating
                    changes={changes}
                    onChangesChange={onChangesChange}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={onEditRowKeyChange}
                  />
                  <Column dataField='numero' width='30px' caption='#' allowEditing={false} allowSearch={false} allowFiltering />
                  <Column dataField='letra' width='*' minWidth='100px' caption='Letra' allowEditing={false} allowSearch={false} allowFiltering />
                  <Column dataField='cuota' width='80px' allowEditing={true} setCellValue={setCuotaValue} />
                  <Column dataField='vencimiento' dataType='date' setCellValue={setFechaVencimientoValue} calculateCellValue={convertirFechaVencimiento} width='100px' caption='Vencimiento' allowEditing={true} allowSearch={false} allowFiltering >
                    <AsyncRule validationCallback={validateVencimiento} />
                  </Column>
                  <Column dataField='saldo' width='80px' allowEditing={false} />
                  <Column dataField='estado' width='100px' allowEditing={false} />
                </DataGrid>
              </CustomCol>
            </RowContainer>


          </CCardBody>
          {descuadre !== 0 &&
            <CCardFooter>
              <RowContainer>
                <CustomCol>
                  <ValidationSummary validationGroup='valGroupCuotasCredito' />
                </CustomCol>
              </RowContainer>
            </CCardFooter>
          }

        </CCard>
      </ValidationGroup>
    </CustomModalDevx >
  );
};