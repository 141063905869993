import { CAlert, CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react-pro'
import {
  Button,
  DataGrid,
  DateBox,
  LoadPanel,
  NumberBox,
  RadioGroup,
  TextArea,
} from 'devextreme-react'
import { Column, Editing, Scrolling, Sorting } from 'devextreme-react/data-grid'
import React from 'react'
import CustomCol from '../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer'
import CabeceraFiltroBusqueda from '../../shared/cabeceraFiltroBusqueda'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'
import {
  fetchAbonarFC,
  fetchAbonarFCEmpresa,
  fetchCuotasVenta,
  saveAbonar,
  generateAcoountEntry,
  setCurrentAction,
  setDatosAbonos,
  setInitData,
  changeLoaderAbonos,
} from '../../store/abonosReducer'
import {
  AbonosIngresos,
  AccountingEntry,
  AsientosIngresos,
  CoutasPagar,
  CoutasVenta,
  FacturasXCobrar,
  FiltrosDocumentosState,
  Ingreso,
  IngresosCajaDto,
  PaySave,
  PaymentSave,
  Pendientes,
  SavePayment,
  Venta,
} from '../../types/types'
import { addToast } from '../../../../../../store/toasterReducer'
import {
  CustomButtons,
  FETCH_STATUS,
  ToastTypes,
} from '../../../../../../store/types'
import ModalDetallesVenta from '../../../../../componentes/modalDetallesVenta'
import FormasPagoLookUp from '../../../../../componentes/formasPagoLookUp'
import {
  ETipoTransaccion,
  TFormaPagoOption,
} from '../../../../../componentes/formasPagoLookUp/store/type'
import { restaFechas } from '../../../../../../helpers/Helper'
import VariasFormasPago from '../../../../../ventas/components/ventas/variasFormasPago/variasFormasPago'
import ModalTarjetas from '../../../../../componentes/modalTarjetas'
import ModalAnticipos from '../../../../../shared/components/modales/modalAnticipos'
import ModalIngresoCheques from '../../../../../componentes/modalIngresoCheques'
import { Vendedor } from '../../../../../shared/components/buscarVendedorLookMemoUp/type/types'
import { DateUtils } from '../../../../../../helpers/dateUtils'
import { ButtonTypes } from '../../../../../../views/componentes/globalMenu/types'
import VisualizaError from '../../../../../ventas/pages/shared/visualizaError/visualizaError'
import { clearButtonClick, setCurrentExecutingAction } from '../../store/tabsReducer'
import Importar from '../impportarExcel/importar'
import ModalAsiento from '../../../../../contabilidad/pages/mayorGeneral/componentes/modalAsiento'
import { DocCuentasCobrar } from '../../services/docCuentasCobrar.service'
import { ReportWithLocalDataModal } from '../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import Modalform from '../../../../../../views/componentes/modalform'
import { TabState } from '../../../../../../store/genericTabTypes'
import BuscarVendedorLookMemoUp from '../../../../../shared/components/buscarVendedorLookMemoUp/buscarVendedorLookMemoUp'
import BlockUi from '../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../views/componentes/loadingindicator/loadingindicator'
import { consoleService } from '../../../../../../services/console.service'
import { setCleanResult } from '../../store/anticiposReducer'
import { EFormasPago } from '../../../../../../store/enum/formasPago'

export const optionsAbono = [
  { value: 0, label: 'Por Factura' },
  { value: 1, label: 'Por Cuotas de Varias Facturas' },
]

interface IAbonosProps {
  tabId: string
  tab: TabState<any>
}

const Abonos: React.FunctionComponent<IAbonosProps> = (props) => {

  const { tabId, tab } = props
  const dispatch = useDispatch()

  const filtroGlobales = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.search.filter)

  const [filtroGlobal, setfiltroGlobal] = React.useState<FiltrosDocumentosState>(filtroGlobales)
  const facturasPendientes = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.abonos.facturasPendientes)
  const modulo = useSelector((state: RootState) => state.clientes.docCuentasXCobrar.configuraciones.modulo)
  const usuarioGlobal = useSelector((state: RootState) => state.global.usuario)

  const moduleCode = useSelector((state: RootState) => {
    return state.clientes.docCuentasXCobrar.configuraciones.modulo
  })
  const abono = useSelector((state: RootState) => {
    return state.clientes.docCuentasXCobrar.abonos
  })
  const estadoBusqueda = useSelector((state: RootState) => {
    return state.clientes.docCuentasXCobrar.abonos.status
  })
  const abonosLoader = useSelector((state: RootState) => {
    return state.clientes.docCuentasXCobrar.abonos.loader
  })
  const peridoActual = useSelector(
    (state: RootState) => state.shared.providers.periodoContable,
  )

  const [fechaCal, setFechaCal] = React.useState(new Date())
  const [fecha, setFecha] = React.useState(DateUtils.dateToString(new Date(), 'dd/MM/yyyy'))
  const [formaPago, setFormaPago] = React.useState<TFormaPagoOption>(null)
  const [radioGroup, setRadioGroup] = React.useState(optionsAbono[1])
  const [concepto, setConcepto] = React.useState<string>(null)
  const [vendedor, setVendedor] = React.useState<Vendedor>(null)
  const [cantidadAbonar, setCantidadAbonar] = React.useState<number>(null)
  const [cantidadPagar, setCantidadPagar] = React.useState<number>(null)
  const [ingCodigo, setIngCodigo] = React.useState<number>(null)
  const [clean, setClean] = React.useState<boolean>(false)

  const [changes, setChanges] = React.useState<any>();
  const [editRowKey, setEditRowKey] = React.useState<any>();

  const [totalPendientes, setTotalPendientes] = React.useState<number>(null)
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false)
  const [arrayErrors, setArrayErrors] = React.useState<Array<string>>([])
  const [showModalsiento, setShowModalsiento] = React.useState<boolean>(false)
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null)
  const [reporteTicketera, setReporteTicketera] = React.useState<null | "Viewer" | "Designer">(null)
  const [resultadoImprimir, setResultadoImprimir] = React.useState<any>({})
  const [resultadoPendientes, setResultadoPendientes] = React.useState<Array<any>>([])
  const [accion, setAccion] = React.useState<string>("Imprimir")
  const [resultadoImprimirTicketera, setResultadoImprimirTicketera] = React.useState<any>({})
  const [showLoader, setShowLoader] = React.useState(false)


  const [showDetallesVenta, setShowDetallesVenta] =
    React.useState<boolean>(false)
  const [buscarCuotas, setBuscarCuotas] = React.useState<boolean>(false)
  const [showImportButton, setShowImportButton] = React.useState<boolean>(false)
  const [paymentsMethods, setPaymentsMethods] = React.useState<number>(-1)
  const [showOthersPayMethod, setShowOthersMethod] =
    React.useState<boolean>(false)
  const [othersPayMethod, setOthersPayMethod] = React.useState<Array<PaySave>>(
    [],
  )
  const [showCreditCard, setShowCreditCard] = React.useState<boolean>(false)
  const [showAdvances, setShowAdvances] = React.useState<boolean>(false)
  const [showBankChecks, setShowBankChecks] = React.useState<boolean>(false)


  const setToast = React.useCallback(
    (message: string, type: ToastTypes) => {
      dispatch(
        addToast({
          content: message,
          type: type,
        }),
      )
    },
    [dispatch],
  )

  const setCurrentExecutionAction = React.useCallback((type: ButtonTypes | undefined) => {
    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: type
    }))
  }, [dispatch, tabId])

  const setLoader = React.useCallback((mostrar: boolean, mensaje: string) => {
    dispatch(changeLoaderAbonos({
      show: mostrar,
      mensaje: mensaje
    }))
  }, [dispatch])

  const onEnterChecksCredtiCardAdvances = React.useCallback(
    (paymentsMethods) => {
      let openModalChekcs = false
      let openModalCreditCards = false
      let openModalAdvances = false
      paymentsMethods.map((payment) => {
        if (
          payment.formaPagoCodigo === 7 ||
          payment.formaPagoCodigo === 111 ||
          payment.formaPagoCodigo === 114 ||
          payment.formaPagoCodigo === 9
        )
          openModalChekcs = true
        else if (payment.formaPagoCodigo === 22) openModalCreditCards = true
        else if (payment.formaPagoCodigo === 30) openModalAdvances = true
      })
      if (openModalChekcs || openModalCreditCards || openModalAdvances)
        if (openModalChekcs) setShowBankChecks(true)
        else if (openModalCreditCards) setShowCreditCard(true)
        else if (openModalAdvances) setShowAdvances(true)
    },
    [],
  )


  const validatePayments = React.useCallback(() => {
    const errors: Array<string> = [
      'Antes de guardar la información revise lo siguiente:',
    ]
    let errorDate: boolean = false
    let errorPayment: boolean = false
    const dataPayments: any =
      abono.facturaCuotaVarias.value === 1
        ? [...abono.coutasPagar]
        : [...abono.cuotasFactura]
    const currentDate = DateUtils.format(fecha, 'yyyy/MM/dd', 'dd/MM/yyyy')

    for (const payment of dataPayments) {
      const dateError = Number(restaFechas(payment.fechaVenta, currentDate))
      if (dateError < 0) errorDate = true
      if (!(payment.abono > 0 && payment.abono <= payment.saldo))
        errorPayment = true
    }

    if (paymentsMethods === -1)
      errors.push('Forma de pago: debe elegir una forma de pago.')
    else if (paymentsMethods === 8)
      errors.push('Forma de pago: no puede realizar el cobro con Crédito')
    else if (paymentsMethods === EFormasPago.otros) {
      let errorsPaymensMethods: boolean = false
      let valuePayment: number = Number(abono.totalCuotas.toFixed(2))
      othersPayMethod.map((payment) => {
        valuePayment = Number((valuePayment - payment.valor).toFixed(2))
        if (payment.formaPagoCodigo === 8) {
          errors.push('Forma de pago: no puede realizar el cobro con Crédito')
          errorsPaymensMethods = true
        }
      })
      if (valuePayment > 0 || othersPayMethod.length <= 1) {
        errors.push(
          'Forma de pago: establezca los valores de las distintas formas de pago',
        )
        errorsPaymensMethods = true
      }
      if (errorsPaymensMethods) setShowOthersMethod(true)
    }
    if (abono.coutasPagar.length === 0 || errorPayment)
      errors.push(
        'Abonos: existen errores en la columna abonos por favor revíselos.',
      )
    if (errorDate)
      errors.push(
        'Comprobantes ventas: existen comprobantes con fechas mayores a la fecha del abono.',
      )
    if (abono.local?.codigo === -1) errors.push('Centro de Costos: elija una opción.')
    return errors
  }, [
    abono.coutasPagar,
    abono.cuotasFactura,
    abono.facturaCuotaVarias,
    abono.local,
    fecha,
    abono.totalCuotas,
    othersPayMethod,
    paymentsMethods,
  ])

  const onCalcularAbonosSelec = React.useCallback(() => {
    if (abono.totalCuotas < abono.valorPagos)
      setToast(
        'La cantidad ingresada supera la cantidad en abonos.',
        ToastTypes.Info,
      )
    else {
      let cantidadAbonar = abono.valorPagos
      const cuotas = JSON.parse(JSON.stringify(abono.coutasPagar))
      for (const cuota of cuotas) {
        let valorAbono = 0
        if (cantidadAbonar > cuota.abono) {
          valorAbono = cuota.abono
          cantidadAbonar -= cuota.abono
        } else if (cantidadAbonar > 0) {
          valorAbono = cantidadAbonar
          cantidadAbonar = 0
        }
        cuota.abono = valorAbono.toFixed(2)
      }
      dispatch(
        setDatosAbonos({
          ...abono,
          coutasPagar: cuotas,
          totalCuotas: abono.valorPagos,
        }),
      )
    }
  }, [abono, dispatch, setToast])

  const onCargarAbonosSelec = React.useCallback(() => {
    if (abono.valorPagos > 0) onCalcularAbonosSelec()
    else
      setToast(
        'Debe ingresar una cantidad antes de Cargar Abonos.',
        ToastTypes.Info,
      )
  }, [abono.valorPagos, onCalcularAbonosSelec, setToast])

  const onSumarCuotas = React.useCallback(
    (cuotasPagar: Array<CoutasPagar>): number => {
      return cuotasPagar.reduce((totalCuotas, cuota) => {
        return Number(totalCuotas) + Number(cuota.abono)
      }, 0)
    },
    [],
  )

  const onRemoveCuota = React.useCallback(() => {
    if (abono.cuotaPagarActual) {
      const cuotasPagar = JSON.parse(JSON.stringify(abono.coutasPagar))
      const deletedCuota = cuotasPagar.filter(
        (cuota) => cuota.codigo !== abono.cuotaPagarActual.codigo,
      )
      dispatch(
        setDatosAbonos({
          ...abono,
          coutasPagar: deletedCuota,
          totalCuotas: onSumarCuotas(deletedCuota),
        }),
      )
    } else setToast('Elija una cuota para eliminarla.', ToastTypes.Warning)
  }, [abono, dispatch, onSumarCuotas, setToast])



  const onSelectedCuotaPagarChanged = React.useCallback(
    ({ currentSelectedRowKeys }) => {
      if (currentSelectedRowKeys) {
        dispatch(
          setDatosAbonos({
            ...abono,
            cuotaPagarActual: currentSelectedRowKeys[0],
          }),
        )
      }
    },
    [abono, dispatch],
  )

  const setCellAbono = React.useCallback((rowData, value, currentRowData) => {
    const newCuotasFactura: Array<CoutasVenta> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      currentRowData.abono = value;
      newCuotasFactura.push(currentRowData)
      dispatch(setDatosAbonos({
        ...abono,
        cuotasFactura: newCuotasFactura
      }))
    }
  }, [abono, dispatch]);

  const setCellAbonoEmp = React.useCallback((rowData, value, currentRowData) => {
    let newCuotasPagar: Array<CoutasPagar> = []
    if (currentRowData !== null && currentRowData !== undefined && value) {
      newCuotasPagar = abono.coutasPagar.map((cuota) => {
        if (currentRowData.codigo === cuota.codigo) {
          currentRowData.abono = value;
          return currentRowData
        }
        return cuota
      })
      dispatch(setDatosAbonos({
        ...abono,
        coutasPagar: newCuotasPagar
      }))
    }
  }, [abono, dispatch]);

  const addCuotaPagar = React.useCallback(() => {
    const selectedIndex = abono.coutasPagar.findIndex((cuota: CoutasPagar) => {
      return cuota.codigo === abono.cuotaActual.codigo
    })
    if (selectedIndex === -1) {
      const abonosPagar = JSON.parse(JSON.stringify(abono.coutasPagar))
      const cuotaPagar: CoutasPagar = {
        codigo: abono.cuotaActual.codigo,
        formaPago: abono.cuotaActual.formaPago,
        cuota: abono.cuotaActual.cuota,
        vence: abono.cuotaActual.vence,
        interes: abono.cuotaActual.interes,
        saldo: abono.cuotaActual.saldo,
        estado: abono.cuotaActual.estado,
        estadoDes: abono.cuotaActual.estadoDes,
        abono: abono.cuotaActual.abono !== 0 && abono.cuotaActual.abono <= abono.cuotaActual.saldo ? abono.cuotaActual.abono : abono.cuotaActual.saldo,
        numeroVenta: abono.facturaActual.numeroVenta,
        fechaVenta: abono.facturaActual.fechaVenta,
      }
      abonosPagar.push(cuotaPagar)
      dispatch(
        setDatosAbonos({
          ...abono,
          coutasPagar: abonosPagar,
          totalCuotas: onSumarCuotas(abonosPagar),
        }),
      )
    } else setToast('La cuota ya fué elejida.', ToastTypes.Info)
  }, [abono, dispatch, onSumarCuotas, setToast])

  const onAddCuota = React.useCallback(() => {

    if (abono.cuotaActual) addCuotaPagar()
    else
      setToast(
        'Elija una cuota para adicionarla en el cuadro derecho.',
        ToastTypes.Warning,
      )
  }, [abono.cuotaActual, addCuotaPagar, setToast])

  const onSelectedCuotaChanged = React.useCallback(
    (data) => {

      const { selectedRowsData } = data
      if (selectedRowsData[0]) {
        setRadioGroup(optionsAbono[1])
        dispatch(
          setDatosAbonos({
            ...abono,
            cuotaActual: selectedRowsData[0],
            facturaCuotaVarias: optionsAbono[1]
          }),
        )
      }
    },
    [abono, dispatch],
  )

  const onCalcularAbonos = React.useCallback(() => {
    if (totalPendientes < abono.valorAbonos)
      setToast(
        'La cantidad ingresada supera la cantidad en abonos.',
        ToastTypes.Info,
      )
    else {
      let cantidadAbonar = abono.valorAbonos
      const cuotas = JSON.parse(JSON.stringify(abono.cuotasFactura))
      for (const cuota of cuotas) {
        let valorAbono = 0
        if (cantidadAbonar > cuota.saldo) {
          valorAbono = cuota.saldo
          cantidadAbonar -= cuota.saldo
        } else if (cantidadAbonar > 0) {
          valorAbono = cantidadAbonar
          cantidadAbonar = 0
        }
        cuota.letra = ''
        cuota.abono = valorAbono.toFixed(2)
      }
      dispatch(
        setDatosAbonos({
          ...abono,
          cuotasFactura: cuotas,
        }),
      )
    }
  }, [abono, dispatch, setToast, totalPendientes])

  const onCargarAbonos = React.useCallback(() => {
    if (abono.valorAbonos > 0) onCalcularAbonos()
    else
      setToast(
        'Debe ingresar una cantidad antes de Cargar Abonos.',
        ToastTypes.Info,
      )
  }, [onCalcularAbonos, setToast, abono.valorAbonos])

  const onVerDetalleVenta = React.useCallback(() => {
    if (abono.facturaActual) setShowDetallesVenta(true)
    else
      setToast('Debe elejir una factura para ver su detalle.', ToastTypes.Info)
  }, [abono.facturaActual, setToast])

  const onCuotasVentaParse = React.useCallback(
    (cuotas: any): Array<CoutasVenta> => {
      const cuotasVenta: Array<CoutasVenta> = []
      if (cuotas.length === 0) return cuotasVenta
      for (const cuota of cuotas) {
        const cuotaNumber = parseInt(cuota.pagosVentasCuota)
        const cuotaV: CoutasVenta = {
          codigo: Number(cuota.pagoVentaCodigo),
          cuota: parseInt(cuotaNumber.toFixed(2)),
          vence: cuota.pagosVentasVencimiento,
          interes: cuota.pagosVentasInteres,
          saldo: cuota.pagosVentasSaldo,
          letra: cuota.pagosVentasLetra,
          estado: cuota.pagosVentasEstado,
          estadoDes: cuota.pagosVentasEstadoDes,
          saldoCap: cuota.pagosVentasSaldoCap,
          pagoCap: cuota.pagosVentasPagoCap,
          cuotasFaltantes: Number(cuota.pagosVentasCuotasFalta),
          abono: 0,
          formaPago: Number(cuota.pagoVentaCodigo),
        }

        cuotasVenta.push(cuotaV)
      }
      return cuotasVenta.filter((cuota) => cuota.saldo > 0)
    },
    [],
  )

  const onBuscarCuotas = React.useCallback(
    async (codigoVenta: number) => {

      try {
        const toAny: any = fetchCuotasVenta(codigoVenta)
        const res = await dispatch(toAny)


        // setShowLoader(false)
        if (res !== null && res.payload['error'] !== true) {
          if (res.payload['auto'].length === 0) {
            setToast('La búsqueda no produjo resultados', ToastTypes.Success)
          } else {
            const cuotas = onCuotasVentaParse(res.payload['auto'])

            dispatch(
              setDatosAbonos({
                ...abono,
                cuotasFactura: cuotas,
              }),
            )
          }
        } else {
          setToast(res.payload.payload['message'], ToastTypes.Warning)
        }
      } catch (error) {
        setToast(error.message, ToastTypes.Danger)
      }
    },
    [dispatch, onCuotasVentaParse, setToast, abono],
  )

  const onSelectedFactChanged = React.useCallback(
    ({ currentSelectedRowKeys }) => {


      if (currentSelectedRowKeys) {
        dispatch(setDatosAbonos({
          ...abono,
          facturaActual: currentSelectedRowKeys[0],
        }))
        setBuscarCuotas(true)
      }
    }, [abono, dispatch])

  const onFactPendientesParse = React.useCallback(
    (facturas: any): Array<FacturasXCobrar> => {
      const factPendientes: Array<FacturasXCobrar> = []
      if (facturas.length === 0) return factPendientes
      for (const factura of facturas) {
        const fact: FacturasXCobrar = {
          codigoFPV: factura.codigoFPV,
          codigoVDR: factura.codigoVDR,
          codigoVenta: factura.codigoVenta,
          fechaVenta: factura.fechaVenta,
          numeroVenta: factura.numeroVenta,
          saldo: factura.saldo,
          totalVenta: factura.totalVenta,
          valorFPV: factura.valorFPV,
          vencimientoPVE: factura.vencimientoPVE,
        }
        factPendientes.push(fact)
      }
      return factPendientes
    },
    [],
  )

  const onSumarPendientes = React.useCallback(
    (facturasPendientes: Array<FacturasXCobrar>): number => {
      return facturasPendientes.reduce((totalPendientes, factura) => {
        return totalPendientes + factura.saldo
      }, 0)
    },
    [],
  )

  const onNewAbonar = React.useCallback(() => {
    setClean(true)
    setFechaCal(new Date())
    setFecha(DateUtils.getCurrentDateAsString())
    setFormaPago(null)
    setRadioGroup(optionsAbono[1])
    setConcepto('')
    setVendedor(null)
    dispatch(setInitData())
  }, [dispatch])

  const onUndo = React.useCallback(() => {
    setClean(true)
    setFechaCal(new Date())
    setFecha(DateUtils.getCurrentDateAsString())
    setFormaPago(null)
    setRadioGroup(optionsAbono[1])
    setConcepto('')
    setVendedor(null)
  }, [])

  const onBroom = React.useCallback(() => {
    dispatch(setInitData())
  }, [dispatch])

  const onFind = React.useCallback(async () => {
    setLoader(true, 'Cargando...')
    try {
      if (abono.cliente !== null && abono.cliente.codigo !== 0) {
        const toAny: any = fetchAbonarFC(abono.cliente.codigo)
        const res = await dispatch(toAny)
        if (res !== null && res.payload['error'] !== true) {
          if (res.payload['auto'].length === 0) {
            setToast(res.payload['message'], ToastTypes.Success)
          } else {
            const pendientes = onFactPendientesParse(res.payload['auto'])
            const sumaTemp = onSumarPendientes(pendientes)
            setTotalPendientes(sumaTemp)
          }
        } else {
          setToast(res.payload['message'], ToastTypes.Warning)
        }
      } else {
        if (abono.empresa !== null) {
          let codigoEmpresa = -1
          if (!isNaN(parseInt(abono.empresa.codigo))) {
            codigoEmpresa = parseInt(abono.empresa.codigo)
          } else {
            codigoEmpresa = 0
          }
          const object: any = {
            empresa: codigoEmpresa,
            fechInicio: fecha,
            fechFin: fecha
          }
          const toAny: any = fetchAbonarFCEmpresa(object)
          const res = await dispatch(toAny)
          if (res !== null && res.payload['error'] !== true) {
            setToast(res.payload['message'], ToastTypes.Success)
          } else {
            const mensaje: string = res.payload['message'] ?? 'Error'
            setToast(mensaje, ToastTypes.Success)
          }
        } else {
          setToast('Seleccione una empresa o un cliente para buscar.', ToastTypes.Warning)
        }
      }
    } catch (error) {
      setToast(error.message, ToastTypes.Danger)
    }
    setLoader(false, '')
  }, [
    abono,
    dispatch,
    onFactPendientesParse,
    onSumarPendientes,
    setToast,
    fecha,
    setLoader
  ])

  const onGenerateAE = React.useCallback(async (incomeCode, payments) => {
    const accountingEntry: AccountingEntry = {
      infoRegistro: {
        movimiento: 'ABONO CLIENTE',
      },
      fecha: fecha,
      descripcion: `Abono registrado por: ${incomeCode}`,
      numeroDocumento: incomeCode,
      diarioNumero: peridoActual.length > 0 ? peridoActual[0].diario : 0, //GET THE NUMBER OF ACCOUNT PERIOD
      localCodigo: abono.local !== null ? abono.local.codigo : -1,
      formaPago: payments,
    }

    try {
      setLoader(true, 'Generando Asiento...')
      const toAny: any = generateAcoountEntry(accountingEntry)
      const res = await dispatch(toAny)
      setLoader(false, '')
      if (res !== null && res.payload['error'] !== true) {
        setIngCodigo(incomeCode)
        setToast(res.payload['message'], ToastTypes.Success)
        setShowConfirm(true)
        dispatch(setCurrentAction('Buscar'))
      } else {
        setToast(res.payload['message'], ToastTypes.Danger)
      }
    } catch (error) {
      setLoader(false, '')
      setToast(error.message, ToastTypes.Danger)
    }
  }, [abono, dispatch, fecha, setToast, peridoActual, setLoader])

  const onGenerateAccountingEntry = React.useCallback(async (paymentsMethods, incomeCode) => {
    let generate = false
    const payments: Array<{ codigo: number; valor: number }> = []
    paymentsMethods.map((payment) => {
      payments.push({
        codigo: payment.formaPagoCodigo,
        valor: payment.valor,
      })
      if (
        payment.formaPagoCodigo === 7 ||
        payment.formaPagoCodigo === 9 ||
        payment.formaPagoCodigo === 22 ||
        payment.formaPagoCodigo === 30 ||
        payment.formaPagoCodigo === 114
      )
        generate = true
    })
    if (!generate) {
      onGenerateAE(incomeCode, payments)
    }
  }, [onGenerateAE])

  const onHandleSave = React.useCallback(async () => {
    setLoader(true, 'Guardando...')
    const errors = validatePayments()
    if (errors.length > 1) {
      setLoader(false, '')
      setArrayErrors(errors)
      setShowErrorPopup(true)
      return false
    } else {
      consoleService.log(ButtonTypes.save)
      setCurrentExecutionAction(ButtonTypes.save)
      const paymentsToSave: Array<PaymentSave> = []
      const dataPayments: any =
        abono.facturaCuotaVarias.value === 1
          ? [...abono.coutasPagar]
          : [...abono.cuotasFactura]
      dataPayments.map((payment) => {
        const pay: PaymentSave = {
          codigo: 0,
          pagoVentaCodigo: payment.codigo,
          valor: payment.abono,
          fecha: fecha,
          clienteCodigo: abono.cliente.codigo,
          tipoPago: paymentsMethods,
          origen: 'INGRESO',
        }
        paymentsToSave.push(pay)
      })

      let paymentsMethodsToSave: Array<PaySave> = []
      if (paymentsMethods !== EFormasPago.otros) {
        paymentsMethodsToSave = [
          {
            formaPagoCodigo: paymentsMethods,
            tipoCodigo: 0, // TIP_CODIGO -> THERE ISN'T THIS FIELD IN API RESPONSE
            valor: abono.totalCuotas,
          },
        ]
      } else if (paymentsMethods === EFormasPago.otros) {
        othersPayMethod.map((payment) => {
          paymentsMethodsToSave.push(payment)
        })
      }
      const paymentData: SavePayment = {
        infoRegistro: {
          codigo: 0,
          concepto: concepto,
          fecha: fecha,
          recibido: abono.cliente !== null ? abono.cliente.nombres : '',
          valor: abono.valorPagos,
          localCodigo: abono.local !== null ? abono.local.codigo : -1,
          puntoVentaCodigo: abono.puntoVenta !== null ? abono.puntoVenta.codigo : -1,
          vendedorCodigo: vendedor !== null ? vendedor?.vendedorCodigo : 0,
          abonos: paymentsToSave,
          pagos: paymentsMethodsToSave,
        },
      }

      try {
        const toAny: any = saveAbonar(paymentData)
        const res = await dispatch(toAny)
        setCurrentExecutionAction(undefined)
        setLoader(false, '')
        if (res !== null && res.payload['error'] !== true) {
          dispatch(
            setDatosAbonos({
              ...abono,
              incomeCode: res.payload['auto'],
            }),
          )
          onEnterChecksCredtiCardAdvances(paymentsMethodsToSave)
          await onGenerateAccountingEntry(paymentsMethodsToSave, res.payload['auto'])
        } else {
          setToast(res.payload['message'], ToastTypes.Warning)
        }
      } catch (error) {
        setLoader(false, '')
        setToast(error.message, ToastTypes.Danger)
      }
    }
  }, [
    abono,
    dispatch,
    vendedor,
    concepto,
    fecha,
    onEnterChecksCredtiCardAdvances,
    onGenerateAccountingEntry,
    othersPayMethod,
    paymentsMethods,
    setToast,
    validatePayments,
    setLoader,
    setCurrentExecutionAction
  ])

  const onChangedFecha = React.useCallback((data) => {
    if (data.value !== null && data.value !== undefined) {
      setFechaCal(data.value)
      const newFecha = DateUtils.dateToString(data.value, 'dd/MM/yyyy')
      setFecha(newFecha)
      dispatch(setDatosAbonos({
        ...abono,
        fecha: newFecha
      }))
    } else {
      setFechaCal(new Date())
      const actualFecha = DateUtils.dateToString(new Date(), 'dd/MM/yyyy')
      setFecha(actualFecha)
      dispatch(setDatosAbonos({
        ...abono,
        fecha: actualFecha
      }))
    }
  }, [dispatch, abono])

  const onChangedFacturasCuotas = React.useCallback((data) => {
    if (data.value !== null && data.value !== undefined) {
      setRadioGroup(data.value)
      dispatch(setDatosAbonos({
        ...abono,
        facturaCuotaVarias: data.value
      }))
    }
  }, [abono, dispatch])

  const onChangedConcepto = React.useCallback((data) => {
    if (data.value !== null && data.value !== undefined) {
      setConcepto(data.value)
      dispatch(setDatosAbonos({
        ...abono,
        concepto: data.value
      }))
    }
  }, [abono, dispatch])

  const onChangedVendedor = React.useCallback((data) => {
    if (data !== null && data !== undefined) {
      setVendedor(data)
      dispatch(setDatosAbonos({
        ...abono,
        vendedor: data
      }))
    }
  }, [abono, dispatch])

  const onChangedValorAbonar = React.useCallback((data) => {
    if (data.value !== null && data.value !== undefined) {
      setCantidadAbonar(data.value)
      dispatch(
        setDatosAbonos({
          ...abono,
          valorAbonos: data.value,
        }),
      )
    }
  }, [abono, dispatch])

  const onChangedValoPagar = React.useCallback((data) => {
    if (data.value !== null && data.value !== undefined) {
      setCantidadPagar(data.value)
      dispatch(
        setDatosAbonos({
          ...abono,
          valorPagos: data.value,
        }),
      )
    }
  }, [abono, dispatch])

  const onChangedFormaPago = React.useCallback((data) => {
    if (data !== null && data !== undefined) {
      setFormaPago(data)
      dispatch(
        setDatosAbonos({
          ...abono,
          formasPago: data,
        }),
      )
    }
  }, [abono, dispatch])

  const getDataToPrint = React.useCallback(async (formato: string) => {
    try {
      let data = null
      setShowLoader(true)
      if (formato === "DETALLE") {
        data = await DocCuentasCobrar.getDataPrint('ABONOS', null, ingCodigo, "", "DETALLE")
      } else if (formato === "TICKET") {
        data = await DocCuentasCobrar.getDataPrint('ABONOS', null, ingCodigo, "Ticketera", "")
      } else {
        data = await DocCuentasCobrar.getDataPrint('ABONOS', null, ingCodigo, "", "")
      }
      setShowLoader(false)
      if (data !== null && data['auto']) {
        if (formato === "TICKET") {
          setResultadoImprimirTicketera(data['auto'])
          if (accion === 'Imprimir') {
            setReporteTicketera('Viewer')
          } else {
            setReporteTicketera('Designer')
          }
        } else {
          setResultadoImprimir(data['auto'])
          if (accion === 'Imprimir') {
            setReporte('Viewer')
          } else {
            setReporte('Designer')
          }
        }
      } else {
        if (data !== null && data !== undefined && data['message']) {
          setToast(data['message'], ToastTypes.Danger)
        } else {
          setToast('Hubo un error', ToastTypes.Danger)
        }
        setResultadoImprimir({})
        setResultadoImprimirTicketera({})
      }
    } catch (error) {
      setShowLoader(false)
      setToast('Error en la peticion', ToastTypes.Danger)
    }
  }, [accion, ingCodigo, setToast])

  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.find:
        onFind()
        break
      case ButtonTypes.save:
        onHandleSave()
        break
      case ButtonTypes.new:
        onNewAbonar()
        break
      case ButtonTypes.broom:
        onBroom()
        break
      case ButtonTypes.undo:
        onUndo()
        break
      case ButtonTypes.import:
        setShowImportButton(true)
        break
      case ButtonTypes.verAsiento:
        if (!isNaN(ingCodigo) && ingCodigo !== 0 && ingCodigo !== null && ingCodigo !== undefined) {
          setShowModalsiento(true)
        } else {
          setToast('Cree primero un registro para poder genrar el asiento.', ToastTypes.Warning)
        }
        break
      case ButtonTypes.print:
        if (!isNaN(ingCodigo) && ingCodigo !== 0 && ingCodigo !== null && ingCodigo !== undefined) {
          // getDataToPrint()
          setAccion('Imprimir')
          setShowConfirm(true)
        } else {
          setToast('Seleccione un abono para poder imprimirlo.', ToastTypes.Warning)
        }
        break
      case ButtonTypes.print_design:
        if (!isNaN(ingCodigo) && ingCodigo !== 0 && ingCodigo !== null && ingCodigo !== undefined) {
          // getDataToPrint()
          setAccion('Diseñar')
          setShowConfirm(true)
        } else {
          setToast('Seleccione un abono para poder diseñarlo.', ToastTypes.Warning)
        }
        break
      default:
        break
    }
    dispatch(clearButtonClick(tabId))
  }, [dispatch, onFind, onHandleSave, tabId, onNewAbonar, ingCodigo, setToast, onBroom, onUndo])


  const onSetValuePaymentsMethods = React.useCallback((paymentMetohds) => {
    const othersPaymentsMethods: Array<PaySave> = []
    for (const payment of paymentMetohds) {
      const pay: PaySave = {
        formaPagoCodigo: payment.codigoFormaPago,
        tipoCodigo: 0,
        valor: payment.valor,
      }
      othersPaymentsMethods.push(pay)
    }
    setOthersPayMethod(othersPaymentsMethods)
    setShowOthersMethod(false)
  }, [])

  const onCloseCreditCardsModal = React.useCallback(() => {
    setShowCreditCard(false)
  }, [])

  const calcularTotalLote = React.useCallback(() => {
  }, [])

  const setLote_lb = React.useCallback((data) => {
  }, [])

  // const resultadoPendientes = facturasPendientes['auto'] ?? []

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    setEditRowKey(editRowKey);
  }, []);

  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return <CAlert color='danger'>
        <p>{item[0]}</p>
        <ul className="errorList">
          {item.map(function (item, id) {
            if (id === 0) return null
            return <li key={id}>{item}</li>
          })}
        </ul></CAlert>
    } else {
      return <></>
    }
  }, []);

  const getParseData = React.useCallback((data: any = {}) => {


    const registros: Array<IngresosCajaDto> = []

    let abonos: Array<AbonosIngresos> = []
    let asientos: Array<AsientosIngresos> = []
    let pendientes: Array<Pendientes> = []
    let ingreso: Ingreso
    let venta: Venta
    if (data?.ingresoO !== null && data?.ingresoO !== undefined) {

      const newFecha = data?.ingresoO.ING_FECHA ?? ""
      const fechaDate = newFecha !== "" ? DateUtils.strDateToDate(newFecha, 'dd/MM/yyyy') : new Date()

      ingreso = {
        Valor: parseInt(data?.ingresoO.ING_VALOR) ?? null,
        Fecha: fechaDate,
        Concepto: data?.ingresoO.ING_CONCEPTO ?? "",
        AsientoNumeroTipo: data?.ingresoO.ASI_NROTIPO ?? "",
        LocalNombre: data?.ingresoO.LOC_NOMBRE ?? "",
      }
    }

    if (data.venta !== null && data.venta !== undefined) {
      venta = {
        Establecimiento: data?.venta.establecimiento ?? "",
        ClienteNombre: data?.venta.CLI_NOMBRE ?? "",
        ClienteIdentificacion: data?.venta.CLI_IDENTIFICACION ?? "",
        Direccion: "",
        Telefono: "",
        Email: "",
        FormaPago: "",
        Pendiente: 0,
      }
    }

    if (data.asientos !== null && data.asientos !== undefined && data.asientos.length > 0) {
      asientos = data.asientos.map((asiento: any) => {
        return {
          PlanCtaNumero: asiento?.PLA_NUMERO ?? "",
          PlanCtaDescripcion: asiento?.PLA_DESCRIPCION ?? "",
          TransaccionReferencia: asiento?.TRA_REFERENCIA ?? "",
          TransaccionDebe: asiento?.TRA_DEBE !== "" ? parseInt(asiento?.TRA_DEBE) : 0,
          TransaccionHaber: asiento?.TRA_HABER !== "" ? parseInt(asiento?.TRA_HABER) : 0,
        }
      })
    }

    if (data.abonos !== null && data.abonos !== undefined && data.abonos.length > 0) {
      abonos = data.abonos.map((abono: any) => {
        return {
          Cuota: abono?.CUOTA !== "" ? parseInt(abono?.CUOTA) : 0,
          Abono: abono?.ABONO ?? "",
          Comprobante: abono?.COMPROBANTE ?? "",
          Numero: abono?.NUMERO ?? "",
        }
      })
    }

    if (data.pendientes !== null && data.pendientes !== undefined && data.pendientes.length > 0) {
      pendientes = data.pendientes.map((pendiente: any) => {
        const newFecha = pendiente?.VEN_FECHA ?? ""
        const fechaDate = newFecha !== "" ? DateUtils.strDateToDate(newFecha, 'dd/MM/yyyy') : new Date()
        return {
          Numero: pendiente?.VEN_NUMERO ?? "",
          Fecha: fechaDate,
          Total: pendiente?.VEN_TOTAL ?? 0,
          Saldo: pendiente?.SALDO ?? 0,
        }
      })
    }

    const ingresoCaja: IngresosCajaDto = {
      Elaborado: usuarioGlobal?.idsesion ?? "",
      IngresoTipo: "ABONO CLIENTE",
      TotalDebe: parseInt(data?.asientoTotalDebe) ?? 0,
      TotalHaber: parseInt(data?.asientoTotalHaber) ?? 0,
      Ingreso: ingreso,
      Venta: venta,
      Abonos: abonos,
      Asientos: asientos,
      Pendientes: pendientes.length > 0 ? pendientes : null,
    }


    registros.push(ingresoCaja)

    return registros
  }, [usuarioGlobal])

  const getParseDataTicket = React.useCallback((dataTicket: any = {}) => {


    const { data } = dataTicket

    const registros: Array<IngresosCajaDto> = []

    let abonos: Array<AbonosIngresos> = []
    let ingreso: Ingreso
    let venta: Venta

    if (data === null || data === undefined) {
      return registros
    }

    if (data.length > 0 && data[0].ingreso !== null && data[0].ingreso !== undefined) {
      const ingresoInfo = data[0].ingreso
      const newFecha = ingresoInfo?.fecha ?? ""
      const fechaDate = newFecha !== "" ? DateUtils.strDateToDate(newFecha, 'dd/MM/yyyy') : new Date()
      ingreso = {
        Valor: null,
        Fecha: fechaDate,
        Concepto: "",
        AsientoNumeroTipo: ingresoInfo?.asientoNumeroTipo ?? "",
        LocalNombre: dataTicket?.parameters.Local ?? "",
      }
    }

    if (data.length > 0 && data[0].venta !== null && data[0].venta !== undefined) {
      const ventaInfo = data[0].venta
      venta = {
        Establecimiento: dataTicket?.parameters.Local ?? "",
        ClienteNombre: ventaInfo?.clienteNombre ?? "",
        ClienteIdentificacion: ventaInfo?.clienteIdentificacion ?? "",
        Direccion: ventaInfo?.direccion ?? "",
        Telefono: ventaInfo?.telefono ?? "",
        Email: ventaInfo?.email ?? "",
        FormaPago: ventaInfo?.formaPago ?? "",
        Pendiente: ventaInfo?.pendiente ?? 0,
      }
    }

    if (data.length > 0 && data[0].abonos !== null && data[0].venta !== undefined && data[0].abonos.length > 0) {
      const abonosArray = data[0].abonos
      abonos = abonosArray.map((abono: any) => {
        return {
          Cuota: abono?.cuota ?? "",
          Abono: abono?.abono ?? "",
          Comprobante: abono?.comprobante ?? "",
          Numero: abono?.numero ?? "",
        }
      })
    }

    const ingresoCaja: IngresosCajaDto = {
      IngresoTipo: "ABONO CLIENTE",
      Elaborado: usuarioGlobal?.idsesion ?? "",
      TotalDebe: 0,
      TotalHaber: 0,
      Ingreso: ingreso,
      Venta: venta,
      Abonos: abonos,
    }


    registros.push(ingresoCaja)

    return registros
  }, [usuarioGlobal])

  const renderFooter = () => {
    return (
      <>
        <CButton
          color="secondary"
          onClick={() => {
            setShowConfirm(false)
            getDataToPrint("")
          }}
        >
          {"Resumen"}
        </CButton>
        <CButton
          color="secondary"
          onClick={() => {
            setShowConfirm(false)
            getDataToPrint("DETALLE")
          }}
        >
          {"Detalle"}
        </CButton>
        <CButton
          color="secondary"
          onClick={() => {
            setShowConfirm(false)
            getDataToPrint("TICKET")
          }}
        >
          {"Ticket"}
        </CButton>
      </>
    );
  };

  const renderBody = () => {
    return <>{'Elija el formato de impresión:'}</>;
  };

  const confirmAction = () => {
    return (
      <Modalform
        name="confirmar"
        headerTitle={"Imprimir - ácatha"}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => setShowConfirm(!showConfirm)}
        centered={true}
        size="sm"
      />
    );
  };

  const getResultados = React.useCallback(() => {
    let newArray = []
    if (facturasPendientes !== null && facturasPendientes !== undefined && facturasPendientes['auto'] && facturasPendientes['auto'].length) {
      newArray = facturasPendientes['auto']
    }
    setResultadoPendientes(newArray)
  }, [facturasPendientes])

  React.useEffect(() => {
    onNewAbonar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    dispatch(
      setDatosAbonos({
        ...abono,
        cliente: filtroGlobal.cliente,
        empresa: filtroGlobal.empresa,
        local: filtroGlobal.lugar
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroGlobal])

  React.useEffect(() => {
    if (abono.formasPago) setPaymentsMethods(abono.formasPago.codigo)
  }, [abono.formasPago])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick])

  React.useEffect(() => {
    if (abono.cliente !== null && abono.cliente !== undefined) {
      onFind()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abono.cliente])

  React.useEffect(() => {
    if (buscarCuotas && abono.facturaActual) {
      onBuscarCuotas(abono.facturaActual.codigoVenta)
      setBuscarCuotas(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abono.facturaActual])

  React.useEffect(() => {
    dispatch(
      setDatosAbonos({
        ...abono,
        totalCuotas: onSumarCuotas(abono.coutasPagar),
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abono.coutasPagar])

  React.useEffect(() => {
    getResultados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facturasPendientes])

  React.useEffect(() => {
    if (estadoBusqueda === FETCH_STATUS.FAILED) {
      dispatch(addToast({
        title: 'Buscar Abonos',
        content: 'Error al realizar la búsqueda',
        type: ToastTypes.Danger
      }))
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
    if (estadoBusqueda === FETCH_STATUS.LOADING) {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
    }
    if (estadoBusqueda === FETCH_STATUS.SUCCESS) {
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
  }, [dispatch, estadoBusqueda, tabId])

  return (
    <>
      {showConfirm && confirmAction()}
      <ReportWithLocalDataModal
        show={reporte !== null}
        onClose={() => setReporte(null)}
        data={getParseData(resultadoImprimir)}
        fileName='StaticIngresosCaja'
        mode={reporte ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': null, Modulo: "Clientes" }}
        template='StaticIngresosCaja'
        key='reportDesigner'
      />
      <ReportWithLocalDataModal
        show={reporteTicketera !== null}
        onClose={() => setReporteTicketera(null)}
        data={getParseDataTicket(resultadoImprimirTicketera)}
        fileName='StaticIngresosCajaTicketera'
        mode={reporteTicketera ?? 'Viewer'}
        parameters={{ 'Reporte_Filtro': null, Modulo: "Clientes" }}
        template='StaticIngresosCajaTicketera'
        key='reportDesignerTicket'
      />
      {showDetallesVenta && (
        <ModalDetallesVenta
          show={showDetallesVenta}
          codigoVenta={abono.facturaActual.codigoVenta}
          onClose={() => setShowDetallesVenta(false)}
        />
      )}
      {showOthersPayMethod && (
        <VariasFormasPago
          numeroVenta={0}
          tiposPagos={[]}
          fechaFactura={abono.fecha}
          totalCobrar={abono.totalCuotas}
          onCancel={() => setShowOthersMethod(false)}
          onOk={onSetValuePaymentsMethods}
        />
      )}
      {showBankChecks && (
        <ModalIngresoCheques
          show={showBankChecks}
          configModal={{
            open: showBankChecks,
            name: 'Ingresar Cheque Abono Venta',
            parametros: {
              incomeCode: abono.incomeCode, // abono.entryCode
              incomeDate: DateUtils.format(abono.fecha, 'yyyy/MM/dd'),
            },
            codModulo: moduleCode,
            tab: 'ABONAR',
            tipoFP: abono.formasPago.codigo,
          }}
          onClose={() => {
            setShowBankChecks(false)
            const payments: Array<{ codigo: number; valor: number }> = []
            payments.push({
              codigo: abono.formasPago.codigo ?? 0,
              valor: abono.totalCuotas ?? 0
            })
            onGenerateAE(abono.incomeCode, payments)
          }}
          parametros={(evt) => consoleService.log(evt)}
          pathVerificador={''}
          ejecutaAccion={() => consoleService.log('ejecutaAccion')}
          onSelect={(evt) => consoleService.log(evt)}
        />
      )}
      {showCreditCard && (
        <ModalTarjetas
          show={showCreditCard}
          onClose={onCloseCreditCardsModal}
          parametros={() => { }}
          pathVerificador={''}
          ejecutaAccion={calcularTotalLote}
          setTotales={(data) => setLote_lb(data)}
          onSelected={(data) => {
            consoleService.log('tarjetas onSelected', data)
          }}
        />
      )}
      {
        showModalsiento && (

          <ModalAsiento
            show={showModalsiento}
            onClose={() => setShowModalsiento(false)}
            infoAsiento={ingCodigo}
            origenModulo={Number(modulo)}
          />

        )
      }
      {showAdvances && (
        <ModalAnticipos
          show={showAdvances}
          onClose={() => setShowAdvances(false)}
          pathVerificador={''}
          modulo={5}
        />
      )}{
        showErrorPopup && (<VisualizaError titulo='Error en Abonar' mensaje={'Error al guardar'} content={generaDetalleErrores(arrayErrors)} onOk={() => {
          setShowErrorPopup(false)
          // dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))
        }} />)
      }
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={abonosLoader.show}
        message={abonosLoader.mensaje}
      >
        <CCard id='abonarContent'>
          <CCardBody>
            <CabeceraFiltroBusqueda
              filtroBusqueda={{
                empresa: null,
                cliente: null,
                puntoVenta: null,
                proyecto: null,
                lugar: null,
                nombre: ''
              }}
              clean={clean}
              setClean={setClean}
              getCabeceraFiltro={(data) => setfiltroGlobal(data)} />
            <RowContainer className='mt-1 mb-1'>
              <CustomCol xs="12" md={showImportButton ? "7" : "12"}>
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Fecha:">
                      <DateBox value={fechaCal} onValueChanged={onChangedFecha} />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Forma de Pago:">
                      <FormasPagoLookUp
                        selected={formaPago}
                        onChanged={onChangedFormaPago}
                        transaccion={ETipoTransaccion.ventas}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4" className="d-flex align-items-center">
                    <Labeled label="">
                      <RadioGroup
                        name="factura-cuotas"
                        layout="horizontal"
                        displayExpr="label"
                        items={optionsAbono}
                        value={radioGroup}
                        onValueChanged={onChangedFacturasCuotas}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol xs="12" md="6">
                    <Labeled label="Concepto:">
                      <TextArea
                        value={concepto}
                        onValueChanged={onChangedConcepto}
                      />
                    </Labeled>
                  </CustomCol>
                  <CustomCol xs="12" md="4">
                    <Labeled label="Vendedor:">
                      <BuscarVendedorLookMemoUp
                        tab={tab}
                        vendedor={vendedor}
                        cambiarVendedorSeleccionado={(data) => onChangedVendedor(data)}
                        allowClear
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
              </CustomCol>
              {showImportButton && (
                <CustomCol xs="12" md="4">
                  <Importar
                    tab={tab}
                    tabId={tabId}
                    openImport={(open) => setShowImportButton(open)}
                  />
                </CustomCol>
              )}
            </RowContainer>
            <RowContainer>
              <CustomCol xs="12" md="5">
                <CRow>
                  <CCol lg="12" className="d-flex justify-content-end">
                    <strong>{resultadoPendientes.length}</strong>
                  </CCol>
                </CRow>
                <DataGrid
                  style={{ marginTop: '5px' }}
                  selection={{ mode: 'single' }}
                  height={240}
                  hoverStateEnabled={true}
                  dataSource={resultadoPendientes}
                  showBorders={true}
                  showRowLines={true}
                  onSelectionChanged={onSelectedFactChanged}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Sorting mode="none" />
                  <Scrolling
                    mode="virtual" />
                  <Column
                    dataField="numeroVenta"
                    caption="Comprobante"
                    width="40%"
                  />
                  <Column dataField="fechaVenta" caption="Fecha" width="10%" />
                  <Column dataField="totalVenta" caption="Total" width="10%" />
                  <Column dataField="valorFPV" caption="Crédito" width="10%" />
                  <Column
                    dataField="saldo"
                    caption="Saldo a la fecha"
                    width="10%"
                  />
                </DataGrid>
                <RowContainer>
                  <CustomCol xs="12" md="4">
                    <CButton
                      id="btnConfirmar"
                      color="secondary"
                      className="m-1"
                      size="sm"
                      onClick={onVerDetalleVenta}
                    >
                      {'Ver Detalle'}
                    </CButton>
                  </CustomCol>
                  <CustomCol
                    xs="12"
                    md="8"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <CRow>
                      <CCol
                        md="6"
                        className="d-flex justify-content-end align-items-center"
                      >
                        <strong> {'Total:'} </strong>
                      </CCol>
                      <CCol md="6">
                        <NumberBox
                          width="100%"
                          value={totalPendientes}
                          placeholder=""
                          format="#,##0.00"
                        />
                      </CCol>
                    </CRow>
                  </CustomCol>
                </RowContainer>
                <RowContainer>
                  <CustomCol
                    xs="12"
                    md="4"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <p> {'Cantidad a Abonar:'} </p>
                  </CustomCol>

                  <CustomCol
                    xs="12"
                    md="3"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <NumberBox
                      width="100%"
                      value={cantidadAbonar}
                      placeholder=""
                      onValueChanged={onChangedValorAbonar}
                    />
                  </CustomCol>
                  <CustomCol
                    xs="12"
                    md="5"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <CButton
                      id="btnConfirmar"
                      color="secondary"
                      className="m-1"
                      size="sm"
                      onClick={onCargarAbonos}
                    >
                      {'Cargar Abonos'}
                    </CButton>
                  </CustomCol>
                </RowContainer>
                <CRow>
                  <CCol lg="12" className="d-flex justify-content-end">
                    <strong>{abono.cuotasFactura.length}</strong>
                  </CCol>
                </CRow>
                <DataGrid
                  style={{ marginTop: '5px' }}
                  keyExpr='codigo'
                  selection={{ mode: 'single' }}
                  hoverStateEnabled={true}
                  dataSource={abono.cuotasFactura}
                  showBorders={true}
                  repaintChangesOnly
                  showRowLines={true}
                  onSelectionChanged={onSelectedCuotaChanged}
                  onRowDblClick={onAddCuota}
                  loadPanel={{
                    enabled: true,
                    height: 90,
                    indicatorSrc: '',
                    shading: false,
                    shadingColor: '',
                    showIndicator: true,
                    showPane: true,
                    text: 'Cargando...',
                    width: 200,
                  }}
                >
                  <Editing
                    mode="cell"
                    allowUpdating
                    changes={changes}
                    onChangesChange={onChangesChange}
                    editRowKey={editRowKey}
                    onEditRowKeyChange={onEditRowKeyChange}
                  />
                  <Column allowEditing={false} dataField="letra" caption="Letra" width="20%" />
                  <Column allowEditing={false} dataField="cuota" caption="Cuota" width="20%" />
                  <Column allowEditing={false} dataField="vence" caption="Vencimiento" width="20%" />
                  <Column allowEditing={false} dataField="saldo" caption="Saldo" width="20%" />
                  <Column
                    dataField="abono"
                    caption="Abono a la fecha"
                    width="20%"
                    allowEditing={true}
                    setCellValue={setCellAbono}
                  />
                </DataGrid>
              </CustomCol>
              <CustomCol xs="12" md="7">
                <RowContainer>
                  <CustomCol xs="12" md="2">
                    <p> {'Buscar:'} </p>
                  </CustomCol>
                  <CustomCol xs="12" md="10">
                    <CRow>
                      <CCol
                        xs="12"
                        md="4"
                        className="d-flex justify-content-end align-items-center"
                      >
                        <p> {'Cantidad a Abonar:'} </p>
                      </CCol>
                      <CCol
                        xs="12"
                        md="3"
                        className="d-flex justify-content-end align-items-center"
                      >
                        <NumberBox
                          width="100%"
                          value={cantidadPagar}
                          placeholder=""
                          onValueChanged={onChangedValoPagar}
                        />
                      </CCol>
                      <CCol
                        xs="12"
                        md="5"
                        className="d-flex justify-content-end align-items-center"
                      >
                        <CButton
                          id="btnConfirmar"
                          color="secondary"
                          className="m-1"
                          size="sm"
                          onClick={onCargarAbonosSelec}
                        >
                          {'Cargar Abonos'}
                        </CButton>
                      </CCol>
                      <CCol
                        xs="12"
                        md="10"
                        className="d-flex justify-content-end align-items-center"
                      >
                      </CCol>
                    </CRow>
                  </CustomCol>
                </RowContainer>
                <CRow>
                  <CCol lg="12" className="d-flex justify-content-end">
                    <strong>{abono.coutasPagar.length}</strong>
                  </CCol>
                </CRow>
                <RowContainer>
                  <DataGrid
                    style={{ marginTop: '5px' }}
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    dataSource={abono.coutasPagar}
                    showBorders={true}
                    showRowLines={true}
                    onSelectionChanged={onSelectedCuotaPagarChanged}
                    loadPanel={{
                      enabled: true,
                      height: 90,
                      indicatorSrc: '',
                      shading: false,
                      shadingColor: '',
                      showIndicator: true,
                      showPane: true,
                      text: 'Cargando...',
                      width: 200,
                    }}
                  >
                    <Editing
                      mode="cell"
                      allowUpdating
                      changes={changes}
                      onChangesChange={onChangesChange}
                      editRowKey={editRowKey}
                      onEditRowKeyChange={onEditRowKeyChange}
                    />
                    <Column
                      dataField="identificacion" caption="Ced/Ruc" width="20%" allowEditing={false}
                    />
                    <Column
                      dataField="nombre" caption="Nombre" width="20%" allowEditing={false}
                    />
                    <Column
                      dataField="numeroVenta" caption="Comprobante" width="20%" allowEditing={false}
                    />
                    <Column allowEditing={false} dataField="cuota" caption="Cuota" width="20%" />
                    <Column allowEditing={false} dataField="vence" caption="Vence" width="20%" />
                    <Column allowEditing={false} dataField="saldo" caption="Saldo" width="20%" />
                    <Column allowEditing={true} setCellValue={setCellAbonoEmp} dataField="abono" caption="Abono" width="20%" />
                  </DataGrid>
                  <CRow>
                    <CCol md="3" xs="6" style={{ marginTop: '4px' }}>
                      <Button
                        id="btnAdd"
                        className="me-1"
                        icon="add"
                        stylingMode="contained"
                        type="default"
                        onClick={onAddCuota}
                      />
                      <Button
                        id="btnRemove"
                        icon="trash"
                        stylingMode="contained"
                        type="danger"
                        onClick={onRemoveCuota}
                      />
                    </CCol>
                    <CCol md="9" xs="6" style={{ marginTop: '4px' }}>
                      <CRow>
                        <CCol
                          md="8"
                          xs="6"
                          className="d-flex justify-content-end align-items-center"
                        >
                          <strong> {'Total:'} </strong>
                        </CCol>
                        <CCol
                          md="4"
                          xs="6"
                          className="d-flex justify-content-end"
                        >
                          <NumberBox
                            width="100%"
                            value={abono.totalCuotas}
                            placeholder=""
                            format="#,##0.00"
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </RowContainer>
              </CustomCol>
            </RowContainer>
          </CCardBody>
        </CCard>
      </BlockUi>
    </>
  )
}

export default React.memo(Abonos)

export const ModuleButtonsAbonar: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Eliminar: false,
  Editar: false,
  Imprimir: true,
  Importar: true,
  Exportar: true,
  Limpiar: true,
  Ver_asiento: true,
}
