import React from 'react';
import { useBarcode } from 'next-barcode';

interface IBarcodeProps extends React.PropsWithChildren {
  text: string
  width?: number
  fontSize?: number
  height?: number
}

const defaultProps = {
  width: 1,
  fontSize: 10,
  height: 30
};

const Barcode: React.FunctionComponent<IBarcodeProps> = (props) => {
  const { fontSize, height, width } = props
  const { inputRef } = useBarcode({
    value: props.text === "" ? "vacio" : props.text,
    options: {
      width: width ?? 1,
      background: "transparent",
      fontSize: fontSize ?? 10,
      height: height ?? 30
    }
  });

  return <img ref={inputRef} alt={props.text} />;
};
Barcode.defaultProps = defaultProps
export default Barcode;
