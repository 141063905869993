import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { formasPagoService } from './service/formasPago.service';
import { ETipoTransaccion, TFormaPagoOption } from './store/type';

interface FormasPagoLookUpProps extends React.PropsWithChildren {
  selected: TFormaPagoOption | null,
  onChanged: (newValue: TFormaPagoOption | null) => void,
  onChangedOptions?: (newValue: Array<TFormaPagoOption> | []) => void,
  disabled?: boolean,
  transaccion: ETipoTransaccion
}

const FormasPagoLookUp: React.FC<FormasPagoLookUpProps> = (props) => {
  const { transaccion, selected, onChanged, disabled, onChangedOptions } = props;
  const selectRef = React.useRef<any>();
  const [options, setOptions] = React.useState<Array<TFormaPagoOption> | []>([]);

  const loadDatos = React.useCallback(async () => {
    const data = await formasPagoService.getFormasPago(transaccion, "Elija una opción");
    console.log("formasPago", data)
    if (data.error === false) {
      let opt = [];
      if (data.auto.length > 0) {
        opt = data.auto.map(x => {
          const item: TFormaPagoOption = {
            codigo: parseInt(x.codigo) ?? 0,
            descripcion: x.descripcion ?? '',
            grupo: null,
            asignadoSri: null,
          };
          return item;
        });
      }
      setOptions(opt)
      if (onChangedOptions !== undefined) {
        onChangedOptions(opt)
      }
      const selectedIndex = opt.findIndex((option) => {
        return option.codigo === selected?.codigo
      })
      onChanged(opt[selectedIndex] ?? opt[0])
    }

  }, [onChanged, onChangedOptions, selected, transaccion]);

  const getItemDisplayExpr = React.useCallback((item: TFormaPagoOption) => {
    return item ? `${item.descripcion}` : '';
  }, []);

  const onLookUpValueChanged = React.useCallback((evt: any) => {
    if (evt.event !== undefined) {
      onChanged(evt.value);
    }
  }, [onChanged]);

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  const refreshSelected = React.useCallback(() => {
    if (options && options.length > 0) {
      const selectedIndex = options.findIndex((option: TFormaPagoOption) => {
        return option.codigo === selected?.codigo
      })
      onChanged(options[selectedIndex] ?? options[0])
    }
  }, [onChanged, options, selected])

  React.useEffect(() => {
    loadDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    refreshSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectBox
      id='TFormaPagoOptionSelect'
      ref={selectRef}
      items={options ?? []}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )

}
export default React.memo(FormasPagoLookUp);
