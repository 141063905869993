/* eslint-disable no-unused-vars */
import { Ciudad } from '../modulos/ventas/store/types'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { ButtonTypes } from '../views/componentes/globalMenu/types'
import { EdicionEmpresa } from '../containers/component/formEmpresa/types/types'
import { EdicionLocalEmpresa } from '../containers/component/formLocal/types/types'
import { PeriodoActivo } from '../modulos/bancos/general/types'

export type UsuarioLocal = {
  local: number
  usuarioTipo: string
  empresaCodigo: number
  usuarioCodigo?: number
}

export type Usuario = {
  codigo: number
  nombres: string
  apellidos: string
  idsesion: string
  email: string
  firma: string
  cedula: string
  telefono: string
  direccion: string
  codciudad: string
  ciudad: string
  codprovincia: string
  provincia: string
  pais: string
  privilegio: string
  rol: string
  asistente: number
}

export type TipoComprobantes = {
  codigo: number | string
  comprobante: string
  archivo: string
  limite: string
  estado: string | number
  descripcionEstado: string
  nombre: string
  ipImpresora: string
  nombreImpresora: string
  puertoImpresora: string
}

export type Impresiones = {
  codigoImpresion: number
  codigoTic: number
  archivoImpresion: string
  limiteImpresion: number
}

export type Puntoventa = {
  codigoPuntoVenta: number
  host: string
  codigoLocal: number
  nombreLocal: string
  especial: string
  mac: string
  uso: string
  restaurante: number
  toOrden: number
  emula: number
  estado: number
  nombreDocumento: string
}

export interface LocalEmpresa {
  codigo: number
  nombre: string
  ciudad?: Ciudad
  ciudadCodigo: number
  ciudadNombre: string
  clase: string
  descuento: number
  direccion: string
  telefono: string
  tipoRol: string
  controlaKardex: boolean
  establecimiento: string
  siglas: string
  uaf: string
  tipo: number
  estado: number
  provinciaCodigo?: number
  provinciaNombre?: string
  paisCodigo?: number
  paisNombre?: string
}

export interface Mensajes {
  mensajes: []
  mostrar: boolean
  modo: string // item,todos
  pos: number
}

export interface ConfigurationEmpresa {
  cantidadComprobantesCaducaContrato: number
  cantidadDiasCaducaContrato: number
  comisionPayphone: number
  urlFiles: string
  cantidadDiasMoraPago: number
}

export interface Empresa {
  codigo: number
  nombre: string
  caduca: string
  certificado: string
  comercial: string
  descuento: number
  direccion: string
  email: string
  iproforma: string
  logo: string
  modulos: string
  precision: number
  ruc: string
  nrc: string
  telefono: string
  token: string
  transparencia: string
  locales: LocalEmpresa[] | null
  uso_electronica: number
  uso_liquidacion: number
  uso_ncredito: number
  creditos: number
  uso_ndedito: number
  uso_guia: number
  uso_retencion: number
  imprimeAutomaticamenteAlfacturar: boolean //EMP_PREGIMP
  pathArchivosPaginaWeb //EMP_PATHWEB
  cuotasVenta: number //EMP_CUOTASV
  cuotasVentaDias: number //EMP_DIASCUOTA
  formaPagoDefectoCompra: number //EMP_FPAGOCOMPRA
  formaPagoDefectoVenta: number //EMP_FPAGOVENTA
  configurado: boolean
  leyendaComprobantesElctronicos: string
  codigoTipoNegocio: number
  editarFechaEmision: number
  contrato: number
  servicioS3: boolean
  uuid: string
  configuracion: ConfigurationEmpresa
  uaf: string
  ipWeb: string
  wooUrl: string
  preciosIva: number
  consumidorFinalValor: number
  contribuyente: string
  logs: number
  codigoTributarioActividad: string
  descripcionActividad: string
  tipoAmbiente: number
  authHacienda: string
  expiraCertificado: string
  passCertificado: string
  plantillaBarras: number
  inventarios: number
  codigoPais: number
  utilizarImpuestos: number
  recintoFiscal: string
  regimen: string
  tipoItemExporta: number
  distinguir: number
  tipoLicencia: number
  numUsuarios: number
  haciendaapikey?: string
  tipoAgente: number
  numeroRoles: number
}

export interface SessionInfo {
  empresa: Empresa
  local: LocalEmpresa
  usuario: Usuario
  ingreso: string
}

export interface Autorizacion {
  DOCUMENTO: string
  AUT_CODIGO: any
  TIC_CODIGO: any
  AUT_AUTORIZACION: any
  AUT_ESTABLECIMIENTO: any
  AUT_PTOEMISION: any
  AUT_DESDE: any
  AUT_HASTA: any
  AUT_CADUCIDAD: any
  AUT_NUM_ACTUAL: any
  AUT_PERTENECE: any
  AUT_ASOCIADO: any
  AUT_IMPRENTA: any
  AUT_COMPARTIDA: any
  EMP_MODFACTURACION: number
  COD_IMPRESION: number | null
  COD_IMPRESORA: number | null
  COD_TIPONEGOCIO: number
  PUNTO_VENTA: string
  PTO_ESPECIAL?: string
  PTO_RESTAURANTE?: number
}

export interface Impresora {
  nombre: string
  codigo: number
}

export type ConfigConsumidorFinal = {
  email: string
  codigo: number
  ruc: string
}

type sidebarState = {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  asideShow: boolean
  canCloseAside: boolean
  darkMode: boolean
  showNotificacion: boolean
  modoImpresion: boolean
  loadTab: string
  formDataEmpresa: any
  formAutorizaciones: Array<Autorizacion>
  formAutorizacion: Autorizacion
  formPuntoventa: Puntoventa
  impresoras: Array<Impresora>
  tiposAgentes: Array<TipoAgente>
  tiposNegocios: Array<TipoNegocio>
  configConsumidorFinal: ConfigConsumidorFinal
  configEmpresa: EdicionEmpresa
  configLocalEmpresa: EdicionLocalEmpresa
  dpClasificacionRimpe: Array<ClasificacionRimpe>
}

export type LoaderInfo = {
  show: boolean
  mensaje: string
}

export type AlertInfo = {
  show: boolean
  alertTitle: string
  mensaje: string
  detalles: string[]
  tipo?: string
}

export type AlertNotification = {
  title: string
  message: string
  type?: string
}

export type SesionUsuario = {
  usuario: Usuario
  empresa: Empresa
  local: LocalEmpresa
  ingreso: string
}

export type ErrorInfo = {
  show: boolean
  titulo?: string
  mensaje?: string
  error?: any
  retryFn?: () => void
}

export type ModalPerfil = {
  show: boolean
  titulo?: string
  mensaje?: string
  idmodal?: string
  error?: any
}

export type Tour = {
  show: boolean
  usoElectronica: boolean
}

export type ScreenLock = {
  show?: boolean
}

export type TVisitor = {
  country?: string
  countryPrefix?: string
  countryCode?: number
}

export type TAutorizaDocumentos = {
  loadAutorizaDocumentos: boolean
  autorizarTodos: boolean
}

export interface GlobalState {
  usuario: Usuario | null
  empresasUsuario: Array<Empresa> | null
  sidebar: sidebarState
  loader: LoaderInfo
  alert: AlertInfo
  session: SesionUsuario | null
  error: ErrorInfo
  puntoVenta: string | null
  peridoActivo: Array<PeriodoActivo>
  mensajes: Mensajes | null
  modal: ModalPerfil
  tour: Tour
  screenLock: ScreenLock | null
  menu: any | null
  puntosVenta: Array<Puntoventa>
  tipoComprobantes: Array<TipoComprobantes>
  impresiones: Array<Impresiones>
  loadUser: boolean
  loadSummaryDocs: boolean
  procesandoPago: boolean
  planes: Array<Plan> | []
  visitor: TVisitor
  autorizarDocumentos: TAutorizaDocumentos
}

export type SesionIniciadaPayload = {
  isOk: boolean
  error?: string
  usuario?: Usuario
  empresas?: Array<Empresa>
  sesion?: SessionInfo
}

export interface InfoPlan {
  cadena: string
  estado: string
  id: number
  plan: number
  precio: number
}

export interface TipoAgente {
  codigo: number
  descripcion: string
  codigoTributario: number | null
}

export interface TipoNegocio {
  codigo: number
  descripcion: string
  codigoTributario: number | null
}

export interface TImpuestos {
  ivaCodigo: number
  ivaTipo: string
  ivaDescripcion: string
  ivaValor: number
  paisCodigo: number
  ivaSri: string
}

export interface ClasificacionRimpe {
  codigo: number
  descripcion: string
}

export type ItemInventarioPlan = {
  itemCodigo: number
  itemCodigoUsuario: string
  itemBarras: string
  itemDescripcion: string
  itemTipo: number
  itemTipoDescripcion: string
  itemDescuentos: string
  itemPVD: number
  itemPVDIVA: number
  itemPVP: number
  itemPVPIVA: number
}

export type PlanCategory = {
  codigo: number
  descripcion: string
  estado: number
}

export interface Plan {
  id: number
  plan: string
  cadena: string
  estado: boolean
  detalle?: any
  precio: number
  tituloDetalle?: string
  valorPlanConIva?: number
  iva?: number
  codPeriodo: number
  maxComprobantes: number
  usuariosBase?: number
  usuariosExtras?: number
  usuariosMax?: number
  codItemComprobantes?: string | null
  detalleItemComprobante?: ItemInventarioPlan | null
  codItemUsuarios?: string | null
  detalleItemUsuario?: ItemInventarioPlan | null
  isHidden?: boolean
  categoria: PlanCategory
  visibleWeb: number
}

export type Seleccionado = {
  index: any
  row: any
  selectedRow: any
}

export type OptionGrupos = {
  value: number
  label: string
}

export const ESTABLECER_EMPRESA_LOCAL = 'ESTABLECER_EMPRESA_LOCAL'
export const ESTABLECER_USUARIO = 'ESTABLECER_USUARIO'
/*
interface EstablecerEmpresaYLocalAction {
  type: typeof ESTABLECER_EMPRESA_LOCAL
  payload: EmpresaYLocal
}
*/
interface EstablecerUsuarioAction {
  type: typeof ESTABLECER_USUARIO
  payload: string
}

export type GlobalActionTypes = EstablecerUsuarioAction

export const estaSeleccionadaEmpresa = (state: GlobalState) => state.session
export const empresa = (state: GlobalState) => state.session?.empresa
export const local = (state: GlobalState) => state.session?.local
export const sidebar = (state: GlobalState) => state.sidebar
export const hayError = (state: GlobalState) => state.error.show
export const errorInfo = (state: GlobalState) => state.error
//export const useTypedSelector: TypedUseSelectorHook<GlobalState> = useSelector
export type Rol = {
  rol: string
}

export type rolPayload = {
  rol: Rol
}

export type PuntoventaPayload = {
  Puntoventa: Puntoventa
}

export type PlanPayload = {
  plan: Plan
}

export type Respuesta = {
  respuesta: any
}

export type RespuestaPayload = {
  respuesta: Respuesta
}

export type AutorizacionPayload = {
  autorizacion: Autorizacion
}

export enum ToastTypes {
  Success,
  Danger,
  Warning,
  Info,
}

export type TToastNotification = {
  id?: string
  autoHide?: boolean | number
  fade?: boolean
  title?: string
  content: any
  type: ToastTypes
}

export type TToaster = {
  notifications: Array<TToastNotification>
}

export type TNotification = {
  id?: string
  title?: string
  content: any
  type: ToastTypes
  autoClose?: boolean
}

export type TAlertNotification = {
  notifications: Array<TNotification>
}

export type MenuShortCut = {
  modificadores: Array<'ctrl' | 'shift'>
  keyCode: number
}

export type AccionMenu = {
  nombre: string
  icon: string
  shortcut: MenuShortCut | null
  main: boolean
  modal: boolean
  actionType: ButtonTypes
}

export type IconInfo = {
  prefix: string
  iconName: string
}

export type OpcionMenu = {
  codigo: number
  nombre: string
  url: string
  icon: string
  acciones?: Array<AccionMenu>
  tieneHijos: boolean
  opciones?: Array<OpcionMenu>
}

export type ModuloMenu = {
  codigo: number
  nombre: string
  url: string | null
  icon: IconDefinition
  opciones: Array<OpcionMenu>
}

export enum FETCH_STATUS {
  IDDLE,
  LOADING,
  SUCCESS,
  FAILED,
}

export enum TiposComprobantesSri {
  Factura = '01',
  LiquidacionCompra = '03',
  NotaCredito = '04',
  NotaDebito = '05',
  GuiaRemision = '06',
  Retencion = '07',
}

export enum TiposComprobantesSLV {
  Factura = '01',
  CreditoFiscal = '03',
  NotaRemision = '04',
  NotaCredito = '05',
  NotaDebito = '06',
  Retencion = '07',
  Liquidacion = '08',
  LiquidacionContable = '09',
  FacturaExportacion = '11',
  FacturaExcluidoSujeto = '14',
  Donacion = '15', // pendiente poner el codigo tributario correcto en lugar del 15
}

export enum EOperationContitionSLV {
  Contado = 1,
  Credito = 2,
  Otro = 3,
}

export enum EModeloFacturacionSLV {
  ModeloFacturacionprevio = 1, // Modelo Facturación previo
  ModeloFacturacionDiferido = 2, // Modelo Facturación diferido
}

export enum ETipoTransmisionSLV {
  TransmisionNormal = 1, // Transmisión normal
  TransmisionPorContingencia = 2, // Transmisión por contingencia
}

export enum FormatosCertificados {
  P12 = '.p12',
  PFX = '.pfx',
  CRT = '.crt',
}

export enum ETiposPagos {
  Suscripcion = 1,
  Renovacion = 2,
  Adicionales = 3,
}

export type TCredentials = {
  storeIdPayPhone: string
  tokenPayPhone: string
  appId: string
}

export type Urls = {
  apiBasePath: string
  documentServerBasePath: string
  origin: string
  pathUri: string
  pathLogos: string
  pathFotos: string
  pathFotosNotificaciones: string
  reportServerBasePath: string
  pathPlantillas: string
  apiDotNetPath: string
  apiDashboardsPath: string
  apiReportsPath: string
  apiDotNetPathMiddlewareSalvador: string
}

export type DateRange = {
  inicio: string
  fin: string
}

export type PagedResult<T> = {
  currentPage: number
  from: number
  to: number
  count: number
  result: Array<T>
}

export type PaginationInfo = {
  current: number
  size: number
}

export type IdentificacionSriValidationResult = {
  isValid: boolean
  motivo?: string
  nombre?: string
  razonComercial?: string
  direccion?: string
  email?: string
  telefono?: string
}

export type DotNetPagedResult<T> = {
  page: number
  pageSize: number
  count: number
  data: Array<T>
}

export type AcathaMessage = {
  codigo: number
  descripcion: string
  fechaInicio: string
  fechaFin: string
  titulo?: string
  imagen?: string
  permanente: number
  popup?: boolean
}

export type TipoComprobante = {
  codigo: string
  comprobante: string
  archivo: string | null
  limite: string | null
  estado: number
  descripcionEstado: string | null
  nombreImpresora: string | null
  ipImpresora: string | null
  puertoImpresora: string | null
}

// type utilizado para abrir cualquier modal y mantener guardado el modulo desde donde se abrio el modal
export type OpenModal = {
  modalName: string
  open: boolean
  codModulo: number | null
  parametros?: any
}

export enum DashboardModos {
  NoHabilitado = 0,
  Visualizacion = 1,
  VisualizacionEdicion = 2,
}

export type Dashboard = {
  id: number
  nombre: string
  nombreInterno: string
  modo: DashboardModos
}

export type RowStyle = {
  FontBool?: boolean
  FontName?: string
  NumberFormat?: string
}

export enum DashboardModes {
  Viewer = 'Viewer',
  Designer = 'Designer',
}

export type DashboardsState = {
  loading: boolean
  mode: DashboardModes
  dashboards: Array<Dashboard>
  currentDashboard?: Dashboard
}

export type Provincia = {
  codigoPais: number
  codigo: number
  nombre: string
  codigoInec: string
}

export type Pais = {
  nombre: string
  codigo: number
  codigoUaf: string
  provincias?: Array<Provincia>
}

export enum TIPOS_DISPOSITIVOS {
  pc = 'pc',
  tablet = 'tablet',
  movil = 'movil',
}

export type RegistrarSesion = {
  tokenSesion: string
  dispositivo: string
  identificadorSesion: string
  navegador: string
  sistemaOperativo: string
  ip: string
  mac: string
  equipo: string
}

export enum CodPlanes {
  Free = 1,
}

export interface TEstado {
  codigo: number
  descripcion: string
}

export type CustomButtons = {
  [k in ButtonTypes]?: boolean
}

export interface CustomDictionary<T> {
  [key: string]: T
}

export type DocumentInfo<T> = {
  codigo: number
  info?: T
}

export enum ETipoAsociado {
  Proveedores = 'Proveedores',
  Clientes = 'Clientes',
}

export enum ETypeItems {
  ItemGeneric = 'ItemGeneric',
  ItemVentas = 'ItemVentas',
  ItemInventario = 'ItemInventario',
  ItemCompras = 'ItemCompras',
  ItemPedidos = 'ItemPedidos',
}

export enum CapitalizeMode {
  upper = 'upper',
  lower = 'lower',
  upperAllInit = 'upperAllInit',
  upperFirst = 'upperFirst',
}

export interface ApiResponseAll<T> {
  error: boolean
  codigo: number
  message: string
  auto: Array<T> | T
}

export type ConfigPopupMsg = {
  show: boolean
  title: string
  message: string
  type: 'confirm' | 'newTab' | 'inputInfo' | 'confirmOptions' | null
  currentAction?: ButtonTypes | null
  typeInput?: 'string' | 'number' | null
  optionsConfirm?: Array<any> | null
  hideOnOutsideClick?: boolean
}
