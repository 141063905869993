import { combineReducers } from '@reduxjs/toolkit'
import { menuReducer } from './menuReducer'
import { searchComprasSVReducer } from './searchReducer'
import { datosEdicionReducer } from './editDataReducer'
import { tabsReducer } from './tabsReducer'
import { configuracionesReducer } from './configuracionesCompraSV'
import { ComprasSVState } from '../types/types'

export const compraSVReducer = combineReducers<ComprasSVState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchComprasSVReducer,
  editData: datosEdicionReducer,
  configuraciones: configuracionesReducer,
})
