import React from 'react';
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput';
import TextBox from 'devextreme-react/text-box';

const FormularioBuscar = (props: IFormularioBuscarProps) => {

  const { onChanged, ejecutarBusqueda, funcion } = props;

  const [datos, setDatos] = React.useState<DatosBuscarItem>(datosBuscarItemDefaultValue);

  React.useEffect(() => {
    onChanged(datos)
  }, [datos]);

  React.useEffect(() => {
    if (funcion === 'Deshacer') {
      setDatos(datosBuscarItemDefaultValue)
    }
  }, [funcion]);

  return (

    <>
      <RowContainer>
        <CustomCol md='6'>
          <Labeled
            label='Código de barras'
          >
            <TextBox
              name='codigoBarras'
              placeholder='Codigo de Barras'
              value={datos?.codigoBarras}
              onValueChanged={({ value }: any) => setDatos({ ...datos, codigoBarras: value })}
              onEnterKey={() => ejecutarBusqueda()}
            >
            </TextBox>
          </Labeled>
        </CustomCol>

      </RowContainer>
      <RowContainer>
        <CustomCol xs='12'>
          <Labeled
            label='Descripción'
          >
            <TextBox
              name='descripcion'
              placeholder='Descripción'
              value={datos?.descripcion}
              onValueChanged={({ value }: any) => setDatos({ ...datos, descripcion: value })}
              onEnterKey={() => ejecutarBusqueda()}
            >
            </TextBox>
          </Labeled>
        </CustomCol>
      </RowContainer>

    </>

  )
};

interface IFormularioBuscarProps extends React.PropsWithChildren {
  onChanged: (data) => void;
  onCancel?: () => void;
  funcion: string,
  resetFunction: () => void,
  ejecutarBusqueda: () => void
}


type DatosBuscarItem = {
  codigoBarras: string,
  descripcion: string,
}

const datosBuscarItemDefaultValue: DatosBuscarItem = {
  codigoBarras: '',
  descripcion: '',
};

export default React.memo(FormularioBuscar);
