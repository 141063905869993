import React, { useEffect } from 'react'
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../../../views/componentes/colContainer'
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput'
import {
  CheckBox,
  DateBox,
  Draggable,
  List,
  RadioGroup,
  SpeedDialAction,
  TextBox,
} from 'devextreme-react'
import { ETypeMovimiento } from '../../../ingresosEgresos/types/types'
import { ButtonTypes } from '../../../../../../../views/componentes/globalMenu/types'
import { clearButtonClick, setButtons, setCurrentExecutingAction } from '../../store/tabsReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import {
  changeLoaderImports,
  changeLoaderImportsResumen,
  setCurrentFunction,
} from '../../store/generalReducer'
import Dialog from '../../../../../../../views/componentes/librerias/bootstrap-dialog'
import { CCard, CCardBody, CCardHeader, CCol, CCollapse, CLink } from '@coreui/react-pro'
import { TabState } from '../../../../../../../store/genericTabTypes'
import { ETipoAsociado, FETCH_STATUS, ToastTypes } from '../../../../../../../store/types'
import { changeFilterResumen, fetchImportsResume, setColapsadoResumen } from '../../store/searchReducer'
import { ItemDragging } from 'devextreme-react/list'
import { DateUtils, formatoFechasApi } from '../../../../../../../helpers/dateUtils'
import { addToast } from '../../../../../../../store/toasterReducer'
import { ProveedorBusqueda } from '../../../../../../proveedores/store/types'
import { BuscarProveedorLookUp } from '../../../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp'
import BuscarClienteLookUp from '../../../../../../ventas/components/busquedaCliente/busquedaCliente'
// import { consoleService } from '../../../../../../../services/console.service'
import { getLocales } from '../../../../../../componentes/localesLookUp/helper/helper'
import BuscarItemPedidoMemoLookUp from '../../../../../../ventas/pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp'
import BlockUi from '../../../../../../../views/componentes/librerias/block-ui'
import LoadingIndicator from '../../../../../../../views/componentes/loadingindicator/loadingindicator'
import { isMobile } from 'react-device-detect'
import TableLoader from '../../../../../../ventas/components/ventas/busquedaVentas/TableLoader'
import ResultadosBusqueda from './ResultadosBusqueda'
import { FiltrosImportacionesState, ImportacionesDatosEdicion } from '../../types/types'
import { consoleService } from '../../../../../../../services/console.service'
import SelectBox from '../../../../../../../views/componentes/selectBox/selectBox'
import { getTypesMovements } from '../../../ingresosEgresos/helper/helper'
import { EModulosAcatha } from '../../../../../../../store/enumsAplication'
import CIcon from '@coreui/icons-react'
import { cilChevronBottom, cilChevronTop } from '@coreui/icons'

interface INewProps extends React.PropsWithChildren {
  tab: TabState<ImportacionesDatosEdicion>
  tabId: string
}

enum VistasBusqueda {
  Filtros,
  ResultadosBusqueda,
  Resumen,
  Loading,
  Error
}

export const optionsPrioridad = [
  { value: false, label: 'Descripcion' },
  { value: true, label: 'Barras' },
]

const draggingGroupName = 'appointmentsGroup';
const ResumenProductos: React.FunctionComponent<INewProps> = (props) => {
  const { tab, tabId } = props
  const dispatch = useDispatch()
  const currentTab = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.tabs.current)
  const resumenLoader = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.general)
  const searchFilter = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.filterResumen)
  const collapsed = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.colapsadoResumen)
  const estadoBusqueda = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.search.statusResume)
  const tabs = useSelector((state: RootState) => state.inventarios.movimientos.importaciones.tabs)
  const [vistaActual, setVistaActual] = React.useState<VistasBusqueda>(VistasBusqueda.Filtros)
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const [prioridadBarras, setPrioridadBarras] = React.useState<{ value: boolean, label: string }>(optionsPrioridad[1])

  const [desde, setDesde] = React.useState(DateUtils.getCurrentDateAsString())
  const [hasta, setHasta] = React.useState(DateUtils.getCurrentDateAsString())
  const playLoader = React.useCallback(
    (message = 'Cargando...') => {
      dispatch(changeLoaderImportsResumen({ show: true, mensaje: message }))
    },
    [dispatch],
  )
  const fechaFormato: string = 'dd/MM/yyyy'
  const fechaFormatoInput: string = 'yyyy-MM-dd'

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoaderImportsResumen({ show: false, mensaje: '' }))
  }, [dispatch])

  const dialogRef = React.useRef<any>(null)
  const setToast = React.useCallback((mensaje: string, tipo: ToastTypes) => {
    dispatch(
      addToast({
        title: 'Inventario-Ingresos/Egresos',
        content: mensaje,
        type: tipo,
      }),
    )
  }, [dispatch])
  const [resultados, setResultados] = React.useState([])
  const [locales, setLocales] = React.useState([])
  const [excluidos, setExcluidos] = React.useState([])
  const [auxLocales, setAuxLocales] = React.useState([])
  const [auxExcluidos, setAuxExcluidos] = React.useState([])
  const [local, setLocal] = React.useState(null)
  const [excluido, setExcluido] = React.useState(null)

  const onFilters = React.useCallback((data, grupo: string) => {
    const value: string = data
    let arrayProvGrupo = []
    let arrayGrupo = []
    if (grupo === 'local') {
      arrayProvGrupo = auxLocales
      setLocal(value)
      arrayGrupo = arrayProvGrupo.filter(item => {
        if (item && item?.text && item.text.toLowerCase().includes(value.toLowerCase())) {
          return item
        }
      })
      setLocales(arrayGrupo)
    } else {
      arrayProvGrupo = auxExcluidos
      setExcluido(value)
      arrayGrupo = arrayProvGrupo.filter(item => {
        if (item && item?.text && item.text.toLowerCase().includes(value.toLowerCase())) {
          return item
        }
      })
      setExcluidos(arrayGrupo)
    }
  }, [auxExcluidos, auxLocales])

  const onGetLocales = React.useCallback(async () => {
    try {
      playLoader()
      const data = await getLocales('Elija una opcion')
      stopLoader()
      if (data !== null && data !== undefined && data.length >= 0) {
        let arrayNew = []
        const arrayTemp = data.filter(item => item.codigo > 0)
        arrayNew = arrayTemp.map(item => {
          return {
            id: item?.codigo ?? 0,
            text: item?.nombre ?? ''
          }
        })
        setLocales(arrayNew)
        setAuxLocales(arrayNew)
      }
    } catch (error) {
      stopLoader()
      return []
    }
  }, [playLoader, stopLoader])

  const onFilterMovimientos = React.useCallback((data = []) => {
    return data.filter(item => item.codigo === -1 || item.codigo === 9)
  }, [])

  const onInitSearch = React.useCallback(async () => {
    dispatch(
      setButtons({
        tabKey: tabId,
        buttons: {
          Nuevo: true,
          Auditoria: false,
          Guardar: false,
          Editar: false,
          Eliminar: false,
          Ver_asiento: false,
          Lotes: false,
          Buscar: true,
          Imprimir: false,
          Importar: false,
          Exportar: false,
          Limpiar: true,
          Series: false,
          Resumen: false,
        },
      }),
    )
    const data = { ...searchFilter }

    if (data.initial) {
      let arrayMovimientos: Array<any> = []
      const resMovimientos = await getTypesMovements()


      await onGetLocales()
      if (resMovimientos.length > 0) {
        arrayMovimientos = onFilterMovimientos(resMovimientos)
      }
      data.movimientos = arrayMovimientos
      data.movimiento = arrayMovimientos.length > 0 ? arrayMovimientos[1] : null
      data.esRapida = false
      data.item = null

      dispatch(changeFilterResumen({
        ...data
      }))
    }
  }, [dispatch, tabId, onGetLocales, onFilterMovimientos, searchFilter])


  const onValueChanged = React.useCallback(async (data, key: string) => {
    let value = null
    if (data?.event !== null && data?.event !== undefined) {
      value = data.value
    } else if (key === "movimientos" || key === "item" || key === "cliente" || key === "proveedor") {
      value = data
    }

    consoleService.log(value, key)
    if (key === "item" || key === "cliente" || key === "proveedor") {
      dispatch(changeFilterResumen({
        ...searchFilter,
        [key]: value
      }))
    } else {
      if (value !== null && value !== undefined) {
        dispatch(changeFilterResumen({
          ...searchFilter,
          [key]: value
        }))
      }
    }
  }, [dispatch, searchFilter])

  const onDragStart = React.useCallback((e) => {
    if (e.fromData === 'locales') {
      e.itemData = locales[e.fromIndex]
    } else if (e.fromData === 'excluidos') {
      e.itemData = locales[e.fromIndex]
    }
  }, [locales])

  const onAdd = React.useCallback((e) => {
    let newArray: Array<any> = []
    if (e.toData === 'locales') {
      newArray = excluidos.slice(0)
      newArray.push(e.itemData)
      setExcluidos(newArray)
      setAuxExcluidos(newArray)
    } else if (e.toData === 'excluidos') {
      newArray = locales.slice(0)
      newArray.push(e.itemData)
      setLocales(newArray)
      setAuxLocales(newArray)
    }

  }, [excluidos, locales])
  const onRemove = React.useCallback((e) => {
    let newArray = []
    if (e.fromData === 'locales') {
      newArray = locales.slice(0)
      newArray.splice(e.toIndex, 1)
      setLocales(newArray)
      setAuxLocales(newArray)
    } else if (e.fromData === 'excluidos') {
      newArray = excluidos.slice(0)
      newArray.splice(e.toIndex, 1)
      setExcluidos(newArray)
      setAuxExcluidos(newArray)
    }
  }, [excluidos, locales])

  const onReorder = React.useCallback((e) => {
    onRemove(e)
    onAdd(e)
  }, [onAdd, onRemove])

  const onSearchReport = React.useCallback(async () => {
    const cod = searchFilter?.codigo ? parseInt(searchFilter?.codigo) : 0
    const fDesde = desde !== null ? DateUtils.format(desde, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString()
    const fhasta = hasta !== null ? DateUtils.format(hasta, fechaFormato, fechaFormatoInput) : DateUtils.getCurrentDateAsString()

    const search_data: any = {
      codigo: cod,
      documento: searchFilter?.documento ?? '',
      prodCliente: searchFilter.proveedor?.codigo ?? 0,
      tipo: 'IMPORTACIONES',
      movimiento: searchFilter.movimiento?.codigo ?? -1,
      observaciones: searchFilter?.observaciones ?? '',
      estado: searchFilter.item?.codigoBarras ?? '',
      fechaDesde: fDesde,
      fechaHasta: fhasta,
    }
    try {
      setResultados([])
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
      playLoader()
      const toAny: any = fetchImportsResume(search_data)
      const data = await dispatch(toAny)
      stopLoader()
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      if (data !== null && data !== undefined && data['payload']['error'] === false) {
        setResultados(data['payload']['auto'])
        if (data['payload']?.auto && data['payload'].auto.length > 0) {
          dispatch(setColapsadoResumen(false))
        }
        setToast(data['payload']['message'], ToastTypes.Success)
        dispatch(
          setButtons({
            tabKey: tabId,
            buttons: {
              Nuevo: true,
              Auditoria: false,
              Guardar: false,
              Editar: false,
              Eliminar: false,
              Ver_asiento: false,
              Lotes: false,
              Buscar: true,
              Imprimir: true,
              Importar: false,
              Exportar: false,
              Limpiar: true,
              Series: false,
              Resumen: false,
            },
          }),
        )
      } else {
        setToast(data['payload']['message'], ToastTypes.Danger)
      }
    } catch (error) {
      stopLoader()
      setToast(error.message, ToastTypes.Danger)
    }
  }, [searchFilter, setToast, playLoader, stopLoader, desde, hasta, dispatch, tabId])

  const onHandleBroom = React.useCallback(() => {
    setResultados([])
    setDesde(DateUtils.getCurrentDateAsString())
    setHasta(DateUtils.getCurrentDateAsString())
  }, [])

  const handleButtonClick = React.useCallback(
    (buttonAction: string) => {
      switch (buttonAction) {
        case ButtonTypes.find:
          if (tabs.current === tabId) onSearchReport()
          break
        case ButtonTypes.broom:
          if (tabs.current === tabId) onHandleBroom()
          break
        case ButtonTypes.print:
          if (tabs.current === tabId) {
            if (resultados.length > 0) {
              setReporte('Viewer')
            } else {
              setToast('No hay registros para generar el reporte, por favor genere uno', ToastTypes.Warning)
            }
          }
          break
        case ButtonTypes.print_design:
          if (tabs.current === tabId) {
            if (resultados.length > 0) {
              setReporte('Designer')
            } else {
              setToast('No hay registros para generar el reporte, por favor genere uno', ToastTypes.Warning)
            }
          }
          break
        default:
          break
      }
      dispatch(setCurrentFunction(''))
      dispatch(clearButtonClick(tabId))
    },
    [dispatch, onSearchReport, onHandleBroom, tabId, tabs, resultados, setToast],
  )


  const onAsociadoProv = React.useCallback((data) => {
    let data_prov: ProveedorBusqueda = null
    if (searchFilter.movimiento?.asociado === ETypeMovimiento.compras) {
      data_prov = data
      onValueChanged(data_prov, 'proveedor')
    } else if (searchFilter.movimiento?.asociado === ETypeMovimiento.ventas) {
      onValueChanged(data, 'cliente')
    }
  }, [onValueChanged, searchFilter.movimiento?.asociado])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.globalButtonClick]);

  const seleccionarProveedor = () => {
    return (
      <Labeled label="Proveedor:">
        <BuscarProveedorLookUp
          selected={searchFilter.proveedor as ProveedorBusqueda}
          allowAdd
          allowClear
          allowEdit
          onChanged={onAsociadoProv}
        />
      </Labeled>
    )
  }

  const seleccionarCliente = () => {
    return (
      <Labeled label="Cliente:">
        <BuscarClienteLookUp
          selected={null}
          onChanged={onAsociadoProv}
          allowAdd
          allowClear
          allowEdit />
      </Labeled>
    )
  }

  const showDinamycLookUp = () => {
    if (searchFilter.movimiento?.asociado === ETypeMovimiento.ventas) {
      return seleccionarCliente()
    } else if (searchFilter.movimiento?.asociado === ETypeMovimiento.compras) {
      return seleccionarProveedor()
    }
  }


  useEffect(() => {
    onInitSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderFilterSearch = () => {
    return (
      <>
        <CCardHeader
          onClick={() => dispatch(setColapsadoResumen(!collapsed))}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <strong> {collapsed ? '' : 'Filtro de Búsqueda'} </strong>
          <CLink className="card-header-action">
            <CIcon icon={collapsed ? cilChevronBottom : cilChevronTop} />
          </CLink>
        </CCardHeader>
        <CCollapse visible={collapsed}>
          <CCardBody>
            <RowContainer>
              <Dialog ref={dialogRef} />
              <RowContainer>
                <CustomCol xs="12" md="2">
                  <Labeled label="Código:">
                    <TextBox
                      value={searchFilter?.codigo}
                      showClearButton={true}
                      onValueChanged={(data) => onValueChanged(data, 'codigo')}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                      onEnterKey={onSearchReport}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="2">
                  <Labeled label="Documento:">
                    <TextBox
                      value={searchFilter?.documento}
                      showClearButton={true}
                      onValueChanged={(data) => onValueChanged(data, 'documento')}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                      onEnterKey={onSearchReport}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Almacén:">
                    <TextBox
                      value={local}
                      placeholder='Buscar...'
                      showClearButton={true}
                      onValueChanged={(data) => {
                        if (data.event !== null && data.event !== undefined) {
                          setLocal(data.value)
                          onFilters(data.value, 'local')
                        }
                      }}
                      onKeyUp={(d: any) => {
                        if (d?.event && d.event.target && d.event.target['value']) {
                          onFilters(d.event.target['value'], 'local')
                        } else {
                          setLocales(auxLocales)
                        }
                      }}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <List
                      dataSource={locales}
                      keyExpr="id"
                      height={100}
                    >
                      <ItemDragging
                        allowReordering={true}
                        group="tasks"
                        data="locales"
                        onDragStart={onDragStart}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onReorder={onReorder}>
                      </ItemDragging>
                    </List>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Excluidos:">
                    <TextBox
                      value={excluido}
                      placeholder='Buscar...'
                      showClearButton={true}
                      onValueChanged={(data) => {
                        if (data.event !== null && data.event !== undefined) {
                          setExcluido(data.value)
                          onFilters(data.value, 'excluidos')
                        }
                      }}
                      onKeyUp={(d: any) => {
                        if (d?.event && d.event.target && d.event.target['value']) {
                          onFilters(d.event.target['value'], 'excluidos')
                        } else {
                          setExcluidos(auxLocales)
                        }
                      }}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                    />
                    <List
                      dataSource={excluidos}
                      keyExpr="id"
                      height={100}
                    >
                      <ItemDragging
                        allowReordering={true}
                        group="tasks"
                        data="excluidos"
                        onDragStart={onDragStart}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onReorder={onReorder}>
                      </ItemDragging>
                    </List>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="3">
                  <Labeled label="Movimientos:">
                    <SelectBox
                      id="movimientos"
                      placeholder=""
                      options={searchFilter?.movimientos ?? []}
                      displayExpr="descripcion"
                      keyExpr="codigo"
                      selected={searchFilter?.movimiento ?? null}
                      onChange={(data) => {
                        dispatch(changeFilterResumen({
                          ...searchFilter,
                          movimiento: data
                        }))
                      }}
                      clearButton={true}
                    ></SelectBox>
                  </Labeled>
                </CustomCol>
                <CustomCol xs="12" md="4">
                  {searchFilter?.movimientos !== null && showDinamycLookUp()}
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md='10'>
                  <Labeled label="Items:">
                    <RowContainer>
                      <CustomCol md={searchFilter.item === null ? "6" : "8"}>
                        <BuscarItemPedidoMemoLookUp
                          selected={searchFilter.item ?? null}
                          onChanged={(data) => onValueChanged(data, 'item')}
                          cliente={null}
                          fecha={DateUtils.getCurrentDateAsString(formatoFechasApi)}
                          prioridadBarras={prioridadBarras?.value ?? true}
                          modulo={EModulosAcatha.Kardex}
                          tipoAsociado={
                            searchFilter?.movimientos !== null &&
                              searchFilter.movimiento?.asociado ===
                              ETypeMovimiento.ventas
                              ? ETipoAsociado.Clientes
                              : null
                          }
                          local={searchFilter?.almacen !== null && searchFilter?.almacen !== undefined ? searchFilter?.almacen : undefined}
                          movimiento={searchFilter?.movimiento}
                          allowMoreOption={true}
                          allowClear={true}
                          allowEdit={true}
                          allowAdd={true}
                          codigoPrvAsociado={-1}
                        />
                      </CustomCol>
                      <CustomCol md="4">
                        <RadioGroup
                          name="iOptionsMov"
                          dataSource={optionsPrioridad}
                          value={prioridadBarras}
                          layout="horizontal"
                          displayExpr="label"
                          onValueChange={(data) => {
                            setPrioridadBarras(data)
                          }}
                        />
                      </CustomCol>
                    </RowContainer>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol xs="12" md="5">
                  <Labeled label="Rango:">

                    {
                      isMobile && (
                        <>
                          {'Desde:'}
                          <DateBox
                            displayFormat="dd/MM/yyyy"
                            value={desde}
                            onValueChanged={(data) => {
                              if (data.value !== null && data.value !== undefined) {
                                setDesde(data.value)
                              }
                            }}
                          />
                          {'Hasta:'}
                          <DateBox
                            displayFormat="dd/MM/yyyy"
                            value={hasta}
                            onValueChanged={(data) => {
                              if (data.value !== null && data.value !== undefined) {
                                setHasta(data.value)
                              }
                            }}
                          />
                        </>
                      )
                    }

                    {
                      !isMobile && (
                        <div
                          style={{
                            display: 'flex',
                            gap: '2px',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          {'Desde:'}
                          <DateBox
                            displayFormat="dd/MM/yyyy"
                            value={desde}
                            onValueChanged={(data) => {
                              if (data.value !== null && data.value !== undefined) {
                                setDesde(data.value)
                              }
                            }}
                          />
                          {'Hasta:'}
                          <DateBox
                            displayFormat="dd/MM/yyyy"
                            value={hasta}
                            onValueChanged={(data) => {
                              if (data.value !== null && data.value !== undefined) {
                                setHasta(data.value)
                              }
                            }}
                          />
                        </div>
                      )
                    }



                  </Labeled>
                </CustomCol>
                <CCol xs="12" md="2" style={{ marginTop: 'auto' }}>
                  <CheckBox
                    text="Mostrar E/S Rápida"
                    value={searchFilter?.esRapida}
                    onValueChanged={(data) => onValueChanged(data, 'esRapida')}
                  />
                </CCol>
                <CustomCol xs="12" md="3">
                  <Labeled label="Observaciones:">
                    <TextBox
                      value={searchFilter?.observaciones}
                      showClearButton={true}
                      onValueChanged={(data) => onValueChanged(data, 'observaciones')}
                      width="100%"
                      inputAttr={{ autocomplete: 'nope' }}
                      onEnterKey={onSearchReport}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </RowContainer>
          </CCardBody>
        </CCollapse>
      </>
    )
  }


  React.useEffect(() => {
    switch (estadoBusqueda) {
      case FETCH_STATUS.IDDLE:
        setVistaActual(VistasBusqueda.Filtros);
        break;
      case FETCH_STATUS.LOADING:
        setVistaActual(VistasBusqueda.Loading);
        break;
      case FETCH_STATUS.SUCCESS:
        setVistaActual(VistasBusqueda.ResultadosBusqueda);
        break;
      case FETCH_STATUS.FAILED:
        setVistaActual(VistasBusqueda.Error);
        break;
      default:
        break;
    }
    // if (estadoBusqueda === FETCH_STATUS.IDDLE || estadoBusqueda === FETCH_STATUS.FAILED) {
    //   setVistaActual(VistasBusqueda.Loading);
    // } else {
    //   setVistaActual(false);
    // }
  }, [estadoBusqueda])

  if (isMobile) {
    return (
      <>
        <CCard>
          {vistaActual === VistasBusqueda.Filtros && renderFilterSearch()}
          {vistaActual === VistasBusqueda.ResultadosBusqueda &&
            (<ResultadosBusqueda tab={tab} tabId={tabId} data={resultados} setReporte={setReporte} reporte={reporte} />)
          }
          {vistaActual === VistasBusqueda.Error &&
            <></>
          }
          {vistaActual === VistasBusqueda.Loading &&
            <TableLoader />
          }
          {
            currentTab === tabId && (
              <Draggable
                id="list"
                data="dropArea"
                group={draggingGroupName}
              >
                <SpeedDialAction
                  icon="filter"
                  label='Filtros'
                  visible={true}
                  index={1}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.Filtros);
                  }}
                />
                <SpeedDialAction
                  icon="search"
                  label='Busqueda'
                  visible={true}
                  index={2}
                  onClick={() => {
                    setVistaActual(VistasBusqueda.ResultadosBusqueda);
                  }}
                />
              </Draggable>
            )
          }
        </CCard>
      </>
    )
  }



  return (
    <>
      <BlockUi
        tag="div"
        loader={LoadingIndicator}
        blocking={resumenLoader.loaderResumen.show}
        message={resumenLoader.loaderResumen.mensaje}
      >
        <CCard>
          {renderFilterSearch()}
          <ResultadosBusqueda tab={tab} tabId={tabId} data={resultados} setReporte={setReporte} reporte={reporte} />
        </CCard>
      </BlockUi>
    </>
  )
}

export default React.memo(ResumenProductos)

export const initSearchRes: FiltrosImportacionesState = {
  codigo: '',
  documento: '',
  almacen: null,
  movimiento: null,
  movimientos: [],
  proveedor: null,
  item: null,
  itemRadioGroup: null,
  observaciones: '',
  fechaDesde: '',
  fechaHasta: '',
  consolidar: false,
  transito: null,
  esRapida: null,
  initial: true
}