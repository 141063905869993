import React from 'react'
import './labeled.scss'

interface ILabeledInputProps extends React.PropsWithChildren {
  label: string
  position?: 'left' | 'top'
  children?: React.ReactNode

}

const Labeled: React.FunctionComponent<ILabeledInputProps> = (props) => {
  const position = props.position ?? 'top'
  if (position === 'top') {
    return (
      <div className="labeledContainer ">
        <label className="labeledLabel form-label-sm mt-2 mb-2">{props.label}</label>
        <div className="labeledElements">{props.children}</div>
      </div>
    )
  }

  return (
    <div className="labeledContainer d-flex justify-content-start align-items-center">
      <label
        className="labeledLabel form-label-sm mt-2 mb-2"
        style={{ marginRight: '0.8rem' }}
      >
        {props.label}
      </label>
      {props.children}
    </div>
  )

  // return (
  //   <>
  //     <div style={{ width: '100%', marginRight: '5px', marginBottom: '5px', display: "inline-flex" }}>
  //       {props.label &&
  //         <div style={{ marginRight: '5px', width: 'auto', fontWeight: 'lighter', whiteSpace: 'nowrap' }}>
  //           <small>{props.label}</small>
  //         </div>
  //       }
  //       <div style={{ width: '100%' }}>
  //         {props.children}
  //       </div>
  //     </div>
  //   </>
  // );
}

export default Labeled
