import React from 'react'
import ReactDom from 'react-dom'
import SelectBox from 'devextreme-react/select-box'
import { CButton } from '@coreui/react-pro'
import RowContainer from '../rowContainer/rowContainer'
import CustomCol from '../colContainer'
import BlockUi from '../librerias/block-ui'
import LoadingIndicator from '../loadingindicator/loadingindicator'
import Labeled from '../labeledInput/labeledInput'
import {
  OptionCiudad,
  OptionPais,
  OptionProvincia,
} from '../../../containers/component/formLocal/types/types'
import { GlobalLabel } from '../globalLabel/globalLabel'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { localidadesService } from '../../../services/localidades.service'
import { Validator, RequiredRule, AsyncRule } from 'devextreme-react/validator'
import ValidationSummary from 'devextreme-react/validation-summary'
import ValidationGroup from 'devextreme-react/validation-group'
import Modalform from '../modalform'

export type TAtributos = {
  valor: string
  atributo: string
  idAtributoValor: number
  nueva: boolean
}

export type TAtributosOptions = {
  id: number
  des: string
}

type dataChange = {
  ciudad: OptionCiudad | null
  ciudades: Array<OptionCiudad> | []
}

interface IPopupLocalidades {
  onChange: (data: dataChange) => void
  show: boolean
  onClose: () => void
  ciudad: OptionCiudad | null
  setToast: (error, type) => void
}

const PopupLocalidades: React.FunctionComponent<IPopupLocalidades> = (
  props,
) => {
  const { setToast, show, onClose, onChange, ciudad } = props
  const localState = useSelector(
    (state: RootState) => state.global.session?.local,
  )
  const validationGroupRef = React.useRef<any>()

  const [loaderLocal, setLoaderLocal] = React.useState<{
    show: boolean
    message: string
  }>({ show: false, message: '' })
  const [dpPais, setDpPais] = React.useState<Array<OptionPais> | []>([])
  const [pais, setPais] = React.useState<OptionPais | null>(null)

  const [dpProvincia, setDpProvincia] = React.useState<
    Array<OptionProvincia> | []
  >([])
  const [provincia, setProvincia] = React.useState<OptionProvincia | null>(null)

  const [dpCiudad, setDpCiudad] = React.useState<Array<OptionCiudad> | []>([])
  const [ciudad_, setCiudad_] = React.useState<OptionCiudad | null>(null)

  const playLoader = React.useCallback(async () => {
    setLoaderLocal({ show: true, message: 'Cargando...' })
  }, [])

  const stopLoader = React.useCallback(async () => {
    setLoaderLocal({ show: false, message: '' })
  }, [])

  const onSubmit = React.useCallback(async () => {
    const result = validationGroupRef.current.instance.validate()
    if (result.isValid === false) {
      setToast('Tiene errores por favor verifíquelos.', 'info')
      return false
    } else {
      playLoader()
      try {
        onChange({ ciudad: ciudad_ ?? null, ciudades: dpCiudad ?? [] })
        stopLoader()
      } catch (error) {
        setToast(JSON.stringify(error), 'danger')
        stopLoader()
      }
    }
  }, [setToast, playLoader, stopLoader, ciudad_, onChange, dpCiudad])

  const llenarCiudades = React.useCallback(async (idProvincia) => {
    try {
      const ciudades = [] as Array<OptionCiudad>
      const data = await localidadesService.getDpCiudades(
        idProvincia,
        'Elija una opción',
      )

      if (data && data.length > 0) {
        for (const item of data) {
          ciudades.push({
            codigoPais: Number(item?.paisCodigo) ?? 0,
            nombrePais: String(item?.paisNombre) ?? '',
            codigoProvincia: Number(item?.provinciaCodigo) ?? 0,
            nombreProvincia: String(item?.provinciaNombre) ?? '',
            codigo: Number(item?.codigo) ?? 0,
            nombre: String(item?.nombre) ?? '',
          })
        }
      }
      return await ciudades
    } catch (error) {
      return await []
    }
  }, [])

  const llenarProvincias = React.useCallback(async (paisSeleccionado) => {
    const provincias = [] as Array<OptionProvincia>
    if (
      paisSeleccionado['provincias'] &&
      paisSeleccionado['provincias'].length > 0
    ) {
      await paisSeleccionado['provincias'].forEach((item: any) => {
        provincias.push({
          codigo: Number(item.codigo) ?? 0,
          codigoPais: Number(item.codigoPais) ?? 0,
          nombre: String(item.nombre) ?? '',
        })
      })
    }
    return provincias
  }, [])

  const cargarProvincias = React.useCallback(
    async (paisData: OptionPais) => {
      let dpProvincias = [] as Array<OptionProvincia>
      let dpCiudades = [] as Array<OptionCiudad>
      let provincia: OptionProvincia | null = null
      let ciudad: OptionCiudad | null = null
      playLoader()
      setDpProvincia([])
      setProvincia(null)

      if (paisData.provincias && paisData.provincias.length > 0) {
        dpProvincias = await llenarProvincias(paisData)
        provincia = dpProvincias[0]
        const ciudades = await llenarCiudades(dpProvincias[0]['codigo'])
        dpCiudades = ciudades ?? []
        ciudad = dpCiudades[0]
      } else {
        dpProvincias = []
        dpCiudades = []
      }

      setDpProvincia(dpProvincias)
      setDpCiudad(dpCiudades)
      stopLoader()
    },
    [
      llenarProvincias,
      llenarCiudades,
      playLoader,
      stopLoader,
      // configLocalEmpresaRedux,
      // dispatch
    ],
  )

  const loadProvincias = React.useCallback(
    async (provinciaData: OptionProvincia) => {
      let dpCiudades = [] as Array<OptionCiudad>
      let ciudad: OptionCiudad | null = null
      playLoader()
      setDpCiudad([])
      setCiudad_(null)
      if (provinciaData.codigo) {
        const ciudades = await llenarCiudades(provinciaData.codigo)
        dpCiudades = ciudades ?? []
        ciudad = dpCiudades[0]
      } else {
        dpCiudades = []
        ciudad = null
      }

      setDpCiudad(dpCiudades)
      setCiudad_(ciudad)
      stopLoader()
    },
    [llenarCiudades, playLoader, stopLoader],
  )

  const onChangeProvincia = React.useCallback(
    (evt) => {
      if (evt.event !== undefined) {
        if (evt.value) {
          setProvincia(evt.value)
          loadProvincias(evt.value)
        } else {
          setProvincia(dpProvincia[0])
          loadProvincias(dpProvincia[0])
        }
      }
    },
    [dpProvincia, loadProvincias],
  )

  const getPaises = React.useCallback(async () => {
    const paises = [] as Array<OptionPais>
    const data = await localidadesService.getDpPaises('Elija una opción')

    if (data && data.length > 0) {
      for (const item of data) {
        const provincias = [] as Array<OptionProvincia>
        if (item['provincias'] && item['provincias']?.length > 0) {
          for (const provItem of item.provincias) {
            provincias.push({
              codigoPais: Number(provItem.codigoPais) ?? 0,
              codigo: Number(provItem.codigo) ?? 0,
              nombre: String(provItem.nombre) ?? '',
            })
          }
        }
        paises.push({
          ...item,
          codigo: Number(item.codigo) ?? 0,
          descripcion: String(item.nombre) ?? '',
          nombre: String(item.nombre) ?? '',
          codigoUaf: String(item.codigoUaf) ?? '',
          provincias: provincias ?? [],
        })
      }
    }
    return paises
  }, [])

  const initDatos = React.useCallback(
    async (reload: boolean = false) => {
      if (localState?.codigo === 0) {
        return false
      }
      setLoaderLocal({ show: true, message: 'Cargando...' })
      try {
        let paisesApi: Array<OptionPais> | [] = []
        paisesApi = await getPaises()
        setLoaderLocal({ show: false, message: '' })
        setDpPais(paisesApi ?? [])
      } catch (error) {
        setLoaderLocal({ show: false, message: JSON.stringify(error) })
      }
    },
    [getPaises, localState],
  )

  const validateAsyncSelect = React.useCallback((datoSeleccion, message) => {
    if (datoSeleccion.value.codigo > -1) {
      return Promise.resolve()
    } else {
      return Promise.reject(message)
    }
  }, [])

  const validateAsyncSeleccionPais = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `- País: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const validateAsyncSeleccionProvincia = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `- Provincia: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const validateAsyncSeleccionCiudad = React.useCallback(
    (datoSeleccion) => {
      return validateAsyncSelect(
        datoSeleccion,
        `- Ciudad: Debe seleccionar una opción.`,
      )
    },
    [validateAsyncSelect],
  )

  const onChangePais = React.useCallback(
    (evt) => {
      if (evt.event !== undefined) {
        if (evt.value) {
          setPais(evt.value)
          cargarProvincias(evt.value)
        } else {
          setPais(dpPais[0])
          cargarProvincias(dpPais[0])
        }
      }
    },
    [dpPais, cargarProvincias],
  )

  const onChangeCiudad = React.useCallback(
    (evt) => {
      if (evt.event !== undefined) {
        if (evt.value) {
          setCiudad_(evt.value)
        } else {
          setCiudad_(dpCiudad[0])
        }
      }
    },
    [dpCiudad],
  )

  React.useEffect(() => {
    initDatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ContentBody = () => {
    return (
      <>
        <div id="configAtributos">
          <BlockUi
            tag="div"
            loader={LoadingIndicator}
            blocking={loaderLocal?.show ?? false}
            message={loaderLocal?.message ?? ''}
          >
            <fieldset disabled={loaderLocal.show}>
              <ValidationGroup ref={validationGroupRef} className="">
                <RowContainer>
                  <CustomCol xs="12">
                    {ciudad && ciudad?.nombre && (
                      <>
                        <GlobalLabel>{ciudad?.nombre}</GlobalLabel>
                      </>
                    )}

                    <RowContainer>
                      <CustomCol xs="12" md="12" lg="12">
                        <Labeled label="País : ">
                          <SelectBox
                            id="selectPaises"
                            width="100%"
                            dataSource={dpPais ?? []}
                            displayExpr="descripcion"
                            searchEnabled={true}
                            searchMode={'contains'}
                            searchExpr={'descripcion'}
                            searchTimeout={200}
                            minSearchLength={3}
                            placeholder="Seleccionar"
                            value={pais ?? null}
                            onValueChanged={(e) => {
                              if (e.event !== null && e.event !== undefined) {
                                onChangePais(e)
                              }
                            }}
                          >
                            <Validator>
                              <RequiredRule
                                message={'- País: Este campo es requerido'}
                              />
                              <AsyncRule
                                reevaluate
                                message={'- País: Debe seleccionar una opción'}
                                validationCallback={validateAsyncSeleccionPais}
                              />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol xs="12" md="12" lg="12">
                        <Labeled label="Provincia : ">
                          <SelectBox
                            id="selectProvincias"
                            width="100%"
                            dataSource={dpProvincia ?? []}
                            displayExpr="nombre"
                            searchEnabled={true}
                            searchMode={'contains'}
                            searchExpr={'nombre'}
                            searchTimeout={200}
                            minSearchLength={3}
                            showDataBeforeSearch={true}
                            placeholder="Seleccionar"
                            value={provincia ?? null}
                            onValueChanged={(e) => {
                              if (e.event !== null && e.event !== undefined) {
                                onChangeProvincia(e)
                              }
                            }}
                          >
                            <Validator>
                              <RequiredRule
                                message={'- Provincia: Este campo es requerido'}
                              />
                              <AsyncRule
                                reevaluate
                                message={
                                  '- Provincia: Debe seleccionar una opción'
                                }
                                validationCallback={
                                  validateAsyncSeleccionProvincia
                                }
                              />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol xs="12" md="12" lg="12">
                        <Labeled label="Ciudad : ">
                          <SelectBox
                            id="selectCiudades"
                            width="100%"
                            dataSource={dpCiudad ?? []}
                            displayExpr="nombre"
                            searchEnabled={true}
                            searchMode={'contains'}
                            searchExpr={'nombre'}
                            searchTimeout={200}
                            minSearchLength={3}
                            showDataBeforeSearch={true}
                            placeholder="Seleccionar"
                            value={ciudad_ ?? null}
                            onValueChanged={(e) => {
                              if (e.event !== null && e.event !== undefined) {
                                onChangeCiudad(e)
                              }
                            }}
                          >
                            <Validator>
                              <RequiredRule
                                message={'- Ciudad: Este campo es requerido'}
                              />
                              <AsyncRule
                                reevaluate
                                message={
                                  '- Ciudad: Debe seleccionar una opción'
                                }
                                validationCallback={
                                  validateAsyncSeleccionCiudad
                                }
                              />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol lg="12">
                        <ValidationSummary id="summary"></ValidationSummary>
                      </CustomCol>
                    </RowContainer>
                  </CustomCol>
                </RowContainer>
              </ValidationGroup>
            </fieldset>
          </BlockUi>
        </div>
      </>
    )
  }

  const ContentFooter = () => {
    return (
      <>
        <RowContainer>
          <CustomCol xs="12" md="12" lg="12">
            <CButton
              variant="outline"
              size="sm"
              color="primary"
              onClick={() => {
                onSubmit()
              }}
            >
              {'GUARDAR'}
            </CButton>
          </CustomCol>
        </RowContainer>
      </>
    )
  }

  return (
    <>
      {ReactDom.createPortal(
        <Modalform
          name={'popupLocalidades'}
          headerTitle={'Localidades'}
          childrenBody={ContentBody()}
          childrenFooter={ContentFooter()}
          closeOnBackdrop={false}
          show={show}
          onClose={() => onClose()}
          centered={true}
          size="sm"
          backdrop={true}
        />,
        document.body,
      )}
    </>
  )
}
export default PopupLocalidades
