/* eslint-disable prefer-const */
import React, { memo } from 'react';
import ReactDom from 'react-dom'
import { Portal } from 'react-portal';
import {
  CTooltip, CLink, CCard, CCardHeader, CCardBody,
  CButton, CRow, CCol, CModal, CModalBody,
  CModalHeader, CCollapse
} from '@coreui/react-pro';
import CIcon from '@coreui/icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import "./modalstyle.scss"
import config from '../../../../../config/config';
import { AccionMenu, ETypeItems } from '../../../../../store/types';
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types';
import { VentasService } from '../../../services/ventas.service';
import { changeLoader } from '../../../../../store/reducers';
import { addToast } from '../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../store/types'
import { SmallLoadingIndicator } from '../../../../../views/componentes/loadingindicator/loadingindicator';
import Modalform from '../../../../../views/componentes/modalform';
import TablaItems from './Componentes/TablaItems';
import { menuService } from '../../../../../services/menu.service';
import { InventarioService } from '../../../../inventario/services/inventario.service';
import { DateUtils } from '../../../../../helpers/dateUtils';
import FormularioItem from "./Componentes/formularioItem"
import FormularioBuscar from "./Componentes/formularioBuscar"
import GlobalLoader from '../../../../../views/componentes/globalLoader/globalLoader';
import LoadPanel from 'devextreme-react/load-panel';
import { RootState } from '../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { AutorizacionDocumentoVenta, ItemVenta } from '../../../types/types';
import { cilChevronBottom, cilChevronTop } from '@coreui/icons';
import { consoleService } from '../../../../../services/console.service';
import { TOptionGenerico } from '../../../../clientes/pages/clientes/types/types';
import { ConfigFiltros } from '../../../pages/ordenes/pages/ordenPedido/components/busquedaPedidos/BusquedaItemsSelectBox/BuscarItemPedidoMemoLookUp';
import { ItemsService } from '../../../../../services/items.services';
import { FiltroItemGeneric } from '../../../../../services/types/types';
import { Movimiento } from '../../../../inventario/pages/movimientos/conceptosMovimientos/types/types'
import { LocalOption } from '../../../../componentes/localesLookUp/store/type';
import { EAplicationsAcatha, EModulosAcatha } from '../../../../../store/enumsAplication';

interface MainModalItemsProps extends React.PropsWithChildren {
  show: boolean
  cliente?: any
  proveedor?: any
  onCancel: () => void
  returnItem: (item: ItemVenta) => void
  transaccion: string
  modulo?: EModulosAcatha | EAplicationsAcatha
  tipoItem?: TOptionGenerico
  action?: 'update' | 'create' | 'read' | 'none'
  itemEditando?: any
  configFiltros?: ConfigFiltros
  requestType?: ETypeItems
  movimiento?: Movimiento
  codigoPrvAsociado?: number
  local?: LocalOption
  dataToSearch?: any
}

type infoPaginationType = {
  desde: number
  hasta: number
  paginaActual: number
  totalPaginas: number
  totalRegistros: number
}

const infoPaginationInit = {
  desde: 1,
  hasta: 10,
  paginaActual: 1,
  totalPaginas: 1,
  totalRegistros: 0,
}

const MainModalItems = (props: MainModalItemsProps) => {
  const {
    requestType,
    configFiltros,
    itemEditando,
    action,
    transaccion,
    proveedor,
    cliente,
    modulo,
    tipoItem,
    codigoPrvAsociado,
    movimiento,
    local,
    dataToSearch
  } = props

  const dispatch = useDispatch()
  const mainRef = React.useRef(null)

  const loader = useSelector((state: RootState) => state.global.loader)
  const botones = config['menuDefecto'].map((acc) => {
    const boton: AccionMenu = {
      nombre: acc.id,
      icon: acc.icon, //as any,
      actionType: acc.type,
      main: false,
      modal: false,
      shortcut: null,
    }
    return boton
  })

  const [loading, setLoading] = React.useState<boolean>(false)
  const [collapsed, setCollapsed] = React.useState(true)
  const [currentTab, setCurrentTab] = React.useState<any>('Buscar')
  const [CODIGO, setCODIGO] = React.useState<any>(0)
  const [BARRA, setBARRA] = React.useState<any>('')
  const [DESCRIPCION, setDESCRIPCION] = React.useState<any>('')
  const [UNIDAD, setUNIDAD] = React.useState<any>('')
  const [GRUPO, setGRUPO] = React.useState<any>('')
  const [UNIDADAUX, setUNIDADAUX] = React.useState<any>('')
  const [GRUPOAUX, setGRUPOAUX] = React.useState<any>('')
  const [PVP, setPVP] = React.useState<any>('')
  const [PVD, setPVD] = React.useState<any>('')
  const [IMPUESTO, setIMPUESTO] = React.useState<any>(true)
  const [SERVICIO, setSERVICIO] = React.useState<any>(false)
  const [seleccion, setSeleccion] = React.useState<any>({
    index: null,
    row: null,
    selectedRow: null,
  })
  const [unidades, setUnidades] = React.useState<any>([])
  const [grupos, setGrupos] = React.useState<any>([])
  const [items, setItems] = React.useState<any>([])
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const [confirmUpdate, setConfirmUpdate] = React.useState(false)
  const [messageConfirm, setMessageConfirm] = React.useState(
    'Está seguro que desea realizar estos cambios?',
  )
  const [searchingObj, setSearchingObj] = React.useState<any>(null)
  const [itemObj, setItemObj] = React.useState<any>(null)
  const [acciones] = React.useState<Array<AccionMenu>>(botones)
  const [funcion, setFuncion] = React.useState<string>('')
  const [dataSearch, setDataSearch] = React.useState<any>({})
  const [infoPagination, setInfoPagination] =
    React.useState<infoPaginationType>(infoPaginationInit)

  const playLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }, [dispatch])

  const stopLoader = React.useCallback(() => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }, [dispatch])

  const setToast = React.useCallback(
    (texto, type) => {
      let tipo: ToastTypes = ToastTypes.Info
      if (type === 'warning') {
        tipo = ToastTypes.Warning
      } else if (type === 'success') {
        tipo = ToastTypes.Success
      }
      if (type === 'info') {
        tipo = ToastTypes.Info
      }
      if (type === 'danger') {
        tipo = ToastTypes.Danger
      }
      dispatch(
        addToast({
          id: '',
          autoHide: 3500,
          title: 'Acatha',
          content: texto,
          fade: true,
          type: tipo,
        }),
      )
    },
    [dispatch],
  )

  const getItemInfo = React.useCallback(async (itemCode: FiltroItemGeneric) => {
    const item: any = await ItemsService.getItemBaseInfo(itemCode)
    return item
  }, [])

  const onEditionItem = React.useCallback(async () => {
    consoleService.log('onEditionItem')
    consoleService.log('action: ', action)
    consoleService.log('itemEditando: ', itemEditando)
    setLoading(true)
    if (action === 'update') {
      const item: any = await getItemInfo({ codigo: itemEditando?.codigo })
      consoleService.log('item: ', item)
      if (item) {
        setCODIGO(item?.codigo)
        setBARRA(item?.codigoBarras)
        setDESCRIPCION(item?.descripcion)
        setPVP(item?.pvp)
        setPVD(item?.pvd)
        setUNIDADAUX(item?.codigoUnidadVenta)
        setGRUPOAUX(item?.grupo)
        setIMPUESTO(item?.codigoImpuesto > 0 ? true : false)
        setSERVICIO(item?.tipoCodigo === 2 ? true : false)
        setCurrentTab('Editar')
        setLoading(false)
      }
    } else if (action === 'create') {
      setCurrentTab('Nuevo')
      setLoading(false)
    }
  }, [action, itemEditando, getItemInfo])

  const onChangeItem = React.useCallback(
    async (barCode: string) => {
      consoleService.log('onChangeItem ')
      const item: any = await getItemInfo({ barra: barCode })
      consoleService.log('item: ', item)
    },
    [getItemInfo],
  )

  const resetSearched = React.useCallback(() => {
    setSeleccion({
      index: null,
      row: null,
      selectedRow: null,
    })
  }, [])

  const getButtonDisabled = React.useCallback(
    (button: AccionMenu) => {
      switch (button.actionType) {
        case ButtonTypes.find:
        case ButtonTypes.undo:
          return false
        case ButtonTypes.new: {
          return currentTab !== 'Buscar' || seleccion.index !== null
        }
        case ButtonTypes.delete:
        case ButtonTypes.edit: {
          return currentTab !== 'Buscar' || seleccion.index === null
        }
        case ButtonTypes.save: {
          return currentTab === 'Buscar'
        }
        default:
          return true
      }
    },
    [currentTab, seleccion],
  )

  const cargarUnidades = React.useCallback(async () => {
    //setLoading(true);
    //playLoader()
    const _unidades = await VentasService.getUnidadesventa()
    if (_unidades.length > 0) {
      let unidades_: any
      unidades_ = JSON.parse(JSON.stringify(_unidades)).map(function (key) {
        return {
          value: key?.codigo,
          label: key?.nombre,
        }
      })
      setUnidades(unidades_)
    }
    //setLoading(false);
    //stopLoader()
  }, [])

  const cargarGrupos = React.useCallback(async () => {
    //setLoading(true);
    //playLoader()
    const _grupos = await InventarioService.getGrupos('')
    if (_grupos.length > 0) {
      let grupos_: any
      grupos_ = JSON.parse(JSON.stringify(_grupos)).map(function (key) {
        return {
          value: key?.codigo,
          label: key?.descripcion,
        }
      })
      setGrupos(grupos_)
    }
    //setLoading(false);
    stopLoader()
  }, [stopLoader])

  const deleteItem = React.useCallback(async () => {
    setConfirmDelete(false)
    if (seleccion['index'] === null) {
      setToast(
        'No se encuentra seleccionado un item, por favor seleccione un item para eliminarlo.',
        'warning',
      )
      return false
    }
    playLoader()
    try {
      const data = await VentasService.desactivarItem(
        seleccion['row']['codigo'],
      )
      setCODIGO(0)
      setDESCRIPCION('')
      setCurrentTab('Buscar')
      setCollapsed(true)
      setItems([])
      setSeleccion({
        index: null,
        row: null,
        selectedRow: null,
      })
      setToast('Registro eliminado..', 'success')
      stopLoader()
      return await data
    } catch (error) {
      consoleService.log('error', error)
      stopLoader()
      setToast(JSON.stringify(error), 'danger')
    }
  }, [playLoader, seleccion, setToast, stopLoader])

  const handleSubmit = React.useCallback(async (objConsultar) => {
    // playLoader()
    // try {
    //   const data = await VentasService.buscarItemCodYDescripcion(
    //     objConsultar['cod'],
    //     objConsultar['desc'],
    //     props.cliente.identificacion,
    //     DateUtils.strDateToDate moment(fecha, 'yyyy-MM-DD').format('DD-MM-yyyy'),
    //     {
    //       current: 0,
    //       size: 10
    //     }
    //   );
    //   consoleService.log("data", data)
    //   stopLoader()
    //   setToast(data['items'].length + " Registros encontrados.", "success")
    //   if (data['items'].length > 0) {
    //     setSearchingObj({ cod: "", desc: "" })
    //     setCollapsed(false)
    //     setItems(data['items'])
    //     // setInfoPagination({
    //     //   desde: data['from'],
    //     //   hasta: data['to'],
    //     //   paginaActual: data['currentPage'],
    //     //   totalPaginas: data['totalPaginas'],
    //     //   totalRegistros: data['count']
    //     // })
    //   }
    //   return await data;
    // } catch (error) {
    //   console.error(error);
    //   stopLoader()
    //   setToast(JSON.stringify(error), "danger")
    // }
  }, [])

  const handleSubmitSave = React.useCallback(
    async (objSave) => {
      playLoader()
      try {
        const data = await VentasService.modificarItem(objSave)
        consoleService.log('handleSubmitSave')
        consoleService.log('data', data)
        stopLoader()
        // if (data['error'] === true) {
        //   setToast(data['message'], "danger")
        //   return false;
        // } else {
        // }
        setToast('Registro Satisfactorio.', 'success')
        setCODIGO(0)
        setDESCRIPCION('')
        setCurrentTab('Buscar')
        setCollapsed(true)
        setItems([])
        setSeleccion({
          index: null,
          row: null,
          selectedRow: null,
        })

        return await data
      } catch (error) {
        consoleService.log('error', error)
        stopLoader()
        setToast(JSON.stringify(error), 'danger')
      }
    },
    [playLoader, setToast, stopLoader],
  )

  const setActionDelete = React.useCallback((data) => {
    setMessageConfirm('Está seguro que desea eliminar este item?')
    setShowConfirm(true)
    setConfirmDelete(true)
  }, [])

  const reiniciarFormulario = React.useCallback(() => {
    setCollapsed(true)
    setCODIGO(0)
    setBARRA('')
    setDESCRIPCION('')
    setUNIDAD('')
    setGRUPO('')
    setUNIDADAUX('')
    setGRUPOAUX('')
    setPVP('')
    setPVD('')
    setIMPUESTO(true)
    setSERVICIO(false)
    setSeleccion('')
    // setItems([])
    setShowConfirm(false)
    setConfirmDelete(false)
    setConfirmUpdate(false)
    setMessageConfirm('Está seguro que desea realizar estos cambios?')
    setSearchingObj(null)
    setItemObj(null)
    setSeleccion({
      index: null,
      row: null,
      selectedRow: null,
    })
  }, [])

  const returnAction = React.useCallback(
    (boton: AccionMenu) => {
      switch (boton.actionType) {
        case ButtonTypes.find:
          if (currentTab == 'Buscar') {
            setFuncion(ButtonTypes.find)
            return false
          } else if (currentTab === 'Editar') {
            resetSearched()
            setCurrentTab('Buscar')
          } else {
            setCurrentTab('Buscar')
            setFuncion(ButtonTypes.undo)
          }
          break
        case ButtonTypes.new:
          setCurrentTab('Nuevo')
          reiniciarFormulario()
          break
        case ButtonTypes.edit:
          if (seleccion['index'] === null) {
            setToast(
              'No se encuentra un registro seleccionado, Por favor seleccione un Item para editarlo.',
              'info',
            )
            return false
          } else {
            setCurrentTab('Editar')
            setCODIGO(seleccion['row']['codigo'])
            setBARRA(seleccion['row']['codigoBarras'])
            setDESCRIPCION(seleccion['row']['descripcion'])
            setPVP(seleccion['row']['pvp'])
            setPVD(seleccion['row']['pvd'])
            setUNIDADAUX(seleccion['row']['codigoUnidadVenta'])
            setGRUPOAUX(seleccion['row']['grupo'])
            setIMPUESTO(seleccion['row']['codigoImpuesto'] > 0 ? true : false)
            setSERVICIO(seleccion['row']['tipoCodigo'] === 2 ? true : false)
          }
          break
        case ButtonTypes.save:
          if (currentTab == 'Nuevo') {
            // document?.getElementById('btnGuardar')?.click()
            setFuncion(ButtonTypes.save)
            return false
          } else if (currentTab == 'Editar') {
            setFuncion(ButtonTypes.save)
            return false
          } else {
            setToast('No se encuentra cambios que realizar.', 'info')
          }
          break

        case ButtonTypes.delete:
          if (seleccion.index !== null) {
            setActionDelete(seleccion)
          } else {
            setToast(
              'No se encuentra seleccionado un item, por favor seleccione un item para eliminarlo.',
              'info',
            )
            return false
          }
          break
        case ButtonTypes.undo:
          consoleService.log('fiunction', ButtonTypes.undo)
          setFuncion(ButtonTypes.undo)

          break
        default:
          break
      }
    },
    [
      currentTab,
      reiniciarFormulario,
      resetSearched,
      seleccion,
      setActionDelete,
      setToast,
    ],
  )

  const capturaSeleccion = React.useCallback((data) => {
    consoleService.log('itemSeleccionado : ', data)
    setSeleccion(data)
  }, [])

  const reiniciarModal = React.useCallback(() => {
    if (action !== 'none') {
      onEditionItem()
    } else {
      setCollapsed(true)
      setCurrentTab('Buscar')
      setCODIGO(0)
      setBARRA('')
      setDESCRIPCION('')
      setUNIDAD('')
      setGRUPO('')
      setUNIDADAUX('')
      setGRUPOAUX('')
      setPVP('')
      setPVD('')
      setIMPUESTO(true)
      setSERVICIO(false)
      setSeleccion('')
      setItems([])
      setShowConfirm(false)
      setConfirmDelete(false)
      setConfirmUpdate(false)
      setMessageConfirm('Está seguro que desea realizar estos cambios?')
      setSearchingObj(null)
      setItemObj(null)
      setSeleccion({
        index: null,
        row: null,
        selectedRow: null,
      })
    }
  }, [action, onEditionItem])

  React.useEffect(() => {
    if (props.show === true) {
      reiniciarModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  React.useEffect(() => {
    if (props.dataToSearch !== null && props.dataToSearch !== undefined && props.dataToSearch?.descripcion) {
      const data: any = { descripcion: props.dataToSearch?.descripcion }
      setDataSearch(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  React.useEffect(() => {
    if (
      props.show === true &&
      grupos.length === 0 &&
      (currentTab === 'Editar' || currentTab === 'Nuevo')
    ) {
      cargarGrupos()
    }
  }, [props, grupos, cargarGrupos, currentTab])

  React.useEffect(() => {
    if (
      props.show === true &&
      unidades.length === 0 &&
      (currentTab === 'Editar' || currentTab === 'Nuevo')
    ) {
      cargarUnidades()
    }
  }, [props, unidades, cargarUnidades, currentTab])

  React.useEffect(() => {
    if (UNIDADAUX !== '' && unidades.length > 0 && seleccion?.row !== null) {
      setUNIDAD(
        unidades.find(
          (x) => x.value === seleccion['row']['codigoUnidadVenta'],
        ) ?? null,
      )
    }
  }, [UNIDADAUX, unidades, seleccion])

  React.useEffect(() => {
    if (GRUPOAUX !== '' && grupos.length > 0 && seleccion?.row !== null) {
      setGRUPO(
        grupos.find((x) => x.label === seleccion['row']['grupo']) ?? null,
      )
    }
  }, [GRUPOAUX, grupos, seleccion])

  const generaAcciones = () => {
    return (
      <div
        ref={mainRef}
        key="menuItems"
        className="btn-toolbar"
        role="toolbar"
        aria-label="Toolbar with button groups"
        style={{ marginBottom: '5px', paddingTop: '-2rem' }}
      >
        <div
          key="menuItemsBotones"
          className="btn-group-lg btn-group btn-sm me-2"
          role="group"
          aria-label="First group"
        >
          {acciones.map(function (x) {
            let prefixButton = 'modalItems'
            let nameButon = x.nombre
            const disabled = getButtonDisabled(x)
            return (
              <CTooltip
                key={'tool-' + prefixButton + nameButon}
                placement="top"
                content={nameButon}
              >
                <CButton
                  className="btn btn-secondary sm"
                  variant="ghost"
                  onClick={() => returnAction(x)}
                  size="sm"
                  disabled={disabled}
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={menuService.getIcon(x.icon) ?? faQuestionCircle}
                  />
                </CButton>
              </CTooltip>
            )
          })}
        </div>
      </div>
    )
  }

  const ejecutaSubmit = () => {
    handleSubmitSave({
      codigo: itemObj['codigo'],
      descripcion: itemObj['descripcion'],
      codigoBarras: itemObj['codigoBarras'],
      codigoUnidadVenta: itemObj['codigoUnidadVenta'],
      pvp: itemObj['pvp'],
      pvd: itemObj['pvd'],
      codigoGrupo: Number(itemObj['codigoGrupo']),
      codigoImpuesto: Number(itemObj['codigoImpuesto']),
      tipoCodigo: Number(itemObj['tipoCodigo']),
    })
    setShowConfirm(false)
    setConfirmUpdate(false)
  }

  // const onSubmitBuscar = (dataSearch) => {
  //   setTimeout(() => {
  //     if (dataSearch.codigoBarras === "" && dataSearch.descripcion === "") {
  //       setShowConfirm(true);
  //       setMessageConfirm("Está seguro que desea realizar esta búsqueda, no se ha ingresado ninguna descripción del Item, esto podría tardar unos minutos.");
  //       setSearchingObj({ cod: dataSearch.codigoBarras, desc: dataSearch.descripcion })
  //     } else {
  //       consoleService.log("onSubmitBuscar", dataSearch)
  //       handleSubmit({ cod: dataSearch.codigoBarras, desc: dataSearch.descripcion })
  //     }
  //   }, 100)
  // }

  const renderBody = () => {
    return <>{messageConfirm}</>
  }

  const renderFooter = () => {
    if (currentTab === 'Editar') {
      return (
        <>
          {confirmUpdate === true && (
            <CButton
              color="primary"
              onClick={() => {
                ejecutaSubmit()
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          <CButton
            color="secondary"
            onClick={() => setShowConfirm(!showConfirm)}
          >
            Cancelar
          </CButton>
        </>
      )
    }
    if (currentTab === 'Buscar') {
      return (
        <>
          {confirmDelete === true && (
            <CButton
              color="primary"
              onClick={() => {
                setShowConfirm(false)
                deleteItem()
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          {confirmDelete === false && (
            <CButton
              color="primary"
              onClick={() => {
                handleSubmit({
                  cod: searchingObj?.cod ?? '',
                  desc: searchingObj?.desc ?? '',
                })
                setShowConfirm(false)
              }}
            >
              {'Aceptar'}
            </CButton>
          )}
          <CButton
            color="secondary"
            onClick={() => setShowConfirm(!showConfirm)}
          >
            Cancelar
          </CButton>
        </>
      )
    } else {
      return (
        <>
          {showConfirm === true && (
            <CButton
              color="primary"
              onClick={() => {
                setShowConfirm(false)
                handleSubmit({
                  cod: searchingObj['cod'],
                  desc: searchingObj['desc'],
                })
              }}
            >
              {'Aceptar'}
            </CButton>
          )}{' '}
          <CButton
            color="secondary"
            onClick={() => setShowConfirm(!showConfirm)}
          >
            Cancelar
          </CButton>
        </>
      )
    }
  }

  const modalContent = () => {
    return (
      <CModal
        visible={props.show}
        size="lg"
        backdrop={'static'}
        onClose={() => {
          props.onCancel()
        }}
        id="modalItemsMantenimiento"
      >
        <CModalHeader closeButton>
          <CRow>
            <CCol lg="12" className="ms-2">
              <strong>{'Items'}</strong>
              <br></br>
            </CCol>
            <CCol lg="12">
              <CRow>
                <CCol>{generaAcciones()}</CCol>
              </CRow>
            </CCol>
          </CRow>
        </CModalHeader>
        <CModalBody>
          <Modalform
            name="confirmarEliminarItem"
            headerTitle={'Confirmar'}
            childrenBody={renderBody()}
            childrenFooter={renderFooter()}
            closeOnBackdrop={false}
            show={showConfirm}
            onClose={() => setShowConfirm(false)}
            centered={true}
            size="sm"
          />
          {currentTab === 'Buscar' && (
            <>
              <CCard>
                <CCardHeader
                  onClick={() => setCollapsed(!collapsed)}
                  className="d-flex justify-content-between"
                >
                  <strong> {'Buscar'} </strong>
                  <div className="card-header-actions">
                    <CLink className="card-header-action">
                      <CIcon
                        icon={collapsed ? cilChevronBottom : cilChevronTop}
                      />
                    </CLink>
                  </div>
                </CCardHeader>
                <CCollapse visible={collapsed}>
                  <CCardBody>
                    <FormularioBuscar
                      funcion={funcion}
                      resetFunction={() => setFuncion('')}
                      onChanged={(data) => {
                        setDataSearch(data)
                      }}
                      ejecutarBusqueda={() => setFuncion('Buscar')}
                    />
                  </CCardBody>
                </CCollapse>
              </CCard>
              <CRow>
                <CCol>
                  <TablaItems
                    transaccion={props.transaccion}
                    funcion={funcion}
                    name="tableItems"
                    data={items}
                    capturaSeleccion={(data) => capturaSeleccion(data)}
                    filaSeleccionada={seleccion}
                    actionDelete={(data) => setActionDelete(data)}
                    returnItem={(data) => {
                      consoleService.log('itemSeleccionado return : ', data)
                      props.returnItem(data ?? '')
                    }}
                    pagination={infoPagination}
                    cliente={cliente}
                    proveedor={proveedor}
                    dataSearch={dataSearch}
                    fecha={DateUtils.dateToString(new Date(), 'dd-MM-yyyy')}
                    resetFunction={() => {
                      setFuncion('')
                    }}
                    setDataItems={(data: any) => {
                      setItems(data)
                    }}
                    modulo={modulo}
                    tipoItem={tipoItem}
                    configFiltros={configFiltros}
                    requestType={requestType}
                    movimiento={movimiento}
                    codigoPrvAsociado={codigoPrvAsociado}
                    local={local}
                  />
                </CCol>
              </CRow>
            </>
          )}
          {(currentTab === 'Nuevo' || currentTab === 'Editar') && (
            <FormularioItem
              modulo={modulo}
              codigoBarras={BARRA}
              funcion={funcion}
              resetFunction={() => setFuncion('')}
              currentTab={currentTab}
              transaccion={transaccion}
              onChanged={(barCode) => {
                onChangeItem(barCode)
                consoleService.log('saved barCode : ', barCode)
              }}
            />
          )}
        </CModalBody>
      </CModal>
    )
  }

  if (loading) {
    return (
      <SmallLoadingIndicator mensaje="Cargando producto"></SmallLoadingIndicator>
    )
  }

  return (
    <Portal node={document && document.getElementById('root')}>
      <>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={'center'}
          visible={loader.show}
          showIndicator={true}
          shading={true}
          showPane={true}
          message={loader.mensaje}
          closeOnOutsideClick={false}
        />
        <GlobalLoader />
        {ReactDom.createPortal(modalContent(), document.body)}
      </>
    </Portal>
  )
}

export default memo(MainModalItems);
