/* eslint-disable no-case-declarations */
import { CBadge, CCol, CFormInput, CAlert } from '@coreui/react-pro'
import DateBox from 'devextreme-react/date-box'
import TextBox from 'devextreme-react/text-box'
import ValidationGroup from 'devextreme-react/validation-group'
import React, { useCallback, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateUtils, formatoFechasDatePickers } from '../../../../../helpers/dateUtils'
import { seleccionarSesion, setLoadSummaryDocs } from '../../../../../store/reducers'
import { RootState } from '../../../../../store/store'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import { StatesEdition } from '../../../../ventas/types/enums'
import { CustomButtons } from '../../../../ventas/types/generics'
import {
  setDatosEdicion, setEditLoader, clearDatosEdicion, updateDetalles, setMuestraError,
  setFormaPago, updateProveedor, setFechaPropuesta, setFechaEmicion, setCuotasCredito,
  setComprobanteElectronico, setEstablecimiento, setPuntoEmision, setNumero, setAutorizacion, setTipoCompra, setSustentoTributario, setLocal, setProyecto, setLocales, setMostrarBusquedaItems, updateTotales, updateObservaciones, addDetalle
} from '../../../pages/compras/store/editDataReducer'
import { CompraFullInfo, CompraInfo, CompraIngresar, ComprasDatosEdicion, ItemCompras, TFacturaIngresarDetalleC, TiposCompras, TTotalesFacturaC } from '../../../types/types'
import {
  Validator,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
} from 'devextreme-react/validator';
import ValidationSummary from 'devextreme-react/validation-summary';
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper'
import SelectBox from '../../../../../views/componentes/selectBox/selectBox'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { CompraService } from '../../../services/compra.service'
import { ProveedoresService } from '../../../../proveedores/services/proveedores.service'
import { addToast } from '../../../../../store/toasterReducer'
import { DocumentInfo, ToastTypes } from '../../../../../store/types'
import { buttonClick, changeEditStatus, clearButtonClick, closeTab, setButtons, setCurrentExecutingAction } from '../../../pages/compras/store/tabsReducer'
import Barcode from '../../../../../views/componentes/barcode/barcode'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { VentasService } from '../../../../ventas/services/ventas.service'
import PopupReenvioMail from '../../../../ventas/components/ventas/busquedaVentas/popupReenvioMail/index'
import { utilidades } from '../../../../../helpers/utilidades'
import VisualizaError from '../../../../ventas/pages/shared/visualizaError/visualizaError'
import { Switch } from 'devextreme-react/switch';
import { bancoService } from '../../../../bancos/service/bancos.service'
import Button from 'devextreme-react/button';
import { DetalleCompra, DetalleCompraRedux } from '../../../pages/compras/types/detalleCompra'
import MUIDataTable from "mui-datatables";
import { CustomSheet } from '../../../../../views/componentes/customSheet/customSheet';
import { getColumnnasTablaDetalleVentas, OptionsTablaDetalleVentas } from './helper';
import { CustomSheetNumberEditor } from '../../../../../views/componentes/customSheet/editors/numberEditor'
import CustomSheetNumberViewer from '../../../../../views/componentes/customSheet/viewers/sheetNumberViewer'
import { SheetDescripcionItemSelectEditor } from '../../../../ventas/components/liquidaciones/liquidacion/sheetEditors/sheetDescripcionItemSelectEditor'
import { SheetTextEditor } from '../../../../ventas/components/liquidaciones/liquidacion/sheetEditors/sheetTextEditor'
import { CustomSheetTextEditor } from '../../../../../views/componentes/customSheet/editors/textEditor'
import { DataSheetColumn } from '../../../../../views/componentes/customSheet/dataSheetColumn'
import { RowCommand } from '../../../../../views/componentes/customSheet/genericRow'
import { CellChangedArgs } from '../../../../../views/componentes/customSheet/cellChangedArgs'
import TextArea from 'devextreme-react/text-area'
import MainModalItems from '../../../../ventas/components/items/busquedaItemsModal/MainModalItems'
import ModificaItem from '../../../../ventas/components/items/modificarItem'
import { CompraHelper } from '../../../pages/compras/helpers/compraHelper'
import { ReportWithLocalDataModal } from '../../../../../views/componentes/xtrareports/ReportWithLocalDataModal'
import { toogleUsaDescuentoGlobal } from "../../../pages/compras/store/configuracionesComprasReducer";
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import { AdminAutorizacionesModal } from '../../../../componentes/modalAutorizaciones'
import { CreditosCompras } from '../../../../componentes/modalCreditos/creditosCompras'
import BuscarProveedorLookMemoUp from '../../buscarProveedorLookUpMemo/buscarProveedorLookUpMemo'
import { AutorizacionDocumentoVenta } from '../../../../ventas/types/types'
import { ComprobantesService } from '../../../../../containers/component/infoEmergente/comprobantes.service'
import BlockUi from '../../../../../views/componentes/librerias/block-ui'
import Dialog from '../../../../../views/componentes/librerias/bootstrap-dialog'
import { UrlHelper } from '../../../../../helpers/urlHelper'
import { consoleService } from '../../../../../services/console.service'
import { TabState } from '../../../../../store/genericTabTypes'
interface ICompraProps extends React.PropsWithChildren {
  info: DocumentInfo<CompraInfo>,
  tabId: string,
  tab: TabState<CompraInfo>
}

const tiposComprasConst: Array<TiposCompras> = [
  { codigo: 0, tipo: "TIPO DE COMPRA" },
  { codigo: 1, tipo: "ALIMENTACION" },
  { codigo: 2, tipo: "SALUD" },
  { codigo: 3, tipo: "EDUCACION" },
  { codigo: 4, tipo: "VIVIENDA" },
  { codigo: 5, tipo: "VESTIMENTA" },
  { codigo: 6, tipo: "INVENTARIO" }
];


const Compras: React.FunctionComponent<ICompraProps> = (props) => {

  const { info, tabId, tab } = props;
  const dispatch = useDispatch();
  const dialogRef = React.useRef<any>(null);

  const urls = UrlHelper.getUrls();

  const inputEstablec = React.useRef<any>();
  const inputPtoEmision = React.useRef<any>();
  const inputNroComp = React.useRef<any>();
  const inputAut = React.useRef<any>();

  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const localState = useSelector((state: RootState) => { return state.global.session?.local });
  const datosEdicion = useSelector((state: RootState) => { return state.compras.compras.editData[tabId] });
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });
  const establecimiento = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].establecimiento });
  const puntoEmision = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].puntoEmision });
  const fechaEmision = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].fechaEmision });
  const fechaPropuesta = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].fechaPropuesta });
  const proveedor = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].proveedor });
  const autorizacion = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].autorizacion });
  const tiposCompras = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].tiposCompras });
  const tipoCompra = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].tipoCompra });
  const sustentosTributarios = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].sustentosTributarios });
  const sustentoTributario = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].sustentoTributario });
  const proyectos = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].proyectos });
  const proyecto = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].proyecto });
  const locales = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].locales });
  const local = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].local });
  const codigo = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].codigo });

  const detalles = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].detalles });
  const loader = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].loader });
  const imprimir = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].imprimir });
  const formasPago = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].formasPago });
  const loading = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].loading });
  const numero = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].numero });
  const comprobanteElectronico = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].comprobanteElectronico });
  const usaDescuentoGlobal = useSelector((state: RootState) => { return state.compras.compras.configuraciones.usaDescuentoGlobal });
  const modulo = useSelector((state: RootState) => { return state.compras.compras.configuraciones.modulo });
  const descuentoGlobal = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].descuentoGlobal });
  const observaciones = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].observaciones });
  const mostrarBusquedaItems = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].mostrarBusquedaItems });
  const iva = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].iva });
  const cuotas = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].cuotas });
  const totales = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].totales });

  const formaPago = useSelector((state: RootState) => { return state.compras.compras.editData[tabId].formaPago });

  const validationGroupRef = React.useRef<any>();
  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{ claveAcceso: string, design: boolean }>();

  const [showReenviarMail, setShowReenviarMail] = React.useState<boolean>(false);
  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false);
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('');
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false);
  const [openAutorizaciones, setOpenAutorizaciones] = React.useState(false);

  const [filaEliminar, setFilaEliminar] = React.useState(-1);
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>([]);
  const [dataExport, setDataExport] = React.useState<Array<any>>([]);
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);
  const [keyProveedor,] = React.useState(1);
  const [mostrarIngresoCreditos, setMostrarIngresoCreditos] = React.useState(false);
  const [habilitarDisenador, setHabilitarDisenador] = React.useState<boolean>(true);
  const [localesAll, setLocalesAll] = React.useState<Array<any>>([]);


  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetalleCompraRedux>> = [];
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: 'minus',
      onExecute: (rowIndex) => { setFilaEliminar(rowIndex) },
    });
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: 'edit',
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true);
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras);
      },
    });
    return commads;
  }, [detalles]);

  const getVerificaPath = React.useCallback(async () => {
    if (urls.origin.includes("dev.acatha.com") || urls.origin.includes("localhost")) {
      setHabilitarDisenador(true);
    }
  }, [urls]);


  const showEditLoader = React.useCallback((mensaje: string) => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: mensaje,
        show: true
      }
    }));
  }, [dispatch, tabId])


  const hideEditLoader = React.useCallback(() => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: '',
        show: false
      }
    }));
  }, [dispatch, tabId])

  const validateNumeric = React.useCallback((data) => {
    const { value } = data;
    let contarString: number = 0;
    for (let i = 0; i < value.length; i++) {
      const caracter = value.charAt(i);
      if (!isNaN(caracter) === false) {
        contarString = contarString + 1;
      }
    }
    const sms = lh.getMessage(MessagesKeys.GenericNumericInvalid);
    // sms = sms.replace("%replace%", inputName);
    return contarString > 0 ? Promise.reject(sms) : Promise.resolve();
  }, []);

  const validateCompraDetalle = React.useCallback(() => {
    return detalles.length === 0 ? Promise.reject(lh.getMessage(MessagesKeys.TransaccionSinDetalles)) : Promise.resolve();
  }, [detalles]);

  const onObservacionesChanged = React.useCallback(({ value }: any) => {
    dispatch(updateObservaciones({
      key: tabId,
      observaciones: value ?? ''
    }))
  }, [tabId, dispatch])

  const validate = useCallback(async () => {
    const resolved = false;

    const validationResult = validationGroupRef.current.instance.validate();

    new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(150);
      }
      resolve(resolved);
    });

    if (!validationResult.isValid) {
      //setTieneErroresValidacion(true);

      const errors: any = [];

      validationResult.brokenRules.forEach(function (item: any) {
        const limpiaTexto: any = item['message'].replace("-", "");
        errors.push({ ...item, message: limpiaTexto })
      })
      return { 'isValid': false, 'errors': errors };
    }

    return { 'isValid': true, 'errors': [] };

  }, [])

  const onParseInfoCompra = React.useCallback(async (compraCodigo: number, getInfoCompra: CompraFullInfo) => {

    const data = { ...defaulDatosEdicionCompra };

    const getDetalleCompra = await CompraService.getDetallesCompra(compraCodigo);


    if (getInfoCompra) {

      const fullProveedorInfo = await ProveedoresService.getProveedorByCode(getInfoCompra?.proveedorCodigo ?? 0);

      if (fullProveedorInfo) {
        data.proveedor = {
          codigo: Number(fullProveedorInfo?.codigo) ?? 0,
          nombre: fullProveedorInfo?.nombre ?? "",
          razonComercial: fullProveedorInfo?.razonComercial ?? "",
          identificacion: fullProveedorInfo?.identificacion ?? "",
          email: fullProveedorInfo?.email ?? "",
          direccion: fullProveedorInfo?.direccion ?? "",
          diaReposicion: fullProveedorInfo?.diaReposicion ?? "",
          diasCredito: fullProveedorInfo?.diasCredito ?? 0,
          intervalo: fullProveedorInfo?.intervalo ?? 0,
          limiteCredito: fullProveedorInfo?.limiteCredito ?? 0,
          personaAtencionCompras: fullProveedorInfo?.personaAtencionCompras ?? "",
          personaAtencionPagos: fullProveedorInfo?.personaAtencionPagos ?? "",
          telefono: fullProveedorInfo?.telefono ?? "",
          tipoIdDescripcion: fullProveedorInfo?.tipoIdentificacionDescripcion ?? "",
          tipoIdentificacion: fullProveedorInfo?.tipoIdentificacion ?? ""
        };
      }

      data.codigo = getInfoCompra?.codigo;
      data.numero = getInfoCompra?.numero;
      data.autorizacion = getInfoCompra?.autorizacion;
      data.establecimiento = getInfoCompra?.establecimiento;
      data.puntoEmision = getInfoCompra?.puntoEmision;
      data.fechaEmision = DateUtils.apiDateToPickersDate(getInfoCompra?.fecha);
      data.descuentoGlobal = getInfoCompra?.descuento;
      data.descuentoGlobal = getInfoCompra?.descuento;
      data.observaciones = getInfoCompra?.observaciones;
      data.iva = getInfoCompra?.IVA;



      if (getDetalleCompra) {
        const tmpDet2 = CompraHelper.detallesToDetallesCompra(getDetalleCompra);

        data.detalles = tmpDet2;
      }
      const formaPago = formasPago.find(x => x.codigo === Number(getInfoCompra.formaPagoCodigo))
      if (formaPago) {
        data.formasPago = formasPago;
        data.formaPago = formaPago;
      }

      const sustento = sustentosTributarios.find(x => String(x.codigo) === String(getInfoCompra.sustentoCodigo))
      if (sustento) {
        data.sustentosTributarios = sustentosTributarios;
        data.sustentoTributario = sustento;
      }

      data.tiposCompras = tiposComprasConst;
      const tipoCompra = tiposComprasConst.find(x => Number(x.codigo) === Number(getInfoCompra.tipo))
      if (tipoCompra) {
        data.tipoCompra = tipoCompra;
      }

      data.proyectos = proyectos;
      data.locales = locales;

      const localSelect = locales.find(x => Number(x.codigo) === Number(getInfoCompra.localCodigo))
      if (localSelect) {
        const proyectoSelect = proyectos.find(x => x.codigo === Number(localSelect.proyectoCodigo))

        if (localSelect) data.local = localSelect;
        if (proyectoSelect) data.proyecto = proyectoSelect;
      }

    }
    return data;
  }, [
    formasPago, sustentosTributarios, proyectos, locales
  ])


  const guardar = React.useCallback(async () => {
    consoleService.log('guardar');
    if (empresa == null) {
      return;
    }

    const validated = await validate();
    if (!validated['isValid']) {
      setShowErrorMessages(validated['errors']);
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...datosEdicion,
          tieneError: true,
          mensajeError: ""
        }
      }))
      return
    } else {
      setShowErrorMessages([]);
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...datosEdicion,
          tieneError: false,
          mensajeError: ""
        }
      }))
    }

    if (datosEdicion.formaPago && datosEdicion?.formaPago.nombre === 'CREDITO' && cuotas.length === 0) {
      dispatch(addToast({
        title: 'Guardar',
        content: 'Por favor, detalle la forma de pago!!!',
        type: ToastTypes.Warning
      }))
      setMostrarIngresoCreditos(true);
      return false;
    }

    showEditLoader("Guardando compra...")

    // verificar plan activo
    const resultado = await ComprobantesService.getRecurrente(empresa.uuid);
    if (resultado.codigo && resultado.planActivo === false) {
      dispatch(setLoadSummaryDocs(true));
      hideEditLoader();
      dispatch(addToast({
        autoHide: true,
        content: lh.getMessage(MessagesKeys.GenericSuscriptionEnd, ""),
        fade: true,
        title: 'Renovar Suscripción.',
        type: ToastTypes.Warning
      }))
      return false;
    }

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.save
    }));


    try {

      const fullProveedorInfo = await ProveedoresService.getProveedorByCode(proveedor?.codigo ?? 0);
      const compra: CompraIngresar = {
        sustentoTributario: sustentoTributario?.codigo ?? "00",
        local: local?.codigo ?? 0,
        codigo: codigo,
        fechaEmision: DateUtils.pickersDateToApiDate(fechaEmision),
        establecimiento: establecimiento,
        puntoEmision: puntoEmision,
        numero: numero,
        host: puntoVenta ?? '',
        autorizacion: autorizacion,
        observaciones: observaciones ?? "",
        items: detalles.map(det => {
          const detalle: TFacturaIngresarDetalleC = {
            // codigo_auxiliar: det.codigo.toString(),
            codigoPrincipal: det.codigoBarras,
            precio_unitario: det._precioUnitario,
            cantidad: det._cantidad,
            precioTotalSinImpuestos: det._subtotal + det._descuento,
            descripcion: det.descripcion,
            descuento: det._porcentajeDescuento,
            detalles_adicionales: {},
            impuestos: [
              {
                codigo: det.tieneIva ? 3 : 2,
                tarifa: (det.porcentajeiva * 100).toFixed(0),
                codigo_porcentaje: det.tieneIva ? 2 : 0,
                base_imponible: det._subtotal + det._descuento,
                valor: det._iva
              }
            ]
          };
          return detalle;
        }),
        moneda: 'USD',
        proveedor: {
          identificacion: proveedor?.identificacion ?? '',
          nombreComercial: proveedor?.razonComercial ?? '',
          razonSocial: proveedor?.razonComercial ?? '',
          ciudad: fullProveedorInfo.ciudadNombre ?? '',
          contribuyenteEspecial: '',
          direccion: fullProveedorInfo.direccion ?? '',
          email: fullProveedorInfo.email ?? '',
          obligadoContabilidad: true,
          telefono: fullProveedorInfo.telefono ?? ''
        },
        pagos: [
          {
            medio: formaPago?.nombre ?? '',
            total: parseFloat((totales.subtotal0 + totales.subtotalIva + iva).toFixed(2)),
            id_medio: datosEdicion?.formaPago?.codigo.toString() ?? "",
          }
        ],
        totales: {
          descuentoAdicional: 0,
          importeTotal: parseFloat((totales.subtotal0 + totales.subtotalIva + iva).toFixed(2)),
          propina: 0,
          totalSinImpuestos: parseFloat((totales.subtotal0 + totales.subtotalIva).toFixed(2)),
          impuestos: [
            {
              codigo: 2,
              codigoPorcentaje: 0,
              descuentoAdicional: 0,
              baseImponible: totales.subtotal0,
              valor: 0
            },
            {
              codigo: 2,
              codigoPorcentaje: 2,
              descuentoAdicional: 0,
              baseImponible: totales.subtotalIva,
              valor: parseFloat(iva.toFixed(2))
            }
          ]
        },
        cuotas: cuotas.map(c => {
          return {
            cuota: c.cuota,
            fechaVencimiento: c.vencimiento,
            saldo: c.saldo,
            interes: 0,
            letra: c.letra,
            saldoCapital: c.saldo
          }
        }),
        tipoComprobante: "01"
      }
      consoleService.log('compra', compra);

      const resultado = await CompraService.ingresar(compra);
      if (resultado) {
        const getInfoCompra = await CompraService.getCompra(resultado);

        const infoCompra = await onParseInfoCompra(resultado, getInfoCompra);
        dispatch(setDatosEdicion({
          data: {
            ...datosEdicion,
            ...infoCompra,
            loading: false,
            loader: {
              show: false,
              mensaje: ''
            },
            imprimir: empresa?.imprimeAutomaticamenteAlfacturar ?? false
          },
          key: tabId
        }));

        dispatch(changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          info: { ...getInfoCompra },
          buttons: {
            ...CompraButtons,
            Imprimir: true,
            Guardar: false,
            Editar: true,
            Deshacer: false,
            Descuento: false,
            Anular: false,
            Eliminar: false,
          }
        }));

        dispatch(addToast({
          id: '',
          autoHide: true,
          content: 'Guardado',
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Success
        }))

      }

    } catch (error) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al guardar la compra ' + (typeof error === 'string' ? error : JSON.stringify(error)),
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }));
      // dispatch(setMuestraError({
      //   key: tabId,
      //   error: error
      // }));
      hideEditLoader();
    }

    hideEditLoader();
  }, [
    cuotas,
    sustentoTributario,
    codigo,
    local,
    observaciones,
    validate,
    showEditLoader,
    datosEdicion,
    hideEditLoader,
    fechaEmision,
    detalles,
    puntoVenta,
    establecimiento,
    puntoEmision,
    proveedor,
    formaPago,
    empresa,
    tabId,
    autorizacion, iva, numero, onParseInfoCompra, totales,
    dispatch
  ]);

  const generarOptionsLocales = React.useCallback((obtenidos) => {
    const objeto: any = []
    Object.keys(obtenidos).map(function (key, i) {
      objeto.push({
        codigo: Number(obtenidos[key]['localCodigo']),
        nombre: obtenidos[key]['localNombre'],
        proyectoCodigo: Number(obtenidos[key]['localPertenece']),
        proyectoNombre: obtenidos[key]['localPerteneceDescripcion']
      })
    })
    setLocalesAll(objeto ?? [])
    return objeto
  }, []);

  const generarOptionsProyectos = React.useCallback((obtenidos) => {
    const objeto: any = []
    Object.keys(obtenidos).map(function (key, i) {
      objeto.push({
        codigo: Number(obtenidos[key]['PRO_CODIGO']),
        nombre: obtenidos[key]['PRO_NOMBRE'],
        locales: obtenidos[key]['LOCALES'].map(det => {
          if (det !== false) {
            const local: any = {
              proyectoCodigo: Number(obtenidos[key]['PRO_CODIGO']),
              proyectoNombre: obtenidos[key]['PRO_NOMBRE'],
              ...det
            }
            return local;
          }

        })
      })
    })
    return objeto
  }, []);

  const generarLocales = React.useCallback(async (obtenidos) => {

    const proyectos_object: any = []
    Object.keys(obtenidos).map((key, i) => {
      if (obtenidos[key]['locales'].length > 0) {
        proyectos_object.push(...obtenidos[key]['locales'])
      }
    })
    // buscar el local principal de la empresa y retornarlo en el lugar de general
    if (proyectos_object.length > 0) {
      const local_object: any = []
      proyectos_object.forEach(function (tipo_I_) {
        if (tipo_I_ !== 'false' && tipo_I_ !== false && tipo_I_ !== undefined) {
          const to_array_proy: any = {
            codigo: Number(tipo_I_['LOC_CODIGO']),
            nombre:
              tipo_I_['LOC_NOMBRE'] === 'Elija una opción' ? 'General' : tipo_I_['LOC_NOMBRE'],
            proyectoCodigo: Number(tipo_I_['proyectoCodigo']),
            proyectoNombre: String(tipo_I_['proyectoNombre'])

          }
          local_object.push(to_array_proy)
        }
      })
      return local_object
    }
  }, []);

  const extraerLocales = React.useCallback(async (obtenidos) => {
    if (obtenidos !== "") {
      if (obtenidos['codigo'] > 0) {
        dispatch(setLocales({ key: tabId, locales: [] }))
        const local_object: any = [];
        local_object.push({
          codigo: -1,
          nombre: "General"
        });
        obtenidos['locales'].forEach(function (tipo_I_) {
          if (tipo_I_ !== "false" && tipo_I_ !== false) {
            const to_array_proy: any = {
              codigo: Number(tipo_I_["LOC_CODIGO"]),
              nombre:
                tipo_I_["LOC_NOMBRE"] === "Elija una opción"
                  ? "General"
                  : tipo_I_["LOC_NOMBRE"],
              proyectoNombre: Number(tipo_I_?.proyectoNombre) ?? -1,
              proyectoCodigo: String(tipo_I_?.proyectoCodigo) ?? ""
            };
            local_object.push(to_array_proy);
          }
        });

        dispatch(setLocales({ key: tabId, locales: local_object }))
        dispatch(setLocal({ key: tabId, local: local_object[0] }))
      }
    }
  }, [dispatch, tabId]);


  const onChangeProyecto = React.useCallback(async (ev) => {
    if (ev) {
      if (ev.value !== -1) {
        extraerLocales(ev)
      } else {
        const localesData = await generarLocales(proyectos);
        if (localesData) {
          dispatch(setLocales({ key: tabId, locales: localesData }))
          dispatch(setLocal({ key: tabId, local: localesData[0] }))
        }
      }
    } else {
      dispatch(setLocales({ key: tabId, locales: localesAll }))
      let localSeleccionar = null
      const localSelect = localesAll.findIndex(x => Number(x.codigo) === Number(localState.codigo))
      if (localSelect > -1) {
        localSeleccionar = localesAll[localSelect];
      } else {
        localSeleccionar = localesAll[0];
      }
      dispatch(setLocal({ key: tabId, local: localSeleccionar }))

      // const localesData = await generarLocales(proyectos);
      // if (localesData) {
      //   dispatch(setLocales({ key: tabId, locales: localesData }))
      //   dispatch(setLocal({ key: tabId, local: localesData[0] }))
      // }
    }
  }, [
    localState, localesAll, proyectos, extraerLocales, generarLocales, dispatch, tabId
  ]);

  const cargarCompra = React.useCallback(async (code: number) => {


    if (loading === false) {
      return;
    };

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.find
    }))

    showEditLoader("Cargando compra...")
    const data = { ...defaulDatosEdicionCompra };
    try {
      const getInfoCompra = await CompraService.getCompra(code);
      const compra = await onParseInfoCompra(code, getInfoCompra);
      const formasPago = await CompraService.getFormasPago();
      const sustentosTributarios = await CompraService.getSustentosTributarios();
      const proyetosApi = await bancoService.getProyectos();
      const localesApi = await bancoService.getLocales(empresa.codigo, 'Elije una opción');

      if (compra == null) {
        return;
      }
      if (imprimir && compra.autorizacion) {
        setVerFacturaPdf({ claveAcceso: compra.autorizacion, design: false });;
      }
      if (formasPago) {
        compra.formasPago = formasPago;

        const formaPago = formasPago.find(x => x.codigo === Number(getInfoCompra.formaPagoCodigo))
        if (formaPago) {
          compra.formaPago = formaPago;
        }
      }
      if (sustentosTributarios) {
        compra.sustentosTributarios = sustentosTributarios;
        const sustento = sustentosTributarios.find(x => String(x.codigo) === String(getInfoCompra.sustentoCodigo))
        if (sustento) {
          compra.sustentoTributario = sustento;
        }
      }
      if (proyetosApi && proyetosApi.auto) {
        const proyectosData = generarOptionsProyectos(proyetosApi.auto);
        compra.proyectos = proyectosData;

        if (proyectosData.length > 0) {
          const localesData = await generarLocales(proyectosData);
          if (localesData) {
            compra.locales = localesData;

            const localSelect = localesData.find(x => Number(x.codigo) === Number(getInfoCompra.localCodigo))
            if (localSelect > -1) {
              const proyectoSelect = proyectosData.find(x => x.codigo === Number(localSelect.proyectoCodigo))

              if (localSelect) compra.local = localSelect;
              if (proyectoSelect) compra.proyecto = proyectoSelect;
            }

          }
        }
      }
      if (compra.local === null) {
        if (localesApi && localesApi.auto) {
          const localesData = generarOptionsLocales(localesApi.auto);
          compra.locales = localesData;
          const localSelect = localesData.findIndex(x => Number(x.codigo) === Number(getInfoCompra.localCodigo))
          if (localSelect > -1) {
            compra.local = localesData[localSelect];
          } else {
            compra.local = localesData[0];
          }
        }
      }
      compra.tiposCompras = tiposComprasConst;
      const tipoCompra = tiposComprasConst.find(x => Number(x.codigo) === Number(getInfoCompra.tipo))
      if (tipoCompra) {
        compra.tipoCompra = tipoCompra;
      }

      data.loader = {
        show: false,
        mensaje: ''
      };
      data.codigo = code;


      dispatch(setDatosEdicion({
        data: {
          ...data,
          ...compra,
          loading: false
        },
        key: tabId
      }));



      dispatch(setButtons({
        tabKey: tabId,
        buttons: {
          ...CompraButtons,
          Guardar: true,
          Anular: true,
          Deshacer: false,
          Descuento: false,
          Imprimir: true
        }
      }))

    } catch (error) {
      console.error(error);
      // data.error = error;
      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));
    }
    hideEditLoader();
  }, [
    onParseInfoCompra, imprimir, loading, tabId,
    hideEditLoader, showEditLoader, dispatch, generarLocales, generarOptionsProyectos,
    empresa, generarOptionsLocales
  ]);

  const getAutorizacionDocumento = React.useCallback((dataEdition) => {
    console.log('getAutorizacionDocumento')
    console.log('dataEdition', dataEdition)
    const autorizacion: AutorizacionDocumentoVenta = {
      codigoAutorizacion: 0,
      codigoTIC: "01",
      comprobandeTIC: "01",
      aut: dataEdition.autorizacion,
      establecimiento: dataEdition.establecimiento,
      ptoemision: dataEdition.puntoEmision,
      numActual: 0,
      idTipoDeNegocio: -1,
      codTributarioTipoDeNegocio: 0,
      descTipoDeNegocio: '',
      especial: dataEdition['especial'],
      restaurante: dataEdition['restaurante'],
    }
    return autorizacion;
  }, []);

  const modoNuevo = React.useCallback(async (puntoVenta: string, template: number, limpiar: boolean = false) => {

    if (loading === false && !limpiar) {
      return;
    }
    sessionStorage.removeItem('autorizacionModulo');

    let data = { ...defaulDatosEdicionCompra };

    dispatch(setButtons({
      tabKey: tabId,
      buttons: {
        ...CompraButtons,
        Editar: false,
        Imprimir: false,
        Exportar: false,
        Enviar: false,
        Guardar: true,
      }
    }));
    try {
      const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers);//  moment().format('yyyy-MM-DD');
      const autorizacion = getAutorizacionDocumento(data);

      sessionStorage.setItem('autorizacionModulo', JSON.stringify(autorizacion));

      const iva = await VentasService.getIva(DateUtils.pickersDateToApiDate(fecha), autorizacion);
      const formasPago = await CompraService.getFormasPago();
      const sustentosTributarios = await CompraService.getSustentosTributarios();
      const proyetosApi = await bancoService.getProyectos();
      const localesApi = await bancoService.getLocales(empresa.codigo, 'Elije una opción');

      if (formasPago) {
        data.formasPago = formasPago;
      }

      if (sustentosTributarios) {
        data.sustentosTributarios = sustentosTributarios;
      }

      data.iva = iva ?? 0;
      data.tiposCompras = tiposComprasConst;

      if (template > 0) {

        const getInfoCompra = await CompraService.getCompra(template);
        const compra = await onParseInfoCompra(template, getInfoCompra);

        data = {
          ...data,
          ...compra,
        }

        if (formasPago) {
          data.formasPago = formasPago;

          const formaPago = formasPago.find(x => x.codigo === Number(getInfoCompra.formaPagoCodigo))
          if (formaPago) {
            data.formaPago = formaPago;
          }
        }
        if (sustentosTributarios) {
          const sustento = sustentosTributarios.find(x => String(x.codigo) === String(getInfoCompra.sustentoCodigo))
          if (sustento) {
            data.sustentosTributarios = sustentosTributarios;
            data.sustentoTributario = sustento;
          }
        }

        data.tiposCompras = tiposComprasConst;
        const tipoCompra = tiposComprasConst.find(x => Number(x.codigo) === Number(getInfoCompra.tipo))
        if (tipoCompra) {
          data.tipoCompra = tipoCompra;
        }

        if (proyetosApi && proyetosApi.auto) {
          const proyectosData = generarOptionsProyectos(proyetosApi.auto);
          data.proyectos = proyectosData;

          if (proyectosData.length > 0) {
            const localesData = await generarLocales(proyectosData);
            if (localesData) {
              data.locales = localesData;

              const localSelect = localesData.find(x => Number(x.codigo) === Number(getInfoCompra.localCodigo))
              const proyectoSelect = proyectosData.find(x => x.codigo === Number(localSelect.proyectoCodigo))

              if (localSelect) data.local = localSelect;
              if (proyectoSelect) data.proyecto = proyectoSelect;
            }
          }
        }

        if (localesApi && localesApi.auto) {
          const localesData = generarOptionsLocales(localesApi.auto);
          data.locales = localesData;
          const localSelect = localesData.findIndex(x => Number(x.codigo) === Number(localState.codigo))
          if (localSelect > -1) {
            data.local = localesData[localSelect];
          } else {
            data.local = localesData[0];
          }
        }

        data.codigo = 0;

      } else {




        if (sustentosTributarios) {
          data.sustentoTributario = sustentosTributarios[0];
        }
        if (proyetosApi && proyetosApi.auto) {
          const proyectosData = generarOptionsProyectos(proyetosApi.auto)
          data.proyectos = proyectosData;
          data.proyecto = proyectosData[0];
          if (proyectosData.length > 0) {
            const localesData = await generarLocales(proyectosData);
            if (localesData) {
              data.locales = localesData;
              data.local = localesData[0];
            }
          }
        }

        if (localesApi && localesApi.auto) {
          const localesData = generarOptionsLocales(localesApi.auto);
          data.locales = localesData;
          data.local = localesData[0];

          const localSelect = localesData.findIndex(x => Number(x.codigo) === Number(localState.codigo))
          if (localSelect > -1) {
            data.local = localesData[localSelect];
          } else {
            data.local = localesData[0];
          }

        }

        data.tipoCompra = tiposComprasConst[0];
        data.detalles = [];
      }

      data.loading = false;
      data.loader = {
        mensaje: '',
        show: false
      }
    } catch (error) {

      data.loading = false;
      data.tieneError = true;
      data.mensajeError = typeof error == 'string' ? error : JSON.stringify(error);
      data.loader = {
        mensaje: '',
        show: false
      }
      dispatch(addToast({
        content: data.mensajeError,
        type: ToastTypes.Danger
      }))

    }

    dispatch(setDatosEdicion({
      data: data,
      key: tabId
    }));

  }, [
    localState, empresa, loading, tabId, dispatch, onParseInfoCompra,
    generarLocales, generarOptionsProyectos, getAutorizacionDocumento,
    generarOptionsLocales
  ]);

  const parseToPrint = React.useCallback(async () => {
    const fullProveedorInfo = await ProveedoresService.getProveedorByCode(datosEdicion?.proveedor?.codigo ?? 0);

    const compra: any = {
      local: local?.nombre ?? "",
      fechaEmision: DateUtils.pickersDateToApiDate(fechaEmision),
      establecimiento: establecimiento,
      puntoEmision: puntoEmision,
      numero: numero,
      host: puntoVenta ?? '',
      autorizacion: autorizacion,
      observaciones: observaciones ?? "",
      items: detalles.map(det => {
        const detalle: TFacturaIngresarDetalleC = {
          // codigo_auxiliar: det.codigo.toString(),
          codigoPrincipal: det.codigoBarras,
          precio_unitario: det._precioUnitario,
          cantidad: det._cantidad,
          precioTotalSinImpuestos: det._subtotal + det._descuento,
          descripcion: det.descripcion,
          descuento: det._porcentajeDescuento,
          detalles_adicionales: {},
          impuestos: [
            {
              codigo: det.tieneIva ? 3 : 2,
              tarifa: (det.porcentajeiva * 100).toFixed(0),
              codigo_porcentaje: det.tieneIva ? 2 : 0,
              base_imponible: det._subtotal + det._descuento,
              valor: det._iva
            }
          ]
        };
        return detalle;
      }),
      moneda: 'USD',
      proveedor: {
        identificacion: proveedor?.identificacion ?? '',
        nombreComercial: proveedor?.razonComercial ?? '',
        razonSocial: proveedor?.razonComercial ?? '',
        ciudad: fullProveedorInfo.ciudadNombre ?? '',
        contribuyenteEspecial: '',
        direccion: fullProveedorInfo.direccion ?? '',
        email: fullProveedorInfo.email ?? '',
        obligadoContabilidad: true,
        telefono: fullProveedorInfo.telefono ?? ''
      },
      pagos: [
        {
          medio: formaPago?.nombre ?? '',
          total: parseFloat((totales.subtotal0 + totales.subtotalIva + iva).toFixed(2))
        }
      ],
      totales: totales
    }
    return compra;
  }, [
    autorizacion, detalles, establecimiento, fechaEmision, formaPago?.nombre, iva, numero, observaciones, proveedor,
    puntoEmision, puntoVenta, totales, datosEdicion, local
  ]);

  const handlePrint = React.useCallback(async (design: boolean) => {
    const compra = await parseToPrint();
    setDataExport([compra]);
    setReporte(!design ? "Viewer" : "Designer")
    setVerFacturaPdf({ claveAcceso: compra.autorizacion, design: design });

  }, [parseToPrint])


  const deshacer = React.useCallback(() => {
    if (info.codigo === 0) {
      const tId: any = tabId;
      dispatch(closeTab(tId));
      dispatch(clearDatosEdicion(tId))
    } else {
      if (info.info === null) {
        const errorInfo = "No se encuentra la información del documento"
        throw errorInfo
      }
      // dispatch(changeEditStatus({
      //   estado: StatesEdition.save,
      //   tabIndex: tabIndex,
      //   info: info.info
      // }));
      cargarCompra(info.codigo);
    }
  }, [tabId, info, cargarCompra, dispatch]);


  const verCuotasGeneradas = React.useCallback(() => {
    if (cuotas.length > 0) {
      dispatch(setCuotasCredito({
        key: tabId,
        cuotas: []
      }))
    }
  }, [tabId, cuotas, dispatch]);


  const sheetOnCellsChanged = React.useCallback(async (arrayOfChanges: Array<CellChangedArgs<DetalleCompraRedux>>) => {
    const _detalles = detalles.slice(0);
    showEditLoader('Por favor espere...')
    for (const change of arrayOfChanges) {
      const detalle = new DetalleCompra(); //clase que realiza los calculos para un detalle
      detalle.setDetalle(change.rowData);
      switch (change.col.name) {
        case 'codigoBarras':
          showEditLoader('Buscando item...');
          const item = await CompraService.getItemCodigoBarras(detalle.codigoBarras, proveedor);

          hideEditLoader();
          if (item) {
            detalle.setItem(item);
          } else {
            dispatch(addToast({
              content: 'No se encuentra el item con código ' + change.newValue,
              title: 'Agregar item',
              type: ToastTypes.Warning,
              autoHide: 2000
            }))
            continue;
          }
          break;
        case 'cantidad':
          detalle.cantidad = change.newValue;
          detalle.calcular();
          break;
        case 'descripcion':
          if (typeof change.newValue === 'string') {
            if (detalle.codigo === undefined) {
              dispatch(addToast({
                content: 'No se puede pegar un item, debe usar la búsqueda o la columna código.',
                title: 'Agregar item',
                type: ToastTypes.Warning,
                autoHide: 2000
              }))
              continue;
            }
            detalle.descripcion = change.newValue;
          }
          break;
        case '_cantidad':
          detalle.cantidad = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_porcentajeDescuento':
          detalle.porcentajeDescuento = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_precioUnitario':
          detalle.precio = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_precioIva':
          detalle.precioIva = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        default:
          break;
      }
      if (change.rowIndex === -1) { //nueva fila
        _detalles.push(detalle.getDetalle());
      } else {
        _detalles[change.rowIndex] = detalle.getDetalle();
      }
    }
    hideEditLoader();
    dispatch(updateDetalles({
      detalles: _detalles,
      key: tabId
    }));
    verCuotasGeneradas()
  }, [detalles, dispatch, hideEditLoader, tabId, showEditLoader, verCuotasGeneradas, proveedor]);



  const calcularTotales = React.useCallback((detalles: Array<DetalleCompraRedux>, descuentoGlobal: number) => {
    const totalCero: TTotalesFacturaC = {
      descuento: 0,
      descuentoGlobal: descuentoGlobal,
      ice: 0,
      iva: 0,
      subtotal: 0,
      subtotal0: 0,
      subtotalIva: 0,
      total: 0
    };
    const totales = detalles.reduce((sum, next) => {
      sum.subtotal += next._subtotal;
      sum.subtotal0 += next._subtotal0;
      sum.subtotalIva += next._subtotalIva;
      sum.iva += next._iva;
      sum.descuento += next._descuento;
      sum.total += next._total;

      return sum;
    }, totalCero);

    if (descuentoGlobal > totales.total) {
      dispatch(addToast({
        title: 'Calcular totales',
        content: 'El descuento no puede ser mayor al total',
        type: ToastTypes.Warning,
        autoHide: 2500
      }))
    }

    if (descuentoGlobal > 0) {
      if (totales.total === 0) {
        return;
      }
      const proporciaonalIva = ((100 / totales.subtotal * totales.subtotalIva) / 100);
      const proporcionalBaseCero = 1 - proporciaonalIva;
      totales.subtotalIva = totales.subtotalIva - (descuentoGlobal * proporciaonalIva);
      totales.iva = totales.subtotalIva * (iva / 100);
      totales.subtotal0 = totales.subtotal0 - (descuentoGlobal * proporcionalBaseCero);
      totales.total = totales.subtotalIva + totales.iva + totales.subtotal0;
    } else if (descuentoGlobal < 0) {
      return;
    }

    dispatch(updateTotales({
      totales: totales,
      key: tabId
    }));
  }, [tabId, dispatch, iva])

  const establecerDescuentoGlobal = React.useCallback((valor) => {
    calcularTotales(detalles, valor);
    //setdescuentoGlobal(valor);
  }, [detalles, calcularTotales]);

  const modoEdicion = React.useCallback(() => {
    if (proveedor == null) {
      return;
    }
    if (autorizacion.length === 37 || autorizacion.length === 49) {
      dispatch(addToast({
        autoHide: true,
        content: 'Una Compra eléctronica no puede ser editada!!!',
        fade: true,
        id: '',
        title: 'Editar Compra',
        type: ToastTypes.Info
      }));
      // dispatch(setMuestraError({
      //   key: tabId,
      //   mensajeError: "Una Retención eléctronica no puede ser editada!!!"
      // }));
    } else {

    }
  }, [autorizacion, proveedor, dispatch]);

  const onCrearProductoButtonClick = React.useCallback(() => {
    setCodigoBarrasItemEditar('');
    setMostrarEdicionItems(true);
  }, [])

  const onMasOpcionesButtonClick = React.useCallback(() => {
    dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: true }));
    //setMostrarBusquedaItems(true);
  }, [tabId, dispatch]);

  const muiTableCellsChanged = React.useCallback((row, col, newValue) => {
    const _detalles = detalles.slice(0);
    //const detalle = _detalles[row];
    const _detalle = new DetalleCompra();
    _detalle.setDetalle(_detalles[row]);

    //const dt = datosEdicion.detallesMuiTable;
    switch (col) {
      case 2://TABLECOL_DESCRIPCION
        _detalle.descripcion = newValue;
        //dt[row].descripcion = newValue;
        break;
      case 3://TABLECOL_CANTIDAD
        _detalle.cantidad = parseFloat(newValue);
        //dt[row].cantidad = newValue ? parseFloat(newValue) : 0;
        break;
      case 4://TABLECOL_PRECIO
        _detalle.precio = parseFloat(newValue);
        //dt[row].precio = newValue ? parseFloat(newValue) : 0;
        break;
      default:
        break;
    }
    _detalles[row] = _detalle.getDetalle();
    dispatch(updateDetalles({
      key: tabId,
      detalles: _detalles
    }))
  }, [detalles, tabId, dispatch]);

  const onModalItemsItemSelected = React.useCallback((item: ItemCompras) => {
    const detalle: DetalleCompra = new DetalleCompra();
    detalle.setItem(item);
    detalle.cantidad = 1;
    dispatch(addDetalle({
      key: tabId,
      detalle: detalle.getDetalle()
    }))
    dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }));
  }, [tabId, dispatch]);

  const ocultarBusquedaItems = React.useCallback(() => {
    dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }));
  }, [tabId, dispatch]);

  const generaDetalleErrores = React.useCallback((item: any) => {
    if (item.length > 0) {
      return <CAlert color='danger'>
        <ul className="errorList">
          {item.map(function (item, id) {
            return <li key={id}>{item['message']}</li>
          })}
        </ul></CAlert>
    } else {
      return <></>
    }
  }, []);

  const openAutorizacion = React.useCallback(() => {
    if (proveedor && proveedor.codigo) {
      setOpenAutorizaciones(true)
    } else {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Debe seleccionar un proveedor',
        fade: true,
        title: 'Proveedor',
        type: ToastTypes.Info
      }))
    }
  }, [proveedor, dispatch]);

  const onCreditoButtonClick = React.useCallback(() => {
    if (formaPago && formaPago?.nombre !== "CREDITO") {
      dispatch(addToast({
        content: 'La forma de pago debe de ser credito para usar esta opción!!!',
        type: ToastTypes.Warning
      }))
      return;
    }
    const total = parseFloat(totales.total.toFixed(2))
    if (total <= 0) {
      dispatch(addToast({
        content: 'La compra debe tener valor para poder ingresar las cuotas de credito.',
        type: ToastTypes.Warning
      }))
      return;
    }
    setMostrarIngresoCreditos(true);
  }, [totales, formaPago, dispatch])

  const onCuotasCreditoChanged = React.useCallback((nuevasCuotas) => {
    dispatch(setCuotasCredito({
      key: tabId,
      cuotas: nuevasCuotas
    }))
    setMostrarIngresoCreditos(false);
    dispatch(buttonClick({
      tabKey: tabId,
      button: ButtonTypes.save
    }))
  }, [tabId, dispatch])

  const cambiarProveedorSeleccionado = React.useCallback(async (data) => {
    dispatch(updateProveedor({ key: tabId, proveedor: data, formasPago: [] }));
  }, [tabId, dispatch]);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVenta ?? "", tab.info?.codigo ?? 0);
    } else {
      cargarCompra(info.codigo);
    }
  }, [tab]);

  const aplicarEstablecimiento = React.useCallback(() => {
    sessionStorage.removeItem('autorizacionModulo');
    const autorizacion = getAutorizacionDocumento(datosEdicion);
    sessionStorage.setItem('autorizacionModulo', JSON.stringify(autorizacion));
  }, [datosEdicion, getAutorizacionDocumento])

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      switch (tab.globalButtonClick) {
        case ButtonTypes.edit:
          modoEdicion();
          break;
        case ButtonTypes.save: {
          guardar();
          break;
        }
        case ButtonTypes.undo:
          deshacer();
          break;
        case ButtonTypes.print:
          handlePrint(false);
          break;
        case ButtonTypes.print_design:
          handlePrint(true);
          break;
        case ButtonTypes.discount:
          dispatch(toogleUsaDescuentoGlobal());
          break;
        case ButtonTypes.broom:
          modoNuevo(puntoVenta ?? "", 0, true);
          break;
        case ButtonTypes.credit:
          onCreditoButtonClick();
          break;
        default:
          break;
      }
      dispatch(clearButtonClick(tabId))
    }
  }, [
    onCreditoButtonClick, tabId, handlePrint, deshacer, guardar, modoEdicion, tab, dispatch, modoNuevo, puntoVenta
  ]);

  React.useEffect(() => {
    if (!loading) {
      calcularTotales(detalles, descuentoGlobal);
    }
  }, [loading, detalles, descuentoGlobal, calcularTotales])


  React.useEffect(() => {
    const eliminarDetalle = (indice: number, detalles: Array<DetalleCompraRedux>) => {
      detalles.splice(indice, 1);
      dispatch(updateDetalles({
        detalles: detalles,
        key: tabId
      }));
      setFilaEliminar(-1);
    };

    const eliminarDetalleConfirm = (indice: number) => {
      const detalle = detalles[indice];
      dialogRef.current.show({
        title: 'Eliminar detalle',
        body: `¿Desea eliminar el item ${detalle.descripcion}?`,
        actions: [
          Dialog.Action(
            <span><u>E</u>liminar</span>,
            () => {
              eliminarDetalle(indice, detalles.slice(0));
            },
            'btn-success',
            'e'
          ),
          Dialog.Action(
            <span><u>C</u>ancelar</span>,
            (dialog) => {
              setFilaEliminar(-1);
              dialog.hide()
            },
            'btn-danger',
            'c'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide();
          setFilaEliminar(-1);
        }
      });
      return;
    };
    if (filaEliminar >= 0) {
      eliminarDetalleConfirm(filaEliminar);
    }
  }, [filaEliminar, detalles, tabId, dispatch])


  React.useEffect(() => {
    if (imprimir) {
      handlePrint(false);
    }
  }, [imprimir, handlePrint, tabId, dispatch])

  React.useEffect(() => {
    aplicarEstablecimiento()
  }, [puntoVenta, establecimiento, autorizacion])

  React.useEffect(() => {
    getVerificaPath()
  }, [getVerificaPath]);

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} >
      <Dialog ref={dialogRef} />
      {openAutorizaciones &&
        <AdminAutorizacionesModal
          onChanged={(data) => {
            dispatch(setEstablecimiento({ key: tabId, establecimiento: data.establecimiento }))
            dispatch(setPuntoEmision({ key: tabId, puntoEmision: data.puntoEmision }))
            dispatch(setAutorizacion({ key: tabId, autorizacion: data.numeroAutorizacion }))
            setOpenAutorizaciones(false)
          }}
          onCancel={() => { setOpenAutorizaciones(false) }}
          show={openAutorizaciones}
          modulo={modulo}
          data={{
            codigoTipoDocumento: "01",
            codigoAsociado: proveedor?.codigo ?? 0,
            nombreAsociado: proveedor?.nombre ?? "",
            codigoPertenece: 2
          }}
        />
      }
      {datosEdicion.tieneError &&
        <VisualizaError titulo='Error en Compra' mensaje={datosEdicion.mensajeError} content={generaDetalleErrores(showErrorMessages)} onOk={() => {
          dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))
        }} />
      }
      {showReenviarMail && proveedor != null && (
        <PopupReenvioMail
          showConfirm={showReenviarMail}
          itemReenviarMail={{
            claveAcceso: autorizacion,
            proveedor: proveedor,
          }}
          closeConfirm={() => setShowReenviarMail(false)}
        />
      )}

      {tab.editStatus === StatesEdition.save && dataExport &&
        <>
          <ReportWithLocalDataModal
            show={reporte !== null}
            onClose={() => setReporte(null)}
            data={dataExport}
            fileName='StaticCompras'
            mode={reporte ?? 'Viewer'}
            parameters={{ 'Reporte_Filtro': [] }}
            template='StaticCompras'
            key='reportDesignerCompras'
          />
          {/*
          <VerRIDE
            modo={habilitarDisenador ? 'DevExpressReport' : 'Legacy'}
            claveAcceso={verFacturaPdf.claveAcceso}
            vista={verFacturaPdf.design ? "Designer" : "Viewer"}
            onClose={() => {
              setVerFacturaPdf(undefined)
              setReporte(null)
            }}
            />
            */}
        </>
      }

      {mostrarIngresoCreditos &&
        <CreditosCompras
          cuotas={cuotas}
          fechaFactura={datosEdicion.fechaEmision}
          totalCobrar={Number(datosEdicion.totales.total.toFixed(2))}
          codigoCompra={codigo}
          onCancel={() => setMostrarIngresoCreditos(false)}
          onOk={onCuotasCreditoChanged}
        />
      }

      <BlockUi loader={LoadingIndicator} tag="div" blocking={loader.show} message={loader.mensaje}  >
        {mostrarBusquedaItems && (
          <MainModalItems
            transaccion='compras'
            action='none'
            show={mostrarBusquedaItems}
            proveedor={datosEdicion.proveedor !== null ? datosEdicion.proveedor : ""}
            onCancel={ocultarBusquedaItems}
            returnItem={onModalItemsItemSelected}
          />
        )}
        {mostrarEdicionItems &&
          <ModificaItem
            transaccion={"compras"}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={async (codigoBarras) => {
              setMostrarEdicionItems(false);
              setCodigoBarrasItemEditar('');
              if (codigoBarrasItemEditar === '') { //nuevo
                const items = await CompraService.getItemCodigoBarras(codigoBarras, proveedor);
                if (items) {
                  onModalItemsItemSelected(items[0]);
                }
              }
            }}
          />
        }
        {/* disabled={tab.editStatus === StatesEdition.save} */}
        <fieldset  >
          <ValidationGroup id={`valGroupCompra${props.tabId}`} ref={validationGroupRef}>
            {!isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }
            <div>
              <RowContainer>
                <CustomCol xs='12' lg='7'>
                  <div>
                    <RowContainer>
                      <CustomCol xs='12'>
                        <Labeled label='Proveedor'>
                          <BuscarProveedorLookMemoUp
                            proveedor={proveedor}
                            tab={tab}
                            cambiarProveedorSeleccionado={cambiarProveedorSeleccionado}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {proveedor &&
                      <RowContainer>
                        <CustomCol
                          xs='12'
                        >
                          <Labeled label='Dirección'                          >
                            <TextBox
                              readOnly
                              style={{ backgroundColor: "#dedede" }}
                              value={proveedor.direccion}
                            >
                            </TextBox>
                          </Labeled>
                        </CustomCol>
                        <CustomCol
                          xs='4'
                        >
                          <Labeled label='Teléfono'                          >
                            <TextBox
                              readOnly
                              style={{ backgroundColor: "#dedede" }}
                              value={proveedor.telefono ?? ''}
                            >
                            </TextBox>
                          </Labeled>
                        </CustomCol>
                        <CustomCol
                          xs='8'
                        >
                          <Labeled label='Correo eléctronico'                          >
                            <TextBox
                              readOnly
                              style={{ backgroundColor: "#dedede" }}
                              value={proveedor.email ?? ''}
                            >
                            </TextBox>
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    }

                    <RowContainer>
                      <CustomCol xs="12" xl="5">
                        <Labeled label='Tipo Compra'                        >
                          <SelectBox
                            id='tipoCompra'
                            placeholder='Tipo de Compra'
                            options={tiposCompras}
                            displayExpr='tipo'
                            keyExpr='codigo'
                            selected={tipoCompra ?? null}
                            onChange={(selected) => {
                              dispatch(setTipoCompra({ key: tabId, tipoCompra: selected }))
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                            clearButton={true}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GenericTipoCompraRequerida)} />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="12" xl="7">
                        <Labeled label='Sustento Tributario'                        >
                          <SelectBox
                            id='sustentosTributarios'
                            placeholder=''
                            options={sustentosTributarios}
                            displayExpr='tipo'
                            keyExpr='codigo'
                            selected={sustentoTributario ?? null}
                            onChange={(selected) => {
                              dispatch(setSustentoTributario({ key: tabId, sustentoTributario: selected }))
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                            clearButton={true}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GenericSustentoTributarioRequerida)} />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>


                  </div>
                </CustomCol>
                <CustomCol xs='12' lg='5'>
                  <div>
                    <RowContainer>
                      <CustomCol >
                        <Labeled label='Fecha de Emisión'                        >
                          <DateBox applyValueMode="useButtons"
                            displayFormat='dd/MM/yyyy'
                            value={DateUtils.strDateToDate(fechaEmision)}
                            onValueChanged={({ value }: any) => {
                              if (value) {
                                dispatch(setFechaEmicion({ key: props.tabId, fecha: DateUtils.dateToString(value) }))
                              }
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                          >
                          </DateBox>
                        </Labeled>
                      </CustomCol>
                      <CustomCol >
                        <Labeled label='Fecha Propuesta'                        >
                          <DateBox applyValueMode="useButtons"
                            displayFormat='dd/MM/yyyy'
                            value={DateUtils.strDateToDate(fechaPropuesta)}
                            onValueChanged={({ value }: any) => {
                              if (value) {
                                dispatch(setFechaPropuesta({ key: props.tabId, fecha: DateUtils.dateToString(value) }))
                              }
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                          >
                          </DateBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>

                    <RowContainer>
                      <CustomCol>
                        <Labeled label='Comp. electrónico:'                        >
                          <Switch value={comprobanteElectronico}
                            onValueChanged={(e) => {
                              dispatch(setComprobanteElectronico({ key: tabId, comprobanteElectronico: e.value }))
                              dispatch(setEstablecimiento({ key: tabId, establecimiento: "" }))
                              dispatch(setPuntoEmision({ key: tabId, puntoEmision: "" }))
                              dispatch(setNumero({ key: tabId, numero: "" }))
                              dispatch(setAutorizacion({ key: tabId, autorizacion: "" }))
                              inputEstablec.current.instance.focus();
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                          />
                        </Labeled>
                      </CustomCol>

                      <CustomCol xs='6' lg='3'>
                        <Labeled label='Establecimiento:'                        >
                          <TextBox
                            ref={inputEstablec}
                            name='establecimiento'
                            value={establecimiento}
                            useMaskedValue={true}
                            onValueChanged={(e) => {
                              dispatch(setEstablecimiento({
                                key: tabId,
                                establecimiento: e.value
                              }))
                            }}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                            maxLength={3}
                            onKeyUp={() => {
                              if (establecimiento.length === 3) {
                                inputPtoEmision.current.instance.focus();
                              }
                            }}
                            valueChangeEvent="keyup"
                            hoverStateEnabled={true}
                            disabled={tab.editStatus === StatesEdition.save}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GenericEstablecimientoRequerido, "")}  ></RequiredRule>
                              <StringLengthRule message="El Establecimiento debe tener 3 dígitos." min={3} />
                            </Validator>

                          </TextBox>
                        </Labeled>
                      </CustomCol>

                      <CustomCol xs='6' lg='3'>
                        <Labeled label='Pto. Emisión:'                        >
                          <TextBox
                            ref={inputPtoEmision}
                            name='puntoEmision'
                            value={puntoEmision}
                            useMaskedValue={true}
                            onValueChanged={(e) => {
                              dispatch(setPuntoEmision({
                                key: tabId,
                                puntoEmision: e.value
                              }))
                            }}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                            maxLength={3}
                            valueChangeEvent="keyup"
                            hoverStateEnabled={true}
                            onKeyUp={() => {
                              if (puntoEmision.length === 3) {
                                inputNroComp.current.instance.focus();
                              }
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GenericPtoEmisionRequerido, "")}  ></RequiredRule>
                              <StringLengthRule message="El Pto. Emisión debe tener 3 dígitos." min={3} />

                            </Validator>

                          </TextBox>
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs='6' lg='3'>
                        <Labeled label='Nro.:'                        >
                          <TextBox
                            ref={inputNroComp}
                            name='Numero'
                            value={numero}
                            useMaskedValue={true}
                            onValueChanged={(e) => {
                              dispatch(setNumero({
                                key: tabId,
                                numero: e.value
                              }))
                            }}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                            valueChangeEvent="keyup"
                            hoverStateEnabled={true}
                            onKeyUp={() => {
                              if (numero.length === 9) {
                                inputAut.current.instance.focus();
                              }
                            }}
                            maxLength={9}
                            onEnterKey={() => {
                              inputAut.current.instance.focus();
                            }}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GenericNumeroComprobanteRequerido, "")}  ></RequiredRule>
                              <AsyncRule ignoreEmptyValue message={lh.getMessage(MessagesKeys.TransaccionSinDetalles)} validationCallback={validateCompraDetalle}   > </AsyncRule>
                              <StringLengthRule message="El Nro debe tener al menos 1 dígitos." min={1} />
                            </Validator>

                          </TextBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs='12' lg='8'>
                        <Labeled label='Autorización:'                        >
                          <TextBox
                            ref={inputAut}
                            name='autorizacion'
                            value={autorizacion}
                            useMaskedValue={true}
                            onValueChanged={(e) => {
                              dispatch(setAutorizacion({
                                key: tabId,
                                autorizacion: e.value
                              }))
                            }}
                            onKeyDown={utilidades.filtraTeclasNoNumericas}
                            valueChangeEvent="keyup"
                            hoverStateEnabled={true}
                            maxLength={comprobanteElectronico === true ? 49 : 10}
                            disabled={tab.editStatus === StatesEdition.save}
                          >
                            <TextBoxButton
                              name="autorizaciones"
                              location="after"
                              options={{
                                type: "normal",
                                stylingMode: "contained",
                                icon: 'more',
                                onClick: () => {
                                  openAutorizacion()
                                }
                              }}
                            />
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GenericAutorizacionComprobanteRequerido, "")}  ></RequiredRule>
                            </Validator>

                          </TextBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {((tab.editStatus === StatesEdition.save)) &&
                      <>
                        < RowContainer >
                          <CustomCol xs='12' xxl='8' >
                            {!isMobileOnly &&
                              <Barcode text={autorizacion} />
                            }
                            {isMobileOnly &&
                              <Labeled label='Autorización'                              >
                                <small>{autorizacion}</small>
                              </Labeled>
                            }

                          </CustomCol>
                          <CustomCol xs='12' xxl='4' >
                            <Labeled label='Cod.'                            >
                              <CBadge
                                color={"defaut"}
                              >
                                {datosEdicion.codigo}
                              </CBadge>
                            </Labeled>
                          </CustomCol>
                        </RowContainer>
                      </>
                    }
                    <RowContainer>
                      <CustomCol xs="12" xl="7">
                        <Labeled label='Proyecto'                        >
                          <SelectBox
                            id='proyectos'
                            placeholder=''
                            options={proyectos}
                            displayExpr='nombre'
                            keyExpr='codigo'
                            selected={proyecto ?? null}
                            onChange={(selected) => {
                              dispatch(setProyecto({ key: tabId, proyecto: selected }));
                              onChangeProyecto(selected);
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                            clearButton={true}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GenericProyectoRequerida)} />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs="12" xl="5">
                        <Labeled label='Local'                        >
                          <SelectBox
                            id='locales'
                            placeholder=''
                            options={locales}
                            displayExpr='nombre'
                            keyExpr='codigo'
                            selected={local ?? null}
                            onChange={(selected) => {
                              dispatch(setLocal({ key: tabId, local: selected }));
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                            clearButton={true}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GenericLocalRequerida)} />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>

                  </div>
                </CustomCol>
              </RowContainer>

              {tab.editStatus !== StatesEdition.save &&
                <RowContainer className='mb-2 mt-2'>
                  <CCol>
                    <div className='buttonsContainer' >
                      {isMobileOnly &&
                        <Button
                          text='Agregar Detalle'
                          icon='add'
                          stylingMode='contained'
                          type='default'
                          onClick={() => {
                            // setAgregarDetalleMovil(true);
                            dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: true }));

                          }}
                        />
                      }
                      <Button
                        style={buttonsProductoStyle}
                        text='Crear Producto'
                        icon='file'
                        stylingMode='contained'
                        type='default'
                        onClick={onCrearProductoButtonClick}
                        elementAttr={{
                          id: 'btnCreateItem'
                        }}
                      />
                      <Button
                        style={buttonsProductoStyle}
                        hint='Más opciones'
                        icon='more'
                        stylingMode='contained'
                        type='default'
                        onClick={onMasOpcionesButtonClick}
                        elementAttr={{
                          id: 'btnOptionsItems'
                        }}
                      >

                      </Button>
                    </div>
                  </CCol>
                </RowContainer>
              }

              <div id="detalleVentayTotales"  >

                {isMobileOnly && //Mobiles
                  <RowContainer className='mb-2 mt-2'>
                    <CCol xs='12'>
                      <div style={{ paddingTop: '5px' }}>
                        <MUIDataTable
                          data={detalles}
                          columns={getColumnnasTablaDetalleVentas(
                            muiTableCellsChanged,
                            (row) => setFilaEliminar(row)
                          )}
                          options={OptionsTablaDetalleVentas}
                        />
                      </div>
                    </CCol>
                  </RowContainer>
                }
                {isMobile && !isMobileOnly &&  //Tablets
                  < RowContainer className='mb-2 mt-2'>
                    <CCol xs='12'>

                      <div className='detallesTableContainer' style={{ maxHeight: `${window.innerHeight / 2}px` }}  >
                        <CustomSheet
                          id='sheetDetalle'
                          striped
                          blankRows={2}
                          columns={sheetColumns}
                          data={detalles}
                          editable={tab.editStatus !== StatesEdition.save}
                          initializeNewRow={(col, value) => {
                            const detalle: DetalleCompra = new DetalleCompra();
                            if (col.name === 'descripcion') {
                              const item = value as ItemCompras;
                              detalle.setItem(item);
                            } else {
                              detalle[col.name] = value;
                            }

                            return detalle.getDetalle();
                          }}
                          onCellChanged={sheetOnCellsChanged}
                          showRowNumber
                          rowCommands={sheetCommands}
                        />
                      </div>

                    </CCol>
                  </RowContainer>
                }

                {!isMobile && //Escritorio
                  < RowContainer className='mb-2 mt-2'>
                    <CCol xs='12'>
                      <div className='detallesTableContainer overflow-auto' onKeyDown={(e) => { }} style={{ width: window.innerWidth > 900 ? '100%' : `${window.innerWidth - 20}px`, maxHeight: `${window.innerHeight / 2}px` }}  >
                        <CustomSheet
                          id='sheetDetalle'
                          blankRows={2}
                          striped
                          columns={sheetColumns}
                          data={detalles}
                          editable={tab.editStatus !== StatesEdition.save}
                          initializeNewRow={(col, value) => {
                            const detalle: DetalleCompra = new DetalleCompra();
                            if (col.name === 'descripcion') {
                              const item = value as ItemCompras;
                              detalle.setItem(item);
                            } else {
                              detalle[col.name] = value;
                            }
                            return detalle.getDetalle();
                          }}
                          onCellChanged={sheetOnCellsChanged}
                          showRowNumber
                          rowCommands={sheetCommands}
                        />
                      </div>
                    </CCol>
                  </RowContainer>
                }

                <RowContainer>
                  <CustomCol xs='12' md={usaDescuentoGlobal ? '4' : '8'}>
                    <Labeled label='Observaciones'                    >
                      <TextArea
                        value={observaciones}
                        onValueChanged={onObservacionesChanged}
                      />
                    </Labeled>
                  </CustomCol>
                  {usaDescuentoGlobal &&
                    <>
                      <CustomCol xs='3' md='1' lg='1'>
                        <Labeled label='Desc ($)'                        >
                          <CFormInput
                            name='inputDescuentoXItemValor'
                            type='number'

                            defaultValue={datosEdicion.descuentoGlobal}
                            onBlur={(ev) => {
                              if (ev.currentTarget.value) {
                                establecerDescuentoGlobal(parseFloat(ev.currentTarget.value));
                              } else {
                                establecerDescuentoGlobal(datosEdicion.descuentoGlobal);
                              }
                            }}
                          />
                        </Labeled>

                      </CustomCol>
                    </>
                  }
                  <CustomCol xs='12' md='3'>
                    <Labeled label='Forma Pago'                    >
                      <SelectBox
                        id='formaPagoCompras'
                        placeholder='Forma de Pago'
                        options={formasPago}
                        displayExpr='nombre'
                        keyExpr='codigo'
                        selected={formaPago ?? null}
                        onChange={(selected) => {
                          dispatch(setFormaPago({ key: tabId, formaPago: selected }))
                        }}
                        disabled={tab.editStatus === StatesEdition.save}
                        clearButton={true}
                      >
                        <Validator>
                          <RequiredRule message={lh.getMessage(MessagesKeys.GenericFormaPagoRequerida)} />
                        </Validator>
                      </SelectBox>
                    </Labeled>
                  </CustomCol>
                </RowContainer>
                {!isMobile &&
                  < RowContainer >
                    <div className='totalesContainer'>
                      <div className='totalContainer'>
                        <div className='totalLabel'>
                          <span>Subtotal</span>
                        </div>
                        <div className='totalValue'>
                          <span> {(datosEdicion.totales.subtotal - datosEdicion.totales.descuentoGlobal).toFixed(2)} </span>
                        </div>
                      </div>
                      <div className='totalContainer'>
                        <div className='totalLabel'>
                          <span>Subtotal 0%</span>
                        </div>
                        <div className='totalValue'>
                          <span>{datosEdicion.totales.subtotal0.toFixed(2)} </span>
                        </div>
                      </div>
                      <div className='totalContainer'>
                        <div className='totalLabel'>
                          <span>I.C.E</span>
                        </div>
                        <div className='totalValue'>
                          <span>{datosEdicion.totales.ice.toFixed(2)} </span>
                        </div>
                      </div>
                      <div className='totalContainer'>
                        <div className='totalLabel'>
                          <span>Subtotal IVA</span>
                        </div>
                        <div className='totalValue'>
                          <span> {datosEdicion.totales.subtotalIva.toFixed(2)} </span>
                        </div>
                      </div>
                      <div className='totalContainer'>
                        <div className='totalLabel'>
                          <span>IVA {iva}%</span>
                        </div>
                        <div className='totalValue'>
                          <span> {datosEdicion.totales.iva.toFixed(2)} </span>
                        </div>
                      </div>
                      <div className='totalContainer'>
                        <div className='totalLabel'>
                          <span>Desc. Items</span>
                        </div>
                        <div className='totalValue'>
                          <span> {datosEdicion.totales.descuento.toFixed(2)} </span>
                        </div>
                      </div>
                      <div className='totalContainer'>
                        <div className='totalLabel'>
                          <span>Total</span>
                        </div>
                        <div className='totalValue'>
                          <span> {datosEdicion.totales.total.toFixed(2)} </span>
                        </div>
                      </div>
                    </div>
                  </RowContainer>
                }
                {isMobile &&
                  <>
                    <RowContainer>
                      <CustomCol xs='8'>
                        <small>Subtotal</small>
                      </CustomCol>
                      <CustomCol xs='4'>
                        <small>{datosEdicion.totales.subtotal.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs='8'>
                        <small>Subtotal 0%</small>
                      </CustomCol>
                      <CustomCol xs='4'>
                        <small>{datosEdicion.totales.subtotal0.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs='8'>
                        <small>ICE</small>
                      </CustomCol>
                      <CustomCol xs='4'>
                        <small>{datosEdicion.totales.ice.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs='8'>
                        <small>Subtotal IVA</small>
                      </CustomCol>
                      <CustomCol xs='4'>
                        <small>{datosEdicion.totales.subtotalIva.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs='8'>
                        <small>Impuestos</small>
                      </CustomCol>
                      <CustomCol xs='4'>
                        <small>{datosEdicion.totales.iva.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs='8'>
                        <small>Descuento  Items</small>
                      </CustomCol>
                      <CustomCol xs='4'>
                        <small>{datosEdicion.totales.descuento.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol xs='8'>
                        <small>Total</small>
                      </CustomCol>
                      <CustomCol xs='4'>
                        <small>{datosEdicion.totales.total.toFixed(2)}</small>
                      </CustomCol>
                    </RowContainer>
                  </>
                }
              </div>

            </div>

            {isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }

          </ValidationGroup>
        </fieldset>
      </BlockUi>
      {
        isMobile &&
        <div style={{ height: '40px' }} />
      }
    </div >
  );
}

const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '10%',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null;
    }
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcion',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false;
    },
    getDataEditor: (data) => {
      return data == null ? SheetDescripcionItemSelectEditor : SheetTextEditor
    }
  },
  {
    id: 3,
    colSpan: 1,
    name: 'porcentajeiva',
    headerText: 'Imp.',
    width: '100px',
    order: 3,
    readOnly: true,
    getDisplay: (value, data: DetalleCompraRedux) => `${(data.porcentajeiva * 100).toFixed(2)}% - ${data._iva.toFixed(2)}`
  },
  {
    id: 4,
    colSpan: 1,
    name: '_cantidad',
    headerText: 'Cant.',
    width: '60px',
    order: 4,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },
  {
    id: 5,
    colSpan: 1,
    name: '_porcentajeDescuento',
    headerText: 'Desc %.',
    width: '80px',
    order: 5,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleCompraRedux) => `${data._porcentajeDescuento.toFixed(2)}% (${data._descuento.toFixed(2)})`
  },
  {
    id: 6,
    colSpan: 1,
    name: '_precioUnitario',
    headerText: 'P. Unit.',
    width: '80px',
    order: 6,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },
  {
    id: 7,
    colSpan: 1,
    name: '_precioIva',
    headerText: 'Unit. IVA',
    width: '80px',
    order: 7,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleCompraRedux) => data._precioIva.toFixed(4)
  },
  {
    id: 8,
    colSpan: 1,
    name: '_total',
    headerText: 'Subtotal',
    width: '80px',
    order: 8,
    readOnly: true,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleCompraRedux) => data._subtotal.toFixed(2)
  }
];


export const defaulDatosEdicionCompra: ComprasDatosEdicion = {
  numero: "",
  autorizacion: '',
  proveedor: null,
  detalles: [],
  establecimiento: '',
  puntoEmision: '',
  fechaEmision: DateUtils.getCurrentDateAsString(),
  fechaPropuesta: DateUtils.getCurrentDateAsString(),
  fechaAutorizacion: '',
  formasPago: [],
  formaPago: null,
  proyectos: [],
  proyecto: null,
  locales: [],
  local: null,
  tiposCompras: [],
  tipoCompra: null,
  sustentosTributarios: [],
  sustentoTributario: null,
  comprobanteElectronico: false,
  iva: 0,
  loader: {
    mensaje: 'Cargando...',
    show: true
  },
  codigo: 0,
  loading: true,
  imprimir: false,
  tieneError: false,
  mensajeError: "",
  mostrarBusquedaItems: false,
  mostrarEdicionProveedor: false,
  mostrarEdicionItems: false,
  observaciones: '',
  totales: {
    descuento: 0,
    descuentoGlobal: 0,
    ice: 0,
    iva: 0,
    subtotal: 0,
    subtotal0: 0,
    subtotalIva: 0,
    total: 0
  },
  cuotas: [],
  desdePlantilla: false,
  descuentoGlobal: 0,
}




export const CompraButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: false,
  Imprimir: false,
  Descuento: true,
  Credito: true,
  Extraer: false,
}

const buttonsProductoStyle = { marginLeft: '2px' };

export default React.memo(Compras);
