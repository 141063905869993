import { EAmbiente } from '../../services/itemEnum/enumSV'
import { Empresa } from '../../store/types'
import { regexPatterns } from './patternValidations'

export const JsonDteElectronicHelper = {
  getAmbiente,
  generaFechaQrEnlace,
  isValidDateString,
}

export function getAmbiente(empresa: Empresa) {
  let amb: string = EAmbiente.Pruebas
  if (empresa?.tipoAmbiente !== undefined && empresa.tipoAmbiente === 1) {
    amb = EAmbiente.Produccion
  }
  return amb
}

export function extractControlNumber(input: string): {
  establecimiento: string
  ptoEmision: string
  numero: string
} {
  if (regexPatterns.numeroControl.test(input)) {
    // Expresión regular para capturar los valores deseados
    const parts = input.split('-')

    const thirdPart = parts[2] // 'RESL0101'
    const fourthPart = parts[3] // '000100000000001'

    const establecimiento = thirdPart.slice(4)
    const ptoEmision = fourthPart.slice(0, 4).match(/\d+/)?.[0] ?? ''
    const numero = fourthPart.slice(4)

    return {
      establecimiento, // Captura '0101'
      ptoEmision, // Captura '0001'
      numero, // Captura '000000000001'
    }
  }

  // Si el formato del string no coincide con el patrón esperado, devolver null
  return null
}

export function generaFechaQrEnlace(fecha: string): string {
  console.log('generaFechaQrEnlace')
  console.log('fecha', fecha)
  let dateString = fecha
  if (fecha.includes(' ')) {
    const descDate = fecha?.split(' ')
    dateString = descDate[0]
  }
  console.log('dateString', dateString)

  const dateParts = dateString.split(/[-/]/)
  console.log('dateParts', dateParts)

  let year: string = ''
  let month: string = ''
  let day: string = ''

  dateParts.forEach((part) => {
    if (part.length === 4) {
      year = part
    } else if (!month) {
      month = part
    } else {
      day = part
    }
  })

  let y = ''
  if (dateString.startsWith(year))
    [y, month, day] = [year, dateParts[1], dateParts[2]]

  if (dateString.endsWith(year))
    [day, month, y] = [dateParts[0], dateParts[1], year]

  year = y
  if (month.length === 1) month = '0' + month
  if (day.length === 1) day = '0' + day
  console.log('year', year)
  console.log('month', month)
  console.log('day', day)

  return `${year}-${month}-${day}`
}

type DateString = `${number}-${number}-${number}`
function isValidDateString(date: string): date is DateString {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/
  return dateRegex.test(date)
}
