import { DateUtils } from "../../../helpers/dateUtils";
import { RequestHelper } from "../../../helpers/requestHelper";
import { MotivoCaducidad } from "./contadorDocumentos/contadorDocumentos";
import { PagoVencido, RecurrenteData, TipoPeriodo } from "./contadorDocumentos/type/type";

export const ComprobantesService = {
  getComprobantes, getRecurrente, checkArrears
}

async function getComprobantes(fechaDesde: string): Promise<any> {
  // 01 / 07 / 2022
  const fechaHoy = DateUtils.getCurrentDateAsString();
  const fechaHasta = DateUtils.strDateToDate(fechaHoy, "dd/MM/yyyy");

  const data = {
    desde: fechaDesde,
    hasta: fechaHasta
  };
  const apiData = await RequestHelper.get<any>('admin/contratos', 'count', "", data);
  return apiData;

}

async function getRecurrente(codigoEmpresa: string): Promise<RecurrenteData> {
  const data = { empresaReferencia: codigoEmpresa };
  const apiData = await RequestHelper.get<any>('admin/contratos', 'getRecurrente', "", data);
  const recurrente = parseApiRecurrente(apiData);
  return recurrente;
}

async function checkArrears(codigoEmpresa: number): Promise<any> {
  const data = {
    empresa: codigoEmpresa
  };
  const apiData = await RequestHelper.get<any>('admin/contratos', 'checkArrears', "", data);
  const items = parseApiItemsData(apiData);
  return items;
}


function parseApiItemsData(apiresult: Array<any>): Array<PagoVencido> {
  const items = apiresult.map(x => {
    const item: PagoVencido = {
      autorizacion: x.autorizacion ?? "",
      codTipoDoc: parseInt(x.codTipoDoc) ?? 0,
      codigo: parseInt(x.codigo) ?? 0,
      codigoCliente: parseInt(x.codigoCliente) ?? 0,
      codigoLocal: parseInt(x.codigoLocal) ?? 0,
      credito: parseInt(x.credito) ?? 0,
      detalle: String(x.detalle) ?? "",
      dias: parseInt(x.dias) ?? 0,
      direccionCliente: String(x.direccionCliente) ?? "",
      emailCliente: String(x.emailCliente) ?? "",
      empresaCliente: String(x.empresaCliente) ?? "",
      fecha: String(x.fecha) ?? "",
      identificacionCliente: String(x.identificacionCliente) ?? "",
      noVencido: parseInt(x.noVencido) ?? 0,
      nombreCliente: String(x.nombreCliente) ?? "",
      numero: String(x.numero) ?? "",
      pagoInmediato: x.pagoInmediato,
      rcomercialCliente: String(x.rcomercialCliente) ?? "",
      saldo: parseFloat(x.saldo) ?? 0,
      seleccionado: x.seleccionado,
      telefonoCliente: String(x.telefonoCliente) ?? "",
      tipoComprobante: String(x.tipoComprobante) ?? "",
      total: parseFloat(x.total) ?? 0,
      vencido: parseFloat(x.vencido) ?? 0,
      vendedor: String(x.vendedor) ?? "",
      venta8: parseFloat(x.venta8) ?? 0,
      venta30: parseFloat(x.venta30) ?? 0,
      venta60: parseFloat(x.venta60) ?? 0,
      venta90: parseFloat(x.venta90) ?? 0,
      venta120: parseFloat(x.venta120) ?? 0,
      venta180: parseFloat(x.venta180) ?? 0,
      ventaMas: parseFloat(x.ventaMas) ?? 0,
    };
    return item;
  });
  return items;
}

function parseApiRecurrente(apiresult: any): RecurrenteData {
  const item: RecurrenteData = {
    codigo: parseInt(apiresult?.codigo) ?? 0,
    clienteCodigo: parseInt(apiresult?.clienteCodigo) ?? 0,
    nombreCliente: apiresult?.nombreCliente,
    rcomercialCliente: apiresult?.rcomercialCliente,
    identificacionCliente: apiresult?.identificacionCliente,
    direccionCliente: apiresult?.direccionCliente,
    telefonoCliente: apiresult?.telefonoCliente,
    emailCliente: apiresult?.emailCliente,
    fechaInicio: apiresult?.fechaInicio,
    fechaFin: apiresult?.fechaFin,
    planActivo: apiresult?.planActivo ?? false,
    comprobantesEmitidos: apiresult?.comprobantesEmitidos ?? 0,
    tipoRecurrencia: apiresult?.tipoRecurrencia,
    mensajeMotivo: apiresult?.mensaje ?? '',
    motivoCaduca: apiresult?.motivo ?? MotivoCaducidad.none,
    verificado: parseInt(apiresult?.verificado) ?? 0,
    isFree: apiresult.noFree ?? true, // key es tomado del api retRecurrente => noFree, para conocer el el plan es gratuito
    codigoPlanVigente: parseInt(apiresult?.plan) ?? 0,
    maxComprobantes: parseInt(apiresult?.maxComprobantes) ?? 0,
    periodo: parseInt(apiresult?.periodo) === 5 ? TipoPeriodo.ANUAL : TipoPeriodo.MENSUAL ?? TipoPeriodo.ANUAL,
    numComprobantesContrato: parseInt(apiresult?.numComprobantesContrato) ?? 0,
    numUsuariosContrato: parseInt(apiresult?.numUsuariosContrato) ?? 0,
  };
  return item;
}
