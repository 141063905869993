import React from 'react'
import { SelectBox as DevExSelectBox } from 'devextreme-react/select-box'
import DataSource from 'devextreme/data/data_source'
import { CTooltip } from '@coreui/react-pro'

interface ISelectBoxProps extends React.PropsWithChildren {
  id: string
  keyExpr: string
  displayExpr: string
  multiple?: boolean
  onChange: (selected: any) => void
  options: any[]
  placeholder: string
  selected: any | null
  disabled?: boolean
  clearButton?: boolean
  invalid?: boolean
  minSearchLength?: number
  elementAttr?: any
  children?: React.ReactNode
  onEnterKey?: any
  fieldRender?: any
  itemRender?: any
  itemComponent?: any

}

const SelectBox: React.FunctionComponent<ISelectBoxProps> = (props) => {
  // const [showClear, setShowClear] = React.useState(false);
  // const [selected, setSelected] = React.useState<Array<any>>([]);
  const { onChange, clearButton, fieldRender, itemRender, itemComponent } = props
  const dataSource = React.useMemo(() => {
    return new DataSource({
      store: {
        data: props.options,
        type: 'array',
        key: props.keyExpr,
      },
    })
  }, [props.options, props.keyExpr])

  const selectBoxValueChanged = React.useCallback(
    ({ value }: any) => {
      onChange(value)
    },
    [onChange],
  )

  // const tooltipComponentOption = React.useCallback(
  //   (data) => {
  //     console.log('tooltipComponentOption', data)
  //     if (!tooltipOption) {
  //       return false
  //     }
  //     return (
  //       <CTooltip
  //         key={'tool-tipoOptions'}
  //         placement="top"
  //         content={data?.descripcion ?? ''}
  //       >
  //         <div className="custom-item" >
  //           <div className="product-name">{data.descripcion}  </div>
  //         </div>
  //       </CTooltip>
  //     )
  //   }, [tooltipOption])

  // const tooltipComponentField = React.useCallback(
  //   (data) => {
  //     console.log('tooltipComponentField', data)
  //     if (!tooltipField) {
  //       return false
  //     }
  //     return (
  //       <CTooltip
  //         key={'tool-tipoOptions'}
  //         placement="top"
  //         content={data?.descripcion ?? ''}
  //       >
  //         <div className="custom-item" >
  //           <div className="product-name">{data.descripcion}  </div>
  //         </div>
  //       </CTooltip>
  //     )
  //   }, [tooltipField])

  return (
    <DevExSelectBox
      dataSource={dataSource}
      searchEnabled={true}
      searchMode={'contains'}
      searchExpr={props.displayExpr}
      displayExpr={props.displayExpr}
      minSearchLength={props.minSearchLength ?? 3}
      searchTimeout={500}
      showDataBeforeSearch
      showSelectionControls={props.multiple ? props.multiple : false}
      onValueChanged={(evt) => {
        if (evt?.event !== undefined) {
          selectBoxValueChanged(evt)
        }
      }}
      showClearButton={clearButton}
      value={props.selected}
      disabled={props.disabled}
      placeholder={props.placeholder ?? 'Seleccionar...'}
      elementAttr={props.elementAttr}
      onEnterKey={props.onEnterKey}
      fieldRender={fieldRender}
      itemRender={itemRender}
      itemComponent={itemComponent}
    >
      {props.children}
    </DevExSelectBox>
  )

  // return (
  //   <Autocomplete
  //     size="small"
  //     id={props.id}
  //     disableClearable={props.clearButton !== undefined ? !props.clearButton : false}
  //     multiple={props.multiple}
  //     options={props.options}
  //     getOptionLabel={(option) => option[props.labelKey]}
  //     disabled={props.disabled}
  //     onChange={(event: any, newValue) => {
  //       props.onChange([newValue]);
  //     }}
  //     value={props.selected && props.selected.length > 0 ? props.selected[0] : null}
  //     renderInput={(params) => <TextField {...params} label="" variant='standard' fullWidth />}
  //   />
  // )
}

export default SelectBox
