import React from 'react'
import { CAlert } from '@coreui/react-pro'

interface IListErrorValidationFormProps extends React.PropsWithChildren {
  errorsList: Array<string>
  color: string
  mainMessage: string
}

const ListErrosValidationForm: React.FunctionComponent<
  IListErrorValidationFormProps
> = (props) => {
  const { errorsList: errorList, color, mainMessage } = props
  return (
    errorList?.length && (
      <CAlert color={color}>
        <p>{mainMessage}</p>
        <ul className="errorList">
          {errorList.map(function (errorList, id) {
            return <li key={id}>{errorList}</li>
          })}
        </ul>
      </CAlert>
    )
  )
}

export default ListErrosValidationForm
